.pagination-container {
    margin: 0 auto;
    text-align: center;
}

.pagination {
    position: relative;
}

.pagination .page-item {
    height: 42px;
    min-width: 42px;
}

.pagination .page-item.pagination-prev, .pagination .page-item.pagination-next {
    width: fit-content;
}

.pagination .page-link {
    position: relative;
    display: inline-block;
    color: #2c3e50;
    text-decoration: none;
    font-size: 1.2rem;
    padding: 8px 16px 10px;
    width: 100%;
}

.pagination .page-link:before {
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 100%;
    content: "";
    top: 0;
    left: 0;
    background-color: rgb(0, 180, 235);
    border-radius: 24px;
    transform: scale(0);
    transition: all 0.2s;
}

.pagination .page-link:hover {
    color: #fff;
}

.pagination .page-link:hover:before,
.pagination .pagination-active .page-link:before {
    transform: scale(1);
}

.pagination .pagination-active .page-link {
    color: #fff;
    background-color: rgb(0, 180, 235);
    border-radius: 24px;
}

.pagination .pagination-active:before {
    transform: scale(1);
}
.pagination-next {
    margin-right: 50px;
}

.pagination-prev {
    margin-left: 50px;
}

.page-link {
    border: none;
    margin: 0;
}

.page-link:hover {
    z-index: 2;
    text-decoration: none;
    background-color: white;
    border-color: white;
}

@media screen and (max-width: 499px) {
    .pagination .page-item {
        display: none;
    }

    .pagination .page-item.pagination-prev, .pagination .page-item.pagination-next, .pagination .page-item.pagination-active {
        display: flex;
    }
}

@media screen and (min-width: 500px) and (max-width: 799px) {
    .pagination .page-item {
        display: none;
    }

    .pagination .page-item.pagination-prev, .pagination .page-item.pagination-next, .pagination .page-item.pagination-active {
        display: flex;
    }
}

