.siteHeader {
    position:relative;
    z-index:1;
    overflow:hidden;
    background-position:top,50%;
    background-size:1441px 900px,100vw 100vw;
    background-repeat:repeat,no-repeat;
    background-color: #fab900;
    color:#fff;
}

@supports ((-webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)) or (clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)))
{ .siteHeader
{ margin-top:-7em;
    padding-top:7em;
    -webkit-clip-path:polygon(0 7vw,100% 0,100% 100%,0 100%);
    clip-path:polygon(0 7vw,100% 0,100% 100%,0 100%); }
}

.siteHeader
{
    margin-top: 20px;

    -webkit-clip-path:polygon(0 100px,100% 0,100% 100%,0 100%);

    /*clip-path:polygon(0 100px,100% 0,100% 100%,0 100%);*/
    clip-path: polygon(0 100px,100% 50px, 100% 0, 0 0, 0 100%);

}
