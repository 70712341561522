.purchase-payment-provider-img:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.49);
}

.purchase-payment-provider-img-active {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.49);
}

.purchase-container {
    width: 100%;
    margin: auto;
    color: #333;
    font-family: Mikado, sans-serif
}

.purchase-container .round {
    height: 24px;
}

.purchase-container .purchase-registration-container .round {
    height: 40px;
}

.purchase-container .purchase-confirmation-section {
    font-weight: bold;
    margin-bottom: 10px;
}

.purchase-container .purchase-confirmation-edit {
    float: right;
    font-weight: normal;
    color: #e50045;
    text-decoration: underline;
    cursor: pointer;
}

.purchase-container .month {
    float: left;
}

.purchase-container .year {
    float: right;
}

.purchase-container .purchase-confirmation-list {
    list-style-type: "\1F43E";
}

.purchase-container .purchase-confirmation-list li {
    padding-left: 10px;
    padding-top: 10px
}

.purchase-container .purchase-status-step-number-active {
    color: #fefefe;
    background-color: #e50045;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(150, 0, 52, 0.5), 0 6px 20px 0 rgba(150, 0, 52, 0.49);
}

.purchase-container .purchase-status-step-number-inactive {
    color: #fefefe;
    background-color: #fda9c2;
    cursor: pointer;
}

.purchase-container .purchase-status-step-number-inactive:hover{
    background-color: #e50045;
    box-shadow: 0 4px 8px 0 rgba(150, 0, 52, 0.5), 0 6px 20px 0 rgba(150, 0, 52, 0.49);
}

.purchase-container .purchase-status-step-number-blocked {
    color: #fefefe;
    background-color: #b9c5d2;
    cursor: not-allowed;
}

.purchase-container .purchase-status-step-text-active {
    font-weight: bold;
    color: #e50045;
}

.purchase-container .purchase-status-step-text-inactive {
    font-weight: normal;
    color: #333333;
    cursor: pointer;
}

.purchase-container .purchase-status-step-text-blocked {
    font-weight: normal;
    color: #b9c5d2;
    cursor: not-allowed;
}

.purchase-container .purchase-status-step {
    vertical-align: top;
}

.purchase-container .purchase-status-step.bundled {
    width: 18%;
}

.color-choser-container .color-choser-info {
    text-align: center;
}

.color-choser-colors {
    display: flex;
    justify-content: center;
    gap: 1em;

    .color-choser-box {
        width: 34px;
        height: 34px;
        background-color: white;
        border-radius: 50%;

        &.selected-color{
          box-shadow: 0 0 0 3px white, 0 0 0 5px #737373;
        }
    }
}


.color-choser-box-image {
    margin: 0 auto;
}

.color-choser-container {
    .color-choser-form-container button {
        margin-top: 1em;
    }
}

.purchase-payment-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (min-width: 1200px) {
    width: 40%;
    margin: 40px auto;
  }

  @media screen and (min-width: 800px) and (max-width: 1199px) {
    width: 60%;
    margin: 40px auto;
  }

  @media screen and (min-width: 500px) and (max-width: 799px) {
    width: 80%;
    margin: 40px auto;
  }

  @media screen and (max-width: 499px) {
    width: 950%;
    margin: 40px auto;
  }

  .purchase-payment-provider {
    height: 180px;
    margin: 0 10px;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    font-size: 16pt;
    color: #969696;
  }
}

@media screen and (min-width: 1200px){
    .purchase-container .purchase-redirect-container {
        width: 20%;
        margin: 30px auto;
    }

    .purchase-container .purchase-error-container {
        width: 60%;
        margin: 30px auto;
        text-align: center;
        font-size: 18pt;
    }

    .purchase-status {
        width: 40%;
        margin: 30px auto;
    }

    .purchase-status-step {
        margin: 0 1%;
        width: 23%;
        display: inline-block;

        font-size: 15pt;
        text-align: center;
    }

    .purchase-status-step-number {
        width: 50%;
        margin: 10px auto;
        text-align: center;
        border-radius: 50px;
        font-size: 20pt;
        font-weight: bold;
    }

    .purchase-payment-provider-img {
        width: 100%;
        height: 80px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin-top: 10px;
        position: relative;
        cursor: pointer;
    }

    .purchase-payment-provider-img img{
        max-width: 80%;
        max-height: 80%;
        position: absolute;
        top: 50%;
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }

    .purchase-cc-check-form {
        width: 90%;
        margin: 20px auto;
    }

    .purchase-cc-check-label {
        font-weight: bold;
        font-size: 16pt;
        margin-bottom: 10px;
    }

    .purchase-form-check-info {
        font-size: 12pt;
    }

    .purchase-cc-check-date {
        font-weight: bold;
        font-size: 16pt;
        display: inline-block;
    }

    .purchase-success-tones-icon {
        width: 10%;
        margin: 0 auto;
    }

    .purchase-success-icon-box {
        width: 25%;
        margin: 0 auto;
    }

    .purchase-success-icon-box-column {
        width: 48%;
        margin: 0 1%;
        display: inline-block;
    }

    .purchase-title {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
        font-size: 26pt;
        font-weight: bold;
    }

    .purchase-container .purchase-info {
        width: 40%;
        font-size: 18pt;
        line-height: 1.5;
        margin: 30px auto;
    }

    .purchase-info-checkbox {
        line-height: 1.2;
        font-size: 16pt;
        text-align: left;
    }

    .purchase-container .purchase-info .round {
        height: 17px;
    }


    .purchase-form-container {
        width: 80%;
        margin: 40px auto;
    }

    .purchase-registration-container {
        min-height: 1200px;
    }

    .purchase-form {
        width: 40%;
        margin: 0 auto;
        font-size: 15pt;
    }

    .color-choser-form-container {
        width: 40%;
        max-width: 650px;
        margin: 0 auto;
        font-size: 15pt;
    }

    .color-choser-box-image {
        width: 50%;
    }
}

@media screen and (min-width: 800px) and (max-width: 1199px){
    .purchase-redirect-container {
        width: 40%;
        margin: 100px auto;
        min-height: 1000px;
    }

    .purchase-error-container {
        width: 60%;
        margin: 30px auto;
        text-align: center;
        font-size: 18pt;
    }

    .purchase-status {
        width: 60%;
        margin: 30px auto;
    }

    .purchase-status-step {
        margin: 0 1%;
        width: 23%;
        display: inline-block;

        font-size: 15pt;
        text-align: center;
    }

    .purchase-status-step-number {
        width: 50%;
        margin: 10px auto;
        text-align: center;
        border-radius: 50px;
        font-size: 20pt;
        font-weight: bold;
    }



    .purchase-payment-provider {
        margin: 0 2%;
        display: inline-block;
        width: 45%;
        text-align: center;
        font-weight: bold;
        font-size: 16pt;
        color: #969696;
    }

    .purchase-payment-provider-img {
        width: 100%;
        height: 80px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin-top: 10px;
        position: relative;
        cursor: pointer;
    }

    .purchase-payment-provider-img img{
        max-width: 80%;
        max-height: 80%;
        position: absolute;
        top: 50%;
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }

    .purchase-cc-check-form {
        width: 90%;
        margin: 20px auto;
    }

    .purchase-cc-check-label {
        font-weight: bold;
        font-size: 16pt;
    }

    .purchase-cc-check-date {
        font-weight: bold;
        font-size: 16pt;
        display: inline-block;
    }

    .purchase-success-tones-icon {
        width: 15%;
        margin: 0 auto;
    }

    .purchase-success-icon-box {
        width: 50%;
        margin: 0 auto;
    }

    .purchase-success-icon-box-column {
        width: 48%;
        margin: 0 1%;
        display: inline-block;
    }

    .purchase-title {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
        font-size: 30pt;
        font-weight: bold;
    }

    .purchase-info {
        width: 60%;
        font-size: 18pt;
        line-height: 1.5;
        margin: 30px auto;
        text-align: center;
    }

    .purchase-info-checkbox {
        line-height: 1.2;
        font-size: 16pt;
        text-align: left;
    }

    .purchase-form-container {
        width: 80%;
        margin: 40px auto;
    }

    .purchase-registration-container {
        min-height: 1200px;
    }

    .purchase-form {
        width: 60%;
        margin: 0 auto;
        font-size: 15pt;
    }

    .color-choser-form-container {
        width: 60%;
        max-width: 650px;
        margin: 0 auto;
        font-size: 15pt;
    }

    .color-choser-box-image {
        width: 80%;
    }
}

@media screen and (min-width: 500px) and (max-width: 799px){
    .purchase-redirect-container {
        width: 40%;
        margin: 30px auto;
        min-height: 1000px;
    }

    .purchase-error-container {
        width: 60%;
        margin: 30px auto;
        text-align: center;
        font-size: 18pt;
    }

    .purchase-status {
        width: 90%;
        margin: 30px auto;
    }

    .purchase-status-step {
        margin: 0 0;
        width: 25%;
        display: inline-block;

        font-size: 15pt;
        text-align: center;
    }

    .purchase-status-step-number {
        width: 50%;
        margin: 10px auto;
        text-align: center;
        border-radius: 50px;
        font-size: 20pt;
        font-weight: bold;
    }

    .purchase-payment-provider {
        margin: 0 2%;
        display: inline-block;
        width: 45%;
        text-align: center;
        font-weight: bold;
        font-size: 16pt;
        color: #969696;
    }

    .purchase-payment-provider-img {
        width: 100%;
        height: 80px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin-top: 10px;
        position: relative;
        cursor: pointer;
    }

    .purchase-payment-provider-img img{
        max-width: 80%;
        max-height: 80%;
        position: absolute;
        top: 50%;
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }

    .purchase-cc-check-form {
        width: 100%;
        margin: 20px auto;
    }

    .purchase-cc-check-label {
        font-weight: bold;
        font-size: 16pt;
    }

    .purchase-cc-check-date {
        font-weight: bold;
        font-size: 16pt;
        display: inline-block;
    }

    .purchase-success-tones-icon {
        width: 20%;
        margin: 0 auto;
    }

    .purchase-success-icon-box {
        width: 60%;
        margin: 0 auto;
    }

    .purchase-success-icon-box-column {
        width: 48%;
        margin: 0 1%;
        display: inline-block;
    }

    .purchase-title {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
        font-size: 30pt;
        font-weight: bold;
    }

    .purchase-info {
        width: 80%;
        font-size: 18pt;
        line-height: 1.5;
        margin: 30px auto;
        text-align: center;
    }

    .purchase-info-checkbox {
        line-height: 1.2;
        font-size: 16pt;
        text-align: left;
    }

    .purchase-form-container {
        width: 80%;
        margin: 40px auto;
    }

    .purchase-registration-container {
        min-height: 1200px;
    }

    .purchase-form {
        width: 80%;
        margin: 0 auto;
        font-size: 15pt;
    }

    .color-choser-form-container {
        width: 80%;
        margin: 0 auto;
        font-size: 15pt;
    }

    .color-choser-box-image {
        width: 80%;
    }
}

@media screen and (max-width: 499px){
    .purchase-redirect-container {
        width: 40%;
        margin: 30px auto;
        min-height: 800px;
    }

    .purchase-error-container {
        width: 60%;
        margin: 30px auto;
        text-align: center;
        font-size: 18pt;
    }

    .purchase-status {
        width: 95%;
        margin: 30px auto;
    }

    .purchase-status-step {
        margin: 0 1%;
        width: 23%;
        display: inline-block;

        font-size: 15pt;
        text-align: center;
    }

    .purchase-status-step-number {
        width: 50%;
        margin: 10px auto;
        text-align: center;
        border-radius: 50px;
        font-size: 20pt;
        font-weight: bold;
    }

    .purchase-payment-buttons {
        width: 95%;
        margin: 40px auto;
    }

    .purchase-payment-provider {
        margin: 0 2%;
        display: inline-block;
        width: 45%;
        text-align: center;
        font-weight: bold;
        font-size: 16pt;
        color: #969696;
    }

    .purchase-payment-provider-img {
        width: 100%;
        height: 80px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin-top: 10px;
        position: relative;
        cursor: pointer;
    }

    .purchase-payment-provider-img img{
        max-width: 80%;
        max-height: 80%;
        position: absolute;
        top: 50%;
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }

    .purchase-cc-check-form {
        width: 100%;
        margin: 20px auto;
    }

    .purchase-cc-check-label {
        font-weight: bold;
        font-size: 16pt;
    }

    .purchase-cc-check-date {
        font-weight: bold;
        font-size: 16pt;
    }

    .year {
        float: none;
        width: 100%;
    }

    .month {
        float: none;
    }

    .purchase-success-tones-icon {
        width: 30%;
        margin: 0 auto;
    }

    .purchase-success-icon-box {
        width: 90%;
        margin: 0 auto;
    }

    .purchase-success-icon-box-column {
        width: 48%;
        margin: 0 1%;
        display: inline-block;
    }

    .purchase-title {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
        font-size: 26pt;
        font-weight: bold;
    }

    .purchase-info {
        width: 80%;
        font-size: 16pt;
        line-height: 1.4;
        margin: 30px auto;
    }

    .purchase-info-checkbox {
        line-height: 1.2;
        font-size: 16pt;
        text-align: left;
    }

    .purchase-form-container {
        width: 95%;
        margin: 40px auto;
    }

    .purchase-registration-container {
        min-height: 1200px;
    }

    .purchase-form {
        width: 80%;
        margin: 0 auto;
        font-size: 12pt;
    }

    .color-choser-form-container {
        width: 80%;
        margin: 0 auto;
        font-size: 15pt;
    }

    .color-choser-box-image {
        width: 80%;
    }

    .purchase-status-step-text-active {
        display: none;
    }

    .purchase-status-step-text-inactive {
        display: none;
    }

    .purchase-status-step-text-blocked {
        display: none;
    }

    .color-choser-container .twitter-picker {
        margin-left: calc(65% - 138px);
    }
}
