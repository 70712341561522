@media screen and (min-width: 1500px) {
    .playlist-container {
        width: 85%;
        height: auto;
        margin: 50px auto;
        vertical-align: middle;
        font-family: Mikado, sans-serif;
        color: #333;
    }

    .playlist-back-button {
        width: 100%;
    }

    .playlist-back-button a:hover {
        text-decoration: none;
    }

    .playlist-title {
        font-family: MikadoBlack, sans-serif;
        font-size: 35pt;
        line-height: 30pt;
        color: #fab900;
        height: auto;

        /* TRANSFORMATION */
        transform: rotate(-3deg);
        -webkit-transform:rotate(-3deg);
        -moz-transform:rotate(-3deg);
        -ms-transform:rotate(-3deg);
        -o-transform:rotate(-3deg);
    }

    .playlist-items-container {
        width: 100%;
        margin-top: 60px;
        display: inline-block;
        align-items: center;
    }

    .playlist-item {
        float: left;
        margin: 10px 10px;
        height: auto;
        min-height: 340px;
        width: 250px;
        border-radius: 20px;
        padding: 10px;
        /*border: 2px outset rgba(250, 185, 0, 1);
        background: radial-gradient(rgba(250, 185, 0, 0.1), rgba(250, 185, 0, 0.8));*/
    }

    .playlist-item:hover {
        cursor: pointer;
        background: linear-gradient(225deg, rgba(250, 185, 0, 0.4), rgb(250, 185, 0));
    }

    .playlist-item-cover {
        width: 100%;
        max-height: 250px;
        border-radius: 10px;
        margin: 0 auto;
    }

    .playlist-item-name {
        margin-top: 20px;
        font-weight: bold;
        color: #676767;
        border-left: 5px solid #676767;
        padding-left: 5px;
        min-height: 50px;
    }

    .playlist-item-age {
        color: #676767;
    }

}

@media screen and (min-width: 1100px) and (max-width: 1499px) {
    .playlist-container {
        width: 85%;
        height: auto;
        margin: 50px auto;
        vertical-align: middle;
        font-family: Mikado, sans-serif;
        color: #333;
    }

    .playlist-back-button {
        width: 100%;
    }

    .playlist-back-button a:hover {
        text-decoration: none;
    }

    .playlist-title {
        font-family: MikadoBlack, sans-serif;
        font-size: 35pt;
        line-height: 30pt;
        color: #fab900;
        height: auto;

        /* TRANSFORMATION */
        transform: rotate(-3deg);
        -webkit-transform:rotate(-3deg);
        -moz-transform:rotate(-3deg);
        -ms-transform:rotate(-3deg);
        -o-transform:rotate(-3deg);
    }

    .playlist-items-container {
        width: 100%;
        margin-top: 60px;
        display: inline-block;
        align-items: center;
    }

    .playlist-item {
        float: left;
        margin: 10px 10px;
        height: auto;
        min-height: 340px;
        width: 250px;
        border-radius: 20px;
        padding: 10px;
        /*border: 2px outset rgba(250, 185, 0, 1);
        background: radial-gradient(rgba(250, 185, 0, 0.1), rgba(250, 185, 0, 0.8));*/
    }

    .playlist-item:hover {
        cursor: pointer;
        background: linear-gradient(225deg, rgba(250, 185, 0, 0.4), rgb(250, 185, 0));
    }

    .playlist-item-cover {
        width: 100%;
        max-height: 250px;
        border-radius: 10px;
        margin: 0 auto;
    }

    .playlist-item-name {
        margin-top: 20px;
        font-weight: bold;
        color: #676767;
        border-left: 5px solid #676767;
        padding-left: 5px;
    }

    .playlist-item-age {
        color: #676767;
    }
}

@media screen and (min-width: 800px) and (max-width: 1099px) {
    .playlist-container {
        width: 85%;
        height: auto;
        margin: 50px auto;
        vertical-align: middle;
        font-family: Mikado, sans-serif;
        color: #333;
    }

    .playlist-back-button {
        width: 100%;
    }

    .playlist-back-button a:hover {
        text-decoration: none;
    }

    .playlist-title {
        font-family: MikadoBlack, sans-serif;
        font-size: 35pt;
        line-height: 30pt;
        color: #fab900;
        height: auto;

        /* TRANSFORMATION */
        transform: rotate(-3deg);
        -webkit-transform:rotate(-3deg);
        -moz-transform:rotate(-3deg);
        -ms-transform:rotate(-3deg);
        -o-transform:rotate(-3deg);
    }

    .playlist-items-container {
        width: 100%;
        margin-top: 60px;
        display: inline-block;
        align-items: center;
    }

    .playlist-item {
        float: left;
        margin: 10px 10px;
        height: auto;
        min-height: 340px;
        width: 250px;
        border-radius: 20px;
        padding: 10px;
        /*border: 2px outset rgba(250, 185, 0, 1);
        background: radial-gradient(rgba(250, 185, 0, 0.1), rgba(250, 185, 0, 0.8));*/
    }

    .playlist-item:hover {
        cursor: pointer;
        background: linear-gradient(225deg, rgba(250, 185, 0, 0.4), rgb(250, 185, 0));
    }

    .playlist-item-cover {
        width: 100%;
        max-height: 250px;
        border-radius: 10px;
        margin: 0 auto;
    }

    .playlist-item-name {
        margin-top: 20px;
        font-weight: bold;
        color: #676767;
        border-left: 5px solid #676767;
        padding-left: 5px;
    }

    .playlist-item-age {
        color: #676767;
    }
}

@media screen and (min-width: 500px) and (max-width: 799px) {
    .playlist-container {
        width: 85%;
        height: auto;
        margin: 50px auto;
        vertical-align: middle;
        font-family: Mikado, sans-serif;
        color: #333;
    }

    .playlist-back-button {
        width: 100%;
    }

    .playlist-back-button a:hover {
        text-decoration: none;
    }

    .playlist-title {
        font-family: MikadoBlack, sans-serif;
        font-size: 35pt;
        line-height: 30pt;
        color: #fab900;
        height: auto;

        /* TRANSFORMATION */
        transform: rotate(-3deg);
        -webkit-transform:rotate(-3deg);
        -moz-transform:rotate(-3deg);
        -ms-transform:rotate(-3deg);
        -o-transform:rotate(-3deg);
    }

    .playlist-items-container {
        width: 100%;
        margin-top: 60px;
        display: inline-block;
        align-items: center;
    }

    .playlist-item {
        float: left;
        margin: 10px 10px;
        height: auto;
        min-height: 340px;
        width: 250px;
        border-radius: 20px;
        padding: 10px;
        /*border: 2px outset rgba(250, 185, 0, 1);
        background: radial-gradient(rgba(250, 185, 0, 0.1), rgba(250, 185, 0, 0.8));*/
    }

    .playlist-item:hover {
        cursor: pointer;
        background: linear-gradient(225deg, rgba(250, 185, 0, 0.4), rgb(250, 185, 0));
    }

    .playlist-item-cover {
        width: 100%;
        max-height: 250px;
        border-radius: 10px;
        margin: 0 auto;
    }

    .playlist-item-name {
        margin-top: 20px;
        font-weight: bold;
        color: #676767;
        border-left: 5px solid #676767;
        padding-left: 5px;
    }

    .playlist-item-age {
        color: #676767;
    }
}

@media screen and (max-width: 499px) {
    .playlist-container {
        width: 85%;
        height: auto;
        margin: 50px auto;
        vertical-align: middle;
        font-family: Mikado, sans-serif;
        color: #333;
    }

    .playlist-back-button {
        width: 100%;
    }

    .playlist-back-button a:hover {
        text-decoration: none;
    }

    .playlist-title {
        font-family: MikadoBlack, sans-serif;
        font-size: 35pt;
        line-height: 30pt;
        color: #fab900;
        height: auto;

        /* TRANSFORMATION */
        transform: rotate(-3deg);
        -webkit-transform:rotate(-3deg);
        -moz-transform:rotate(-3deg);
        -ms-transform:rotate(-3deg);
        -o-transform:rotate(-3deg);
    }

    .playlist-items-container {
        width: 100%;
        margin-top: 60px;
        display: inline-block;
        align-items: center;
    }

    .playlist-item {
        float: left;
        margin: 10px 10px;
        height: auto;
        min-height: 340px;
        width: 250px;
        border-radius: 20px;
        padding: 10px;
        /*border: 2px outset rgba(250, 185, 0, 1);
        background: radial-gradient(rgba(250, 185, 0, 0.1), rgba(250, 185, 0, 0.8));*/
    }

    .playlist-item:hover {
        cursor: pointer;
        background: linear-gradient(225deg, rgba(250, 185, 0, 0.4), rgb(250, 185, 0));
    }

    .playlist-item-cover {
        width: 100%;
        max-height: 250px;
        border-radius: 10px;
        margin: 0 auto;
    }

    .playlist-item-name {
        margin-top: 20px;
        font-weight: bold;
        color: #676767;
        border-left: 5px solid #676767;
        padding-left: 5px;
    }

    .playlist-item-age {
        color: #676767;
    }
}

.refresh-link {
    cursor: pointer;
    color: #e50045;
}

.refresh-link:hover {
    text-decoration: underline;
}


