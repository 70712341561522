.product-details-container {
  margin-bottom: 200px;

  .back-button {
    margin-top: 0.8em;
    float: left;
    margin-left: 7.5%;
    min-width: 120px;
  }

  .product-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    width: 85%;
    min-height: 520px;
    margin: 50px auto 0;
    vertical-align: middle;
    color: #676767;

    .product-cover {
      width: 280px;
      max-width: 280px;
      max-height: 320px;
      margin: 0 3% 0 0;

      @media screen and (max-width: 699px) {
        width: 100%;
        max-width: 100%;
        max-height: 100vw;
        margin-bottom: 2em;
      }

      .product-cover-background-container {
        background-color: #9b9b9b;
        min-height: 280px;
        width: 100%;
        border-radius: 20px;
        position: relative;
        top: 60px;

        color: white;
        font-size: 0.8em;

        .cover-age-display {
          position:absolute;
          top: 228px;
          left: 230px;

          text-align: right;

          @media screen and (max-width: 699px) {
            top: 65vw;
            left: 70vw;
          }

          span {
            font-size: 1.5em;
            font-weight: bold;
          }

        }

        .cover-product-classification-display {
          position: absolute;
          top: 228px;
          left: 44%;
          text-align: center;

          img {
            position: absolute;
            top: 21px;
            left: 5px;
          }

          @media screen and (max-width: 699px) {
            top: 65vw;
          }

        }

        .cover-product-length-display {
          position: absolute;
          top: 228px;
          left: 5%;
          text-align: left;

          span {
            font-size: 1.5em;
            font-weight: bold;
          }

          @media screen and (max-width: 699px) {
            top: 65vw;
          }
        }

        @media screen and (max-width: 699px) {
          min-height: 80vw;
          top: 20vw;
        }
      }

      > img {
        position: relative;
        top: -275px;
        border-radius: 15px;
        width: 100%;

        @media screen and (max-width: 699px) {
         top: -80vw;
        }
      }
    }

    .product-text-container {
      width: calc(96% - 280px);

      a {
        color: #676767;
        text-decoration: underline;
      }

      @media screen and (max-width: 699px) {
        width: 100%;
      }

      .episode-number {
        font-size: 1.6em;
        font-weight: normal;
        margin-bottom: 0.3em;
      }

      .title {
        margin-top: 0;
        font-weight: normal;
        font-size: 2em;
        min-height: 50px;
        display: flex;
        flex-flow: wrap;
        margin-bottom: 0.5em;
        align-items: baseline;

        .product-title-product-title {
          width: 100%;
        }

        .product-title-number-episodes {
          font-size: 0.7em;
        }

        .product-title-arrow-button {
          font-size: 0.7em;
          margin-left: 2em;

          @media screen and (max-width: 799px) {
            margin-left: 0.1em;
          }

          svg {
            vertical-align: top;
          }
        }
      }

      .product-basic-info-series-display-image-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        @media screen and (max-width: 699px) {
          display: block;
        }

        .product-basic-info-container {
          width: 70%;
          @media screen and (max-width: 699px) {
            float:left;
          }
        }

        .product-series-display-image {
            width: 30%;
            max-width: 200px;

            @media screen and (max-width: 699px) {
              float:right;
            }

        }
      }

      .product-attributes {
        font-size: 1em;
        margin-top: 1em;

        @media screen and (max-width: 699px) {
          margin-bottom: 1em;
        }
      }

      .product-description {
        font-size: 1em;
        width: 100%;
        margin-top: 2em;
        clear: both;
      }

    }

    .watchlist-container {
      width: 280px;
      position: absolute;
      top: 620px;
      display: flex;
      flex-direction: column;
      align-items: center;


      @media screen and (max-width: 699px) {
        position: static;
        width: 100%;
        margin-top: 2em;
      }

      .watchlist-headline {
        margin-bottom: 0.5em;

        a {
          color: #676767;
          text-decoration: underline;
        }
      }

      .watchlist-profile-select {
        height: 60px;
        font-size: 16px;
        width: 250px;
        border-radius: 35px;
        text-align: center;

        @media screen and (max-width: 499px) {
          width: 100%;
        }
      }

      .watchlist-button-box {
        text-align: center;
        font-size: 1.6em;
        margin: 0.5em auto 2em auto;
        font-weight: bold;

        button {
          margin-left: auto;
          margin-right: auto;
        }

        @media screen and (max-width: 699px) {
          width: 100%;
        }
      }


    }
  }

  .prev-next-products-in-series-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    width: 85%;
    height: auto;
    margin: 50px auto 0;
    vertical-align: middle;
    color: #676767;

    @media screen and (max-width: 699px) {
      justify-content: center;
    }

    .prev-product-container {
      width: 30%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      flex-direction: column;
      align-content: center;

      @media screen and (max-width: 699px) {
        width: 100%;
        margin-bottom: 2em;
        order: 1;
      }
    }

    .product-start-tigertones {
      width: 30%;

      .cluster-product-button {
        width:185px;
        margin: 2em auto 0 auto;
      }

      @media screen and (max-width: 699px) {
        width: 100%;
        margin-bottom: 2em;
        order: 3;
      }
    }

    .next-product-container {
      width: 30%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      flex-direction: column;
      align-content: center;

      @media screen and (max-width: 699px) {
        width: 100%;
        margin-bottom: 2em;
        order: 2;
      }
    }

    .prev-next-text {
      margin-bottom: 1em;
    }
  }

  .product-cta {
    width: 80%;
    text-align: center;
    font-size: 1.6em;
    margin: 4em auto 0 auto;
    font-weight: bold;

    a:hover {
      text-decoration: none;
    }
  }

  @media screen and (min-width: 1500px) {
  }

  @media screen and (min-width: 1100px) and (max-width: 1699px) {
  }

  @media screen and (min-width: 800px) and (max-width: 1099px) {
  }

}

#episode-popover {
  min-width: fit-content;
  width: 30%;
  max-width: 30%;

  @media screen and (max-width: 699px) {
    width: 80%;
    max-width: 80%;
  }

  .popover-header {
    background-color: #1eaf8c;
    color: white;
  }

  .popover-body {
    padding: 1.2em;
    background-color: #e6e6e6;
  }


}

