.cluster-product-container {
  .cluster-product-item {
    color: #676767;
    flex-direction: row;
    flex-wrap: wrap;

    @media screen and (max-width: 699px) {
      flex-direction: column;
      align-items: center;
    }

    .cluster-product-item-cover-container {
      width: calc(200px + 2.5rem);
      max-width: calc(200px + 2.5rem);
      max-height: calc(200px + 2.5rem);
      padding: 1.25rem;

      @media screen and (max-width: 699px) {
        width: 85%;
        padding: 0;
        margin-top: 1.25rem;
      }
    }

    .cluster-product-item-title {
      .product-title-product-title {
        font-size: 1.3rem;
      }

      .product-title-series-title {
        font-weight: normal;
        font-size: 1rem;
      }

      a {
        color: #676767;

        &:hover {
          color: #676767;
        }
      }

    }

    .card-text {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }
}

.w-80 {
    width: 80% !important;
}