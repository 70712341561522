@media screen and (min-width: 1200px){
    .tigerpost-container {
        min-height: 600px;
    }

    .tigerpost-title {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 32pt;
    }

    .tigerpost-info {
        width: 80%;
        margin: 80px auto;
        font-size: 18pt;
        text-align: center;
    }

    .tigerpost-form {
        width: 40%;
        margin: 60px auto;
    }

    .tigerpost-button {
        width: 50%;
        margin: 10px auto;
    }

    .tigerpost-dsgvo {
        text-align: center;
        font-size: 11pt;
    }
}

@media screen and (min-width: 800px) and (max-width: 1199px){
    .tigerpost-container {
        min-height: 600px;
    }

    .tigerpost-title {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 32pt;
    }

    .tigerpost-info {
        width: 80%;
        margin: 80px auto;
        font-size: 18pt;
        text-align: center;
    }

    .tigerpost-form {
        width: 60%;
        margin: 60px auto;
    }

    .tigerpost-button {
        width: 60%;
        margin: 10px auto;
    }

    .tigerpost-dsgvo {
        text-align: center;
        font-size: 11pt;
    }
}

@media screen and (min-width: 500px) and (max-width: 799px){
    .tigerpost-container {
        min-height: 600px;
    }

    .tigerpost-title {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 32pt;
    }

    .tigerpost-info {
        width: 80%;
        margin: 80px auto;
        font-size: 18pt;
        text-align: center;
    }

    .tigerpost-form {
        width: 60%;
        margin: 60px auto;
    }

    .tigerpost-button {
        width: 60%;
        margin: 10px auto;
    }

    .tigerpost-dsgvo {
        text-align: center;
        font-size: 11pt;
    }
}

@media screen and (max-width: 499px){
    .tigerpost-container {
        min-height: 600px;
    }

    .tigerpost-title {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 32pt;
    }

    .tigerpost-info {
        width: 80%;
        margin: 80px auto;
        font-size: 16pt;
        text-align: center;
    }

    .tigerpost-form {
        width: 80%;
        margin: 60px auto;
    }

    .tigerpost-button {
        width: 100%;
        margin: 10px auto;
    }

    .tigerpost-dsgvo {
        text-align: center;
        font-size: 11pt;
    }
}
