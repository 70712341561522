.switch {
    position: relative;
    display: inline-block;
    width: 5rem;
    height: 2.125rem;
    cursor: pointer;
}

.switch .switch-input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e50045;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 2.125rem;
}

.switch .slider:before {
    position: absolute;
    content: "";
    height: 1.625rem;
    width: 1.625rem;
    left: 0.25rem;
    bottom: 0.25rem;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

.switch .switch-input:checked + .slider {
    background-color: #e50045;
}

.switch .switch-input:focus + .slider {
    box-shadow: 0 0 0.0625rem #e50045;
}

.switch .switch-input:checked + .slider:before {
    transform: translateX(2.85rem);
}

.switch .switch-text-checked {
    position: absolute;
    top: 0.37rem;
    left: 0.8rem;
    margin-top: 0;
    cursor: pointer;
    color: white;
    font-weight: bold;
}

.switch .switch-text-unchecked {
    position: absolute;
    top: 0.37rem;
    right: 0.8rem;
    margin-top: 0;
    cursor: pointer;
    color: white;
    font-weight: bold;
}
