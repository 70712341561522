.card-item {
    margin: 0 8px;
    float: left;
    width: 78px;
    display: flex;
    flex-direction: column;
}

.clickable:hover {
    cursor: pointer;
    color: #ce023e;
}
.clickable:hover img {
    box-shadow: 0 0 0.5125rem rgba(0,0,0,0.5);
}

.card-item-icon {
    max-width: 200px;
    max-height: 200px;
    margin: 0.75rem 0;
    border-radius: 10px;
    float: left;
}

.card-item-icon-round {
    max-width: 200px;
    max-height: 78px;
    margin: 0.75rem 0;
    border-radius: 200px;
    float: left;
}

.card-item-label {
    font-size: 15px;
    font-weight: normal;
    overflow: hidden;
}

.card-item-sublabel {
    font-weight: normal;
    font-size: 13px;
}

.centered {
    text-align: center;
}