.profile-details-wrap {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 799px) {
        flex-direction: column;

        .cover-drop-container {
            min-height: 85vw;

            @media screen and (min-width: 700px) and (max-width: 799px) {
                min-height: 60vw;
            }

        }
    }

    .profile-infos-wrap {
        display: flex;
        justify-content: space-between;
    }

    .profile-name {
        button {
            position: relative;
            top: -5px;
            vertical-align: initial;
        }
    }
}

@media screen and (max-width: 399px) {

    .profile-info {
        padding-left: 0!important;
        width: 150%!important;
    }

    .profile-info-edit {
        padding-left: .25rem!important;
        width: 50%!important;
    }

    .datepicker-edit {
        /*width: 50%!important;*/
    }
}
@media screen and (min-width: 400px) and (max-width: 799px) {


    .profile-info {
        padding-left: 0!important;
        width: 150%!important;
    }

    .profile-info-edit {
        padding-left: .25rem!important;
    }

    .datepicker-edit {
        /*width: 50%!important;*/
    }
}

@media screen and (min-width: 800px) and (max-width: 999px) {

    .profile-info {
        padding-left: 0!important;
        width: 150%!important;
    }

    .profile-info-edit {
        padding-left: .25rem!important;
    }

    .datepicker-edit {
        /*width: 50%!important;*/
    }
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {

    .profile-info {
        padding-left: 0!important;
        width: 150%!important;
    }

    .profile-info-edit {
        padding-left: .25rem!important;
    }

    .datepicker-edit {
        /*width: 50%!important;*/
    }
}

@media screen and (min-width: 1200px) {


    .profile-info {
        padding-left: .25rem!important;
        width: 50%!important;
    }

    .profile-info-edit {
        padding-left: .25rem!important;
    }

    .datepicker-edit {
        /*width: 10%!important;*/
    }
}

.profile-details-input {
    margin: 1.5rem 0.75rem;
    width: 6.5rem;
    height: 2rem;
}

.invalid {
    border-color: red;
    color: red;
    border-width: 2px;
}
