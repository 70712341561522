.card-item-show-all {
    padding-top: 22%;
    padding-left: 5%;
    font-size: 1.5rem;
}

.card-item-show-all:hover {
    cursor: pointer;
    color: #ce023e;
}

.card-item-show-all a:hover {
    cursor: pointer;
    color: #ce023e;
}

.dashboard-card-header {
    font-weight: normal;
    font-size: 20px;
    padding: 0 5px;
    color: #fefefe;
    text-align: center;
    height: 57px;
    line-height: 57px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: rgba(0,0,0,0.3);
}

.dashboard-card-content {
    height: 250px;
    color: #fefefe;
    padding-left: 15px;
    padding-right: 15px;
}

.static-content-container {
    color: #fefefe;
    padding: 15px 5px 0 5px;
    height: 250px;
    line-height: 24px;
    font-size: 20px;
    text-align: center;
}

.dashboard-card-footer {
    padding-top: 12px;
    height: 73px;
}

.dashboard-text-card {
    padding: 1rem;
    color: #fefefe;
    font-weight: normal;
    font-size: 1.5rem;
    position: relative;
}

.dashboard-card-link {
    color: #fefefe;
    padding: .3rem 1rem;
    font-weight: normal;
}

.dashboard-card-link:hover {
    cursor: pointer;
    color: rgba(255,255,255,0.8);
}

.bottom {
    position: absolute;
    bottom: 0;
}

.hover:hover {
    cursor: pointer;
    color: #ce023e;
}

.centered {
    justify-content: center;
}

.small-text {
    margin-top: 10px;
    font-size: 18px;
}

.dashboard-card-grid {
    display: flex;
    justify-content: space-between;
}

.show-all {
    position: absolute;
    padding-right: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    bottom: 0;
    right: 0;
}

.show-all:hover {
    cursor: pointer;
    text-decoration: none;
    color: #ce023e;
}

.newsletter-tooltip {
    color: #fefefe;
}