@font-face {
    font-family: RockyBeachGrotesque;
    src: url("../../../../assets/fonts/RockyBeachGrotesque-RegularWeb.woff2") format("woff2");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: RockyBeachGrotesque;
    src: url("../../../../assets/fonts/RockyBeachGrotesque-BoldWeb.woff2") format("woff2");
    font-weight: bold;
    font-style: normal
}

@font-face {
    font-family: RockyBeachGrotesqueHeadline;
    src: url("../../../../assets/fonts/RockyBeachGrotesque-BoldHeadlineWeb.woff2") format("woff2");
    font-weight: bold;
    font-style: normal
}

@font-face {
    font-family: RockyBeachGrotesque;
    src: url("../../../../assets/fonts/RockyBeachGrotesque-RegularItalicWeb.woff2") format("woff2");
    font-weight: normal;
    font-style: italic
}

body {
    font-family: RockyBeachGrotesque, Mikado, sans-serif
}

.openlink a:hover {
    cursor: pointer;
    text-decoration: none;
}

.ddf-film-container {
    width: 100%;
    margin: auto;
    padding-top: 80px;
    /*background-color: #000000;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255, 255, 255, 0.1));*/
    color: #fefefe;
    font-family: RockyBeachGrotesque, Mikado, sans-serif
}

.ddf-film-info {
    line-height: 1.5;
    margin: 1em auto 0 auto;
    text-align: center;
}

.ddf-film-logo {
    width: 100%;
    text-align: center;
}

.ddf-film-title {
    width: 100%;
    text-align: center;
    color: #40bfcc;
    font-weight: bold;
}

.ddf-film-section-image-content-shifted img {
    width: 100%;
}

@media screen and (min-width: 1200px){


    .ddf-film-logo {
        padding-top: 20px;
    }

    .ddf-film-info {
        width: 80%;
        font-size: 18pt;
    }

    .ddf-film-form-container {
/*        background-image: radial-gradient(closest-side, rgba(110,120,147,1), rgba(110,120,147,0));*/
        width: 80%;
        min-height: 800px;
        margin: 2em auto 0 auto;
        position: relative;
    }

    .form-container-small {
        min-height: 500px;
    }

    .ddf-film-registration-container {
        min-height: 1200px;
    }

    .ddf-film-form {
        width: 40%;
        margin: 0 30%;
        font-size: 15pt;
    }

    .ddf-film-tiger-video {
        width: 60%;
    }

    .form-broad {
        width: 60%;
    }

    .ddf-film-button-row {
        width: 20%;
        margin: 0 auto;
    }

    .ddf-film-error-info {
        text-align: center;
        font-size: 18pt;
    }

    .ddf-film-error-button {
        width: 50%;
        margin: 0 auto;
    }

    .app-icon {
        width: 45%;
    }

    .icon-right {
        float: right;
    }

    .icon-left {
        float: left;
    }

    .ddf-film-section-white {
        width: 100%;
        margin: 0 auto 0 auto;
        padding: 5em;
        color: #010202;
        background-color: #fefefe;
        text-align: center;
        font-size: 20pt;
    }

    .ddf-film-section-black {
        width: 100%;
        margin: 2em auto 0 auto;
        padding: 3em;
        color: #fefefe;
        background-color: black;
        text-align: center;
        font-size: 20pt;
    }

    .ddf-film-section-headline {
        font-weight: bold;
        font-size: 44pt;
        line-height: 1.2em;
    }

    .ddf-film-section-text-content {
        width: 40%;
        margin: 0 auto 0 auto;
    }

    .ddf-film-section-image-content {
        width: 80%;
        margin: 2em auto;
    }

    .ddf-film-section-image-content-shifted {
        width: 50%;
        margin: 3em auto -2em auto;
    }

    .ddf-film-section-image-content-box {
        width: 100%;
        margin: 0;
    }

    .ddf-film-icon {
        width: 25%;
    }

}

@media screen and (min-width: 800px) and (max-width: 1199px){
    .ddf-film-logo {
        padding-top: 40px;
    }

    .ddf-film-info {
        width: 80%;
        font-size: 16pt;
    }

    .ddf-film-form-container {
/*        background-image: radial-gradient(closest-side, rgba(110,120,147,1), rgba(110,120,147,0));*/
        width: 80%;
        min-height: 800px;
        margin: 2em auto 0 auto;
        position: relative;
    }

    .form-container-small {
        min-height: 500px;
    }

    .ddf-film-registration-container {
        min-height: 1200px;
    }

    .ddf-film-form {
        width: 80%;
        margin: 0 10%;
        position: absolute;
        font-size: 15pt;
/*        left: 50%;
        top: 50%;
        -ms-transform: translateX(-50%) translateY(-60%);
        transform: translateX(-50%) translateY(-60%);*/
    }

    .form-broad {
        width: 60%;
    }

    .ddf-film-button-row {
        width: 20%;
        margin: 0 auto;
    }

    .ddf-film-error-info {
        text-align: center;
        font-size: 18pt;
    }

    .ddf-film-error-button {
        width: 50%;
        margin: 0 auto;
    }

    .app-icon {
        width: 45%;
    }

    .icon-right {
        float: right;
    }

    .icon-left {
        float: left;
    }

    .ddf-film-section-white {
        width: 100%;
        margin: 0 auto 0 auto;
        padding: 0;
        color: #010202;
        background-color: #fefefe;
        text-align: center;
        font-size: 20pt;
    }

    .ddf-film-section-black {
        width: 100%;
        margin: 2em auto 0 auto;
        padding: 1em;
        color: #fefefe;
        background-color: #010202;
        text-align: center;
        font-size: 20pt;
    }

    .ddf-film-section-headline {
        font-weight: bold;
        font-size: 35pt;
        line-height: 1.2em;
    }

    .ddf-film-section-text-content {
        width: 70%;
        margin: 0 auto 0 auto;
    }

    .ddf-film-section-image-content {
        width: 120%;
        margin: 2em -10%;
    }

    .ddf-film-section-image-content-shifted {
        width: 100%;
        margin: 6em auto -2em auto;
    }

    .ddf-film-section-image-content-box {
        width: 160%;
        margin: -2em -30%;
    }

    .ddf-film-icon {
        width: 40%;
    }

}

@media screen and (min-width: 500px) and (max-width: 799px){
    .ddf-film-container {
        padding-top: 60px;
    }

    .ddf-film-logo {
        padding-top: 40px;
    }

    .ddf-film-taipan {
        width: 100%;
        margin-top: 20px;
    }

    .ddf-film-info {
        width: 90%;
        font-size: 17px;
    }

    .ddf-film-form-container {
        background-image: radial-gradient(closest-side, rgba(110,120,147,1), rgba(110,120,147,0));
        width: 80%;
        min-height: 800px;
        margin: 2em auto 0 auto;
        position: relative;
    }

    .form-container-small {
        min-height: 500px;
    }

    .ddf-film-registration-container {
        min-height: 1200px;
    }

    .ddf-film-form {
        width: 90%;
        margin: 0 5%;
        font-size: 15pt;
/*        left: 50%;
        top: 50%;
        -ms-transform: translateX(-50%) translateY(-60%);
        transform: translateX(-50%) translateY(-60%);*/
    }

    .form-broad {
        width: 60%;
    }

    .ddf-film-button-row {
        width: 20%;
        margin: 0 auto;
    }

    .ddf-film-error-info {
        text-align: center;
        font-size: 18pt;
    }

    .ddf-film-error-button {
        width: 50%;
        margin: 0 auto;
    }

    .app-icon {
        width: 45%;
    }

    .icon-right {
        float: right;
    }

    .icon-left {
        float: left;
    }

    .ddf-film-section-white {
        width: 100%;
        margin: 0 auto 0 auto;
        padding: 3em;
        color: #010202;
        background-color: #fefefe;
        text-align: center;
        font-size: 20pt;
    }

    .ddf-film-section-black {
        width: 100%;
        margin: 2em auto 0 auto;
        padding: 1em;
        color: #fefefe;
        background-color: #010202;
        text-align: center;
        font-size: 20pt;
    }

    .ddf-film-section-headline {
        font-weight: bold;
        font-size: 34pt;
        line-height: 1.2em;
    }

    .ddf-film-section-text-content {
        width: 70%;
        margin: 0 auto 0 auto;
    }

    .ddf-film-section-image-content {
        width: 120%;
        margin: 2em -10%;
    }

    .ddf-film-section-image-content-shifted {
        width: 100%;
        margin: -2em auto;
    }

    .ddf-film-section-image-content-box {
        width: 160%;
        margin: -2em -30%;
    }

    .ddf-film-icon {
        width: 50%;
    }

}

@media screen and (max-width: 499px){
    .ddf-film-container {
        padding-top: 40px;
    }

    .ddf-film-logo {
        padding-top: 40px;
    }

    .ddf-film-taipan {
        width: 100%;
        margin-top: 20px;
    }

    .ddf-film-info {
        width: 90%;
        font-size: 17px;
    }

    .ddf-film-form-container {
        background-image: radial-gradient(closest-side, rgba(110,120,147,1), rgba(110,120,147,0));
        width: 90%;
        min-height: 800px;
        margin: 2em auto 0 auto;
        position: relative;
    }

    .form-container-small {
        min-height: 500px;
    }

    .ddf-film-registration-container {
        min-height: 1200px;
    }

    .ddf-film-form {
        width: 90%;
        margin: 0 5%;
        font-size: 12pt;
/*        left: 50%;
        top: 50%;
        -ms-transform: translateX(-50%) translateY(-60%);
        transform: translateX(-50%) translateY(-60%);*/
    }

    .form-broad {
        width: 60%;
    }

    .ddf-film-button-row {
        width: 20%;
        margin: 0 auto;
    }

    .ddf-film-error-info {
        text-align: center;
        font-size: 18pt;
    }

    .ddf-film-error-button {
        width: 50%;
        margin: 0 auto;
    }

    .app-icon {
        width: 45%;
    }

    .icon-right {
        float: right;
    }

    .icon-left {
        float: left;
    }

    .ddf-film-section-white {
        width: 100%;
        margin: 0 auto 0 auto;
        padding: 2em;
        color: #010202;
        background-color: #fefefe;
        text-align: center;
        font-size: 16pt;
    }

    .ddf-film-section-black {
        width: 100%;
        margin: 1em auto 0 auto;
        padding: 1em;
        color: #fefefe;
        background-color: #010202;
        text-align: center;
        font-size: 16pt;
    }

    .ddf-film-image-section-black {
        width: 100%;
        margin: 1em auto 0 auto;
        padding: 0;
        color: #fefefe;
        background-color: #010202;
        text-align: center;
        font-size: 16pt;
    }

    .ddf-film-section-headline {
        font-weight: bold;
        font-size: 26pt;
        line-height: 1.2em;
    }

    .ddf-film-section-text-content {
        width: 95%;
        margin: 0 auto 0 auto;
    }

    .ddf-film-section-image-content {
        width: 110%;
        margin: 2em -5%;
    }

    .ddf-film-section-image-content-shifted {
        width: 100%;
        margin: -2em 0;
    }

    .ddf-film-section-image-content-box {
        width: 220%;
        margin: -2em -60%;
    }

    .ddf-film-icon {
        width: 60%;
    }
}
