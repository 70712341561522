@media screen and (max-width: 399px) {
    .device-details-wrap {
        flex-direction: column;
    }

    .device-infos-wrap {
        display: flex;
        justify-content: space-between;
    }

    .device-info {
        padding-left: 0!important;
        width: 150%!important;
    }

    .device-info-edit {
        padding-left: .25rem!important;
        width: 50%!important;
    }
}
@media screen and (min-width: 400px) and (max-width: 699px) {
    .device-details-wrap {
        flex-direction: column;
    }

    .device-infos-wrap {
        display: flex;
        justify-content: space-between;
    }

    .device-info {
        padding-left: 0!important;
        width: 150%!important;
    }

    .device-info-edit {
        padding-left: .25rem!important;
        width: 50%!important;
    }
}

@media screen and (min-width: 700px) and (max-width: 999px) {
    .device-details-wrap {
        display: flex;
        justify-content: space-between;
    }

    .device-infos-wrap {
        display: flex;
        justify-content: space-between;
    }

    .device-info {
        padding-left: 0!important;
        width: 150%!important;
    }

    .device-info-edit {
        padding-left: .25rem!important;
        width: 50%!important;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
    .device-details-wrap {
        display: flex;
        justify-content: space-between;
    }

    .device-infos-wrap {
        display: flex;
        justify-content: space-between;
    }

    .device-info {
        padding-left: 0!important;
        width: 150%!important;
    }

    .device-info-edit {
        padding-left: .25rem!important;
        width: 50%!important;
    }
}

@media screen and (min-width: 1200px) {
    .device-details-wrap {
        display: flex;
        justify-content: space-between;
    }

    .device-infos-wrap {
        display: flex;
        justify-content: space-between;
    }

    .device-info {
        padding-left: .25rem!important;
        width: 50%!important;
    }

    .device-info-edit {
        padding-left: .25rem!important;
        width: 100%!important;
    }
}

.device-details-input {
    margin: 1.5rem 0.75rem;
    width: 6.5rem;
    height: 2rem;
}

.invalid {
    border-color: red;
    color: red;
    border-width: 2px;
}

.device-general-details {
    font-weight: bold;
    color: #838383;
}