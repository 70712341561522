.dashboard {
    max-width: 1035px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .dashboard-card {
        height: 100%;
        width: 315px;
        float: left;
        margin: 15px;
        display: inline-block;
    }

    .dashboard-card-title {
        text-align: center;
        font-family: MikadoBlack, sans-serif;
        text-decoration: none;
        font-size: 24px;
        text-transform: uppercase;

        a {
            color: inherit;

            &:hover{
                text-decoration: none;
            }
        }
    }

    .dashboard-card-body {
        height: 380px;
        border-radius: 10px;
        position: relative;
        margin-top: 5px;
        text-align: center;
    }

    a {
        color: #ffffff;

        &:hover{
            color: #ce023e;
            text-decoration: none;
        }
    }
}

.dashboard-card-button {
    background-color: white;
    font-weight: bold;
    font-size: 20px;
    color: #ce023e;
    padding: 5px 15px;
    border-radius: 35px;
    text-decoration: none;
    cursor: pointer;
    outline: 0;
    border: 0;
}


.newsletter-tooltip {
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: rgba(100,0,0,0.5);
    padding: 2%;
    width: 100%;
    height: 100%;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
}

.with-hover:hover, a .with-hover:hover {
    cursor: pointer;
    color: #ce023e;
}

