@media screen and (min-width: 1200px){
    .ticket-redemption-container {
        width: 100%;
        margin: auto;
        /*background-color: #000000;*/
        color: #333;
    }

    .ticket-redemption-title {
        width: 100%;
        height: auto;
        margin-bottom: 50px;
        text-align: center;
        padding-top: 20px;
    }
    .ticket-redemption-title h1 {
        font-size: 32pt;
        font-weight: bold;
    }

    .ticket-redemption-info {
        width: 50%;
        font-size: 18pt;
        line-height: 1.5;
        margin: 0 auto;
        text-align: center;
    }

    .ticket-redemption-product-details {
        width: 25%;
        font-size: 20pt;
        line-height: 1.8;
        margin: 15px auto;
        text-align: left;
        padding: 0;
    }

    .ticket-redemption-form-container {
        width: 80%;
        min-height: 800px;
        margin: 0 auto;
        position: relative;
    }

    .form-container-small {
        min-height: 500px;
    }

    .ticket-redemption-registration-container {
        min-height: 1200px;
    }

    .ticket-redemption-form {
        width: 40%;
        margin: 0 auto;
        position: absolute;
        font-size: 15pt;
        left: 50%;
        top: 50%;
        -ms-transform: translateX(-50%) translateY(-60%);
        transform: translateX(-50%) translateY(-60%);
    }

    .form-broad {
        width: 60%;
    }

    .ticket-redemption-button-row {
        width: 20%;
        margin: 0 auto;
    }

    .ticket-redemption-error-info {
        text-align: center;
        font-size: 18pt;
    }

    .ticket-redemption-error-button {
        width: 50%;
        margin: 0 auto;
    }

    .ticket-period {
        /* FONT STUFF*/
        /*font-family: MikadoBlack, sans-serif;*/
        font-size: 24pt;
        font-weight: bold;
        color: #f08201;

        /* TRANSFORMATION */
        transform: rotate(-5deg);
        -webkit-transform:rotate(-5deg);
        -moz-transform:rotate(-5deg);
        -ms-transform:rotate(-5deg);
        -o-transform:rotate(-5deg);
        margin-bottom: -16px;

        background: -webkit-linear-gradient(0deg ,#f07f00 ,#ffca03);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

    }

    .ticket-pic {
        width: 16%;
        margin: 30px auto;
    }

    .app-icon {
        width: 45%;
    }

    .icon-right {
        float: right;
    }

    .icon-left {
        float: left;
    }
}

@media screen and (min-width: 800px) and (max-width: 1199px){
    .ticket-redemption-container {
        width: 100%;
        margin: auto;
        /*background-color: #000000;*/
        color: #333;
    }

    .ticket-redemption-title {
        width: 100%;
        height: auto;
        margin-bottom: 50px;
        text-align: center;
        padding-top: 40px;
    }
    .ticket-redemption-title h1 {
        font-size: 32pt;
        font-weight: bold;
    }

    .ticket-redemption-info {
        width: 80%;
        font-size: 16pt;
        line-height: 1.5;
        margin: 0 auto;
        text-align: center;
    }

    .ticket-redemption-product-details {
        width: 80%;
        font-size: 16pt;
        line-height: 1.5;
        margin: 0 auto;
    }

    .ticket-redemption-form-container {
        width: 80%;
        min-height: 800px;
        margin: 0 auto;
        position: relative;
    }

    .form-container-small {
        min-height: 500px;
    }

    .ticket-redemption-registration-container {
        min-height: 1200px;
    }

    .ticket-redemption-form {
        width: 80%;
        margin: 0 auto;
        position: absolute;
        font-size: 15pt;
        left: 50%;
        top: 50%;
        -ms-transform: translateX(-50%) translateY(-60%);
        transform: translateX(-50%) translateY(-60%);
    }

    .ticket-period {
        /* FONT STUFF*/
        /*font-family: MikadoBlack, sans-serif;*/
        font-size: 26pt;
        font-weight: bold;
        color: #f08201;

        /* TRANSFORMATION */
        transform: rotate(-5deg);
        -webkit-transform:rotate(-5deg);
        -moz-transform:rotate(-5deg);
        -ms-transform:rotate(-5deg);
        -o-transform:rotate(-5deg);
        margin-bottom: -16px;

        background: -webkit-linear-gradient(0deg ,#f07f00 ,#ffca03);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

    }

    .ticket-pic {
        width: 25%;
        margin: 30px auto;
    }

    .form-broad {
        width: 60%;
    }

    .ticket-redemption-button-row {
        width: 20%;
        margin: 0 auto;
    }

    .ticket-redemption-error-info {
        text-align: center;
        font-size: 18pt;
    }

    .ticket-redemption-error-button {
        width: 50%;
        margin: 0 auto;
    }

    .app-icon {
        width: 45%;
    }

    .icon-right {
        float: right;
    }

    .icon-left {
        float: left;
    }
}

@media screen and (min-width: 500px) and (max-width: 799px){
    .ticket-redemption-container {
        width: 100%;
        margin: auto;
        /*background-color: #000000;*/
        color: #333;
    }

    .ticket-redemption-title {
        width: 100%;
        height: auto;
        margin-bottom: 50px;
        text-align: center;
        padding-top: 40px;
    }
    .ticket-redemption-title h1 {
        font-size: 30pt;
        font-weight: bold;
    }

    .ticket-redemption-info {
        width: 90%;
        font-size: 16pt;
        line-height: 1.5;
        margin: 0 auto;
        text-align: center;
    }

    .ticket-redemption-product-details {
        width: 80%;
        font-size: 16pt;
        line-height: 1.5;
        margin: 0 auto;
    }

    .ticket-redemption-form-container {
        width: 80%;
        min-height: 800px;
        margin: 0 auto;
        position: relative;
    }

    .form-container-small {
        min-height: 500px;
    }

    .ticket-redemption-registration-container {
        min-height: 1200px;
    }

    .ticket-redemption-form {
        width: 90%;
        margin: 0 auto;
        position: absolute;
        font-size: 15pt;
        left: 50%;
        top: 50%;
        -ms-transform: translateX(-50%) translateY(-60%);
        transform: translateX(-50%) translateY(-60%);
    }

    .ticket-period {
        /* FONT STUFF*/
        /*font-family: MikadoBlack, sans-serif;*/
        font-size: 20pt;
        font-weight: bold;
        color: #f08201;

        /* TRANSFORMATION */
        transform: rotate(-5deg);
        -webkit-transform:rotate(-5deg);
        -moz-transform:rotate(-5deg);
        -ms-transform:rotate(-5deg);
        -o-transform:rotate(-5deg);
        margin-bottom: -16px;

        background: -webkit-linear-gradient(0deg ,#f07f00 ,#ffca03);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

    }

    .ticket-pic {
        width: 40%;
        margin: 30px auto;
    }

    .form-broad {
        width: 60%;
    }

    .ticket-redemption-button-row {
        width: 20%;
        margin: 0 auto;
    }

    .ticket-redemption-error-info {
        text-align: center;
        font-size: 18pt;
    }

    .ticket-redemption-error-button {
        width: 50%;
        margin: 0 auto;
    }

    .app-icon {
        width: 45%;
    }

    .icon-right {
        float: right;
    }

    .icon-left {
        float: left;
    }
}

@media screen and (max-width: 499px){
    .ticket-redemption-container {
        width: 100%;
        margin: auto;
        /*background-color: #000000;*/
        color: #333;
    }

    .ticket-redemption-title {
        width: 100%;
        height: auto;
        margin-bottom: 40px;
        text-align: center;
        padding-top: 40px;
    }
    .ticket-redemption-title h1 {
        font-size: 30pt;
        font-weight: bold;
    }

    .ticket-redemption-info {
        width: 90%;
        font-size: 14pt;
        line-height: 1.5;
        margin: 0 auto;
        text-align: center;
    }

    .ticket-redemption-product-details {
        width: 80%;
        font-size: 16pt;
        line-height: 1.5;
        margin: 0 auto;
    }

    .ticket-redemption-form-container {
        width: 90%;
        min-height: 800px;
        margin: 0 auto;
        position: relative;
    }

    .form-container-small {
        min-height: 500px;
    }

    .ticket-redemption-registration-container {
        min-height: 1200px;
    }

    .ticket-redemption-form {
        width: 90%;
        margin: 0 auto;
        position: absolute;
        font-size: 12pt;
        left: 50%;
        top: 50%;
        -ms-transform: translateX(-50%) translateY(-60%);
        transform: translateX(-50%) translateY(-60%);
    }

    .ticket-period {
        /* FONT STUFF*/
        /*font-family: MikadoBlack, sans-serif;*/
        font-size: 20pt;
        font-weight: bold;
        color: #f08201;

        /* TRANSFORMATION */
        transform: rotate(-5deg);
        -webkit-transform:rotate(-5deg);
        -moz-transform:rotate(-5deg);
        -ms-transform:rotate(-5deg);
        -o-transform:rotate(-5deg);
        margin-bottom: -16px;

        background: -webkit-linear-gradient(0deg ,#f07f00 ,#ffca03);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

    }

    .ticket-pic {
        width: 60%;
        margin: 30px auto;
    }

    .form-broad {
        width: 60%;
    }

    .ticket-redemption-button-row {
        width: 20%;
        margin: 0 auto;
    }

    .ticket-redemption-error-info {
        text-align: center;
        font-size: 18pt;
    }

    .ticket-redemption-error-button {
        width: 50%;
        margin: 0 auto;
    }

    .app-icon {
        width: 45%;
    }

    .icon-right {
        float: right;
    }

    .icon-left {
        float: left;
    }
}
