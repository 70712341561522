.cover-container {
    position: relative;
    width: max-content;

    @media screen and (max-width: 399px) {
        width: 85%;
        margin: 0 auto;
    }

    @media screen and (min-width: 400px) and (max-width: 699px) {
        width: 85%;
        margin: 0 auto;
    }

    @media screen and (min-width: 700px) and (max-width: 799px) {
        width: 60%;
        margin: 0 auto;
    }

    .cover {
        border: 1px solid gray;
        width: 100%;
        border-radius: 1rem;
        padding-top: 2em;
        padding-bottom: 2em;

        .cover-img {
            border-radius: 1rem;
            width: 90%;
            margin-left: 5%;

            @media screen and (min-width: 1000px) {
                width: 80%;
                margin-left: 10%;
            }
        }

    }

    .lock {
        position: absolute;
        bottom: 10px;
        right: 16px;
    }

    .badge-container {
        position: absolute;
        top: -1px;
        margin-left: 1.25rem;
    }

    .badge-icon {
        position: absolute;
        margin: 0.4rem 0 0 0.4rem;
        width: 83%;
        z-index: 99;
    }

    .button-container {
        position: absolute;
        padding: 1rem;
        top: 0;
        right: 0;
        margin-left: 1.25rem;
    }
}
