.landingpage-header {
    width: 100%;
    text-align: center;
}

.unsubscribe-link {
    color: #CE023E;
    cursor: pointer;
}

.unsubscribe-link:hover {
    text-decoration: underline;
}

@media screen and (min-width: 1200px) {
    .landing-page-body {
        width: 80%;
        margin: 50px auto;
        font-size: 24px;
    }

    .landingpage-checkbox {
        margin-bottom: 20px;
        font-size: 20px;
    }

    .landingpage-checkbox-label {
        font-size: 24px;
        font-weight: bold;
        margin-left: 40px;
        display: inline-block;
        margin-top: -5px;
        cursor: pointer;
        width: 200px;
    }

    .newsletter-dsgvo {
        margin-top: 40px;
        font-size: 14px;
        text-align: center;
    }

    .landing-page-input {
        width: 50%;
        margin: 50px auto;
    }
}

@media screen and (min-width: 800px) and (max-width: 1199px) {
    .landing-page-body {
        width: 100%;
        margin: 50px auto;
        font-size: 24px;
    }

    .landingpage-checkbox {
        margin-bottom: 20px;
        font-size: 20px;
    }

    .landingpage-checkbox-label {
        font-size: 24px;
        font-weight: bold;
        margin-left: 40px;
        display: inline-block;
        margin-top: -5px;
        cursor: pointer;
        width: 200px;
    }

    .newsletter-dsgvo {
        margin-top: 40px;
        font-size: 14px;
        text-align: center;
    }

    .landing-page-input {
        width: 70%;
        margin: 50px auto;
    }
}

@media screen and (min-width: 400px) and (max-width: 799px) {
    .landing-page-body {
        width: 100%;
        margin: 50px auto;
        font-size: 24px;
    }

    .landingpage-checkbox {
        margin-bottom: 20px;
        font-size: 20px;
    }

    .landingpage-checkbox-label {
        font-size: 24px;
        font-weight: bold;
        margin-left: 40px;
        display: inline-block;
        margin-top: -5px;
        cursor: pointer;
        width: 200px;
    }

    .newsletter-dsgvo {
        margin-top: 40px;
        font-size: 14px;
        text-align: center;
    }
    .landing-page-input {
        width: 98%;
        margin: 50px auto;
    }

}

@media screen and (max-width: 399px) {
    .landing-page-body {
        width: 100%;
        margin: 50px auto;
        font-size: 24px;
    }

    .landingpage-checkbox {
        margin-bottom: 20px;
        font-size: 20px;
    }

    .landingpage-checkbox-label {
        font-size: 24px;
        font-weight: bold;
        margin-left: 40px;
        display: inline-block;
        margin-top: -5px;
        cursor: pointer;
        width: 200px;
    }

    .newsletter-dsgvo {
        margin-top: 40px;
        font-size: 14px;
        text-align: center;
    }

    .landing-page-input {
        width: 98%;
        margin: 50px auto;
    }
}