
.active-contract {
    border-left: solid 5px #299400;
    border-right: solid 5px #299400;
}

.inactive-contract {
    border-left: solid 5px #515151;
    border-right: solid 5px #515151;
}

.premium-box {
    width: 100%;
}

.selected-item {
    background-color: rgba(250,185,0, 0.6);
}

@media screen and (min-width: 1600px) {
    .premium-placeholder {
        height: 120px;
    }

    .box-left {
        width: 44%;
        float: left;
        display: inline-block;
        height: 500px;
        min-height: 500px;
    }
    .contracts-box {
        width: 100%;
        height: 500px;
        background-color: #f6f6f6;
        border-radius: 10px;
        overflow-y: scroll;
        min-height: 500px;
        position: relative;
    }

    .contracts-info {
        width: 100%;
        text-align: center;
        position: absolute;
        font-size: 15pt;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .contracts-info img {
        width: 15%;
        padding-bottom: 50px;
    }

    .contract-dates {
        float: right;
        margin-top: -10px;
    }

    .box-title {
        width: 100%;
        font-size: 28px;
        font-weight: bold;
    }

    .box-info {
        width: 100%;
        font-size: 18px;
    }

    .box-right {
        width: 54%;
        margin-left: 2%;
        display: inline-block;
        height: 500px;
        min-height: 500px;
    }

    .contract-item {
        width: 100%;
        height: 80px;
        margin-bottom: 5px;
        background-color: #c6c6c6;
        border-radius: 10px;
        cursor: pointer;
        font-size: 18px;
        padding: 24px 12px;
    }

    .contract-item:hover {
        background-color: #ec8320;
    }

    .invoices-box {
        width: 100%;
        height: 500px;
        background-color: #f6f6f6;
        border-radius: 10px;
        position: relative;
        overflow-y: scroll;
        min-height: 500px;
    }

    .invoice-item {
        width: 100%;
        height: 80px;
        background-color: rgba(250,185,0, 0.6);
        border-radius: 10px;
        margin-bottom: 5px;

        padding: 12px 20px;
        font-size: 20px;
    }

    .invoice-item-no{
        font-size: 16px;
    }

    .invoice-download-button {
        float: right;
        padding-top: 5px;
    }

    .invoice-download-button-link {
        font-size: 15px;
    }

    .box-full {
        width: 100%;
        display: inline-block;
        min-height: 500px;
        background-color: #f6f6f6;
    }

    .invoices-info {
        width: 100%;
        text-align: center;
        position: absolute;
        font-size: 15pt;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .invoices-info img {
        width: 12%;
        padding-bottom: 50px;
    }

    .premium-placeholder {
        height: 120px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1599px) {

    .box-left {
        width: 100%;
        display: inline-block;
        height: 500px;
        min-height: 500px;
    }
    .contracts-box {
        width: 100%;
        height: 500px;
        overflow-y: scroll;
        min-height: 500px;
        background-color: #f6f6f6;
        border-radius: 10px;
        position: relative;
    }

    .contracts-info {
        width: 100%;
        text-align: center;
        position: absolute;
        font-size: 15pt;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .contracts-info img {
        width: 15%;
        padding-bottom: 50px;
    }

    .contract-dates {
        float: right;
        margin-top: -10px;
    }

    .box-title {
        width: 100%;
        font-size: 24px;
        font-weight: bold;
        margin-top: 50px;
    }

    .box-info {
        width: 100%;
        font-size: 18px;
        height: 60px;
    }

    .box-right {
        width: 100%;
        margin-top: 150px;
        display: inline-block;
        min-height: 500px;
        height: 500px;
    }

    .contract-item {
        width: 100%;
        height: 80px;
        margin-bottom: 5px;
        background-color: #c6c6c6;
        border-radius: 10px;
        cursor: pointer;
        font-size: 16px;
        padding: 25px 12px;
    }

    .contract-item:hover {
        background-color: #ec8320;
    }

    .invoices-box {
        width: 100%;
        height: 500px;
        min-height: 500px;
        background-color: #f6f6f6;
        position: relative;
        border-radius: 10px;
        overflow-y: scroll;
    }

    .invoice-item {
        width: 100%;
        height: 80px;
        background-color: rgba(250,185,0, 0.6);
        border-radius: 10px;
        margin-bottom: 5px;

        padding: 12px 20px;
        font-size: 18px;
    }

    .invoice-item-no{
        font-size: 16px;
    }

    .invoice-download-button {
        float: right;
        padding-top: 6px;
    }

    .invoice-download-button-link {
        font-size: 15px;
    }

    .box-full {
        width: 100%;
        display: inline-block;
        min-height: 500px;
        background-color: #f6f6f6;
    }

    .invoice-item-number {
        font-size: 20px;
        font-weight: bold;
        margin: 10px;
        width: 60%;
        text-align: center;
    }

    .invoices-info {
        width: 100%;
        text-align: center;
        position: absolute;
        font-size: 15pt;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .invoices-info img {
        width: 12%;
        padding-bottom: 50px;
    }

    .premium-placeholder {
        height: 120px;
    }
}

@media screen and (min-width: 600px) and (max-width: 999px) {

    .box-left {
        width: 100%;
        float: left;
        display: inline-block;
    }
    .contracts-box {
        width: 100%;
        height: auto;
        min-height: 500px;
        background-color: #f6f6f6;
        border-radius: 10px;
        position: relative;
    }

    .contracts-info {
        width: 100%;
        text-align: center;
        position: absolute;
        font-size: 14pt;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .contracts-info img {
        width: 15%;
        padding-bottom: 50px;
    }

    .contract-dates {
        float: right;
        margin-top: -10px;
    }

    .box-title {
        width: 100%;
        font-size: 24px;
        font-weight: bold;
    }

    .box-info {
        width: 100%;
        font-size: 18px;
        min-height: 55px;
    }

    .box-right {
        width: 100%;
        display: inline-block;
        margin-top: 50px;
    }

    .contract-item {
        width: 100%;
        height: 80px;
        margin-bottom: 5px;
        background-color: #c6c6c6;
        border-radius: 10px;
        cursor: pointer;
        font-size: 16px;
        padding: 24px 10px;
    }

    .contract-item:hover {
        background-color: #ec8320;
    }

    .invoices-box {
        width: 100%;
        height: auto;
        min-height: 500px;
        background-color: #f6f6f6;
        border-radius: 10px;
        position: relative;
    }

    .invoice-item {
        width: 100%;
        height: 80px;
        background-color: rgba(250,185,0, 0.6);
        border-radius: 10px;
        margin-bottom: 5px;

        padding: 16px 20px;
        font-size: 18px;
    }

    .invoice-item-no{
        font-size: 16px;
    }

    .invoice-download-button {
        float: right;
        padding-top: 5px;
    }

    .invoice-download-button-link {
        font-size: 15px;
    }

    .box-full {
        width: 100%;
        display: inline-block;
        min-height: 1200px;
        background-color: #f6f6f6;
    }

    .invoice-item-number {
        font-size: 20px;
        font-weight: bold;
        margin: 10px;
        width: 60%;
        text-align: center;
    }

    .invoices-info {
        width: 100%;
        text-align: center;
        position: absolute;
        font-size: 15pt;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .invoices-info img {
        width: 12%;
        padding-bottom: 50px;
    }

    .premium-placeholder {
        height: 50px;
    }
}

@media screen and (max-width: 599px) {

    .box-left {
        width: 100%;
        float: left;
        display: inline-block;
    }
    .contracts-box {
        width: 100%;
        height: auto;
        min-height: 500px;
        background-color: #f6f6f6;
        border-radius: 10px;
        position: relative;
    }

    .contracts-info {
        width: 100%;
        text-align: center;
        position: absolute;
        font-size: 14pt;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .contracts-info img {
        width: 25%;
        padding-bottom: 50px;
    }

    .contract-dates {
        border-top: solid 1px #333333;
        margin-top: 5px;
        padding-top: 5px;
        width: 100%;
    }

    .box-title {
        width: 100%;
        font-size: 24px;
        font-weight: bold;
    }

    .box-info {
        width: 100%;
        font-size: 14px;
        min-height: 65px;
    }

    .box-right {
        width: 100%;
        display: inline-block;
        min-height: 500px;
        margin-top: 50px;
    }

    .contract-item {
        width: 100%;
        height: 22vw;
        margin-bottom: 5px;
        background-color: #c6c6c6;
        border-radius: 10px;
        cursor: pointer;
        padding: 8px 5px;
        font-size: 3vw;
        text-align: center;
    }

    .contract-item:hover {
        background-color: #ec8320;
    }

    .invoices-box {
        width: 100%;
        height: auto;
        min-height: 500px;
        background-color: #f6f6f6;
        border-radius: 10px;
        position: relative;
    }

    .invoice-item {
        width: 100%;
        height: 140px;
        background-color: rgba(250,185,0, 0.6);
        border-radius: 10px;
        margin-bottom: 5px;

        padding: 16px 5px;
        font-size: 15px;
    }

    .invoice-item-no{
        font-size: 14px;
        margin-bottom: 10px;
    }

    .invoice-download-button {
        width: 50%;
        margin: 50px auto;
    }

    .invoice-download-button-link {
        font-size: 12px;
    }

    .box-full {
        width: 100%;
        display: inline-block;
        min-height: 1000px;
        background-color: #f6f6f6;
    }

    .invoice-item-number {
        font-size: 20px;
        font-weight: bold;
        margin: 10px;
        width: 60%;
        text-align: center;
    }

    .invoices-info {
        width: 100%;
        text-align: center;
        position: absolute;
        font-size: 12pt;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .invoices-info img {
        width: 25%;
        padding-bottom: 50px;
    }

    .premium-placeholder {
        height: 50px;
    }
}