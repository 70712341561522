.btn-ci {
    display: block;
    padding: 10px;
    border-radius: 35px;
    outline: 0;
    border: 0;
    color: #fff;
    cursor: pointer;
    height: 60px;
    width: 250px;

    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
}

.modal-footer .btn-ci {
    width: 200px;
}

.btn-ci-outlined {
    display: block;
    padding: 10px;
    border-radius: 35px;
    border: 2px solid;
    outline: 0;
    background-color: #fff;
    cursor: pointer;

    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;

    height: 60px;
    width: 250px
}

.btn-ci span {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 27px;
    font-weight: normal;
    font-size: 16px;
    text-transform: uppercase;
}

.btn-ci span > * {
    margin-right: 0.5rem;
}

.btn-ci:disabled {
    background-color: #d3d3d3 !important;
    cursor: default;
}

.btn-ci:disabled span {
    background-color: #d3d3d3 !important;
    color: grey;
}

.btn-ci:active, .btn-ci:focus {
    filter: brightness(115%);
}

.btn-ci-hover {
    transition: all .2s ease-in-out;
}

.btn-ci-hover:hover {
    transform: scale(1.1);
}

.btn-ci-sm {
    font-size: 15px;
}

.btn-ci-sm span {
    padding: 6px 15px 6px;
}
