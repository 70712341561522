.wildcards-container {
  margin-top: 2em;

  .wildcards-items-container {
    justify-content: center;

    @media screen and (max-width: 399px) {
      flex-direction: column;
      align-content: center;
    }

    .wildcard {
      background-color: white;
      border-radius: 1rem !important;
      box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.5);
      margin: 0.9375rem 0.625rem;
      text-align: center;
      text-decoration: none;
      width: 400px;
      min-width: 400px;

      @media screen and (max-width: 399px) {
        width: 85%;
        min-width: 85%;
      }

    }

    .wildcard:hover {
      background-color: #f3f3f3;
      cursor: pointer;
    }

    .wildcard-body {
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, .125);
      border-top: none;
      overflow-wrap: break-word;
    }
  }
}

/* ==========================================================================
Lock icon
========================================================================== */

.public-icon {
  position: absolute;
  display: flex;
  margin-top: 3.75rem;
  margin-left: 21.7rem;
}

.lock-icon {
  position: absolute;
  bottom: 12px;
  right: 12px;
  color: orange;
}

.lock-icon:hover > .tooltiptext {
  visibility: visible;
}

.tooltiptext {
  color: black;
  visibility: hidden;
  border: 1px solid gray;
  border-radius: 5px;
  position: absolute;
  background-color: white;
  width: 100px;
  z-index: 1;
  top: 2rem;
  right: -4rem;
}
