.loader {
    z-index: 1000;
    width: 200px;
    height: 185px;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.loader .loader-bg {
    width: 50%;
    height: 50%;
    background: url("../../../assets/images/loader_bg.png") no-repeat;
    background-size: cover;
    opacity: 0.8;
    animation: mirror 1.5s linear infinite;
}

@keyframes mirror {
    from {transform:rotateY(0deg);}
    to {transform:rotateY(360deg);}
}