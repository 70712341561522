@media screen and (min-width: 1200px){
    .redirect-container {
        width: 100%;
        margin-top: -30px;
    }

    .redirect-button {
        width: 100%;
        text-align: center;
        margin: 50px auto;
    }
}

@media screen and (min-width: 800px) and (max-width: 1199px){
    .redirect-container {
        width: 100%;
        margin-top: -30px;
    }

    .redirect-button {
        width: 50%;
        text-align: center;
        margin: 50px auto;
    }
}

@media screen and (min-width: 500px) and (max-width: 799px){
    .redirect-container {
        width: 100%;
        margin-top: -30px;
    }

    .redirect-button {
        width: 100%;
        text-align: center;
        margin: 50px auto;
    }
}

@media screen and (max-width: 499px){
    .redirect-container {
        width: 100%;
        margin-top: -30px;
    }

    .redirect-button {
        width: 100%;
        text-align: center;
        margin: 50px auto;
    }
}
