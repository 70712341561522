@media screen and (max-width: 399px) {
    .premium-type-wrapper {
        margin: 1rem auto;
    }

    .premium-type-header {
        font-size: 1.4rem;
        font-weight: bold;
        color: #fefefe;
    }

    .premium-type-container {
        width: 100%;
        margin: 1rem auto;
    }

    .ticket-item-icon {
        width: 80% !important;
        font-size: 20px;
        text-align: center;
        margin: 0 auto;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 50px;
        padding: 2rem 1rem;
        cursor: pointer;
    }

    .ticket-item-details {
        font-size: 1.4rem;
    }

    .details-label {
        width: 100%;
    }

    .details-value {
        width: 100%;
    }

    .ticket-item-minimal {
        width: 100%;
        line-height: 2rem;
        font-size: 1.1rem;
    }

    .ticket-item-icon-minimal {
        width: 80% !important;
        margin: 2rem auto;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 20px;
        margin-right: 2rem;
    }

    .ticket-item-details-minimal {
        width: 100% !important;
    }

    .premium-type-table {
        width: 100%;
    }
}

@media screen and (min-width: 400px) and (max-width: 699px) {
    .premium-type-wrapper {
        margin: 1rem auto;
    }

    .premium-type-header {
        font-size: 1.8rem;
        font-weight: bold;
        color: #fefefe;
        line-height: 2.2rem;
    }

    .premium-type-container {
        width: 98%;
        margin: 1rem auto;
    }

    .ticket-item-icon {
        width: 70% !important;
        font-size: 20px;
        text-align: center;
        margin: 0 auto;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 50px;
        padding: 2rem 1rem;
        cursor: pointer;
    }

    .ticket-item-details {
        font-size: 1.4rem;
    }

    .details-label {
        width: 100%;

    }

    .details-value {
        width: 100%;
    }

    .ticket-item-minimal {
        width: 100%;
        line-height: 2.4rem;
        font-size: 1.4rem;
    }

    .ticket-item-icon-minimal {
        width: 60% !important;
        margin: 2rem auto;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 20px;
    }

    .ticket-item-details-minimal {
        width: 100% !important;
    }
    .premium-type-table {
        width: 100%;
    }
}

@media screen and (min-width: 700px) and (max-width: 999px) {
    .premium-type-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .premium-type-header {
        font-size: 1.8rem;
        font-weight: bold;
        color: #fefefe;
    }

    .premium-type-container {
        width: 100%;
        margin: 2rem auto;
    }

    .ticket-item-icon {
        width: 30% !important;
        font-size: 18px;
        text-align: center;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 50px;
        padding: 2rem 1rem;
        cursor: pointer;
    }

    .ticket-item-details {
        font-size: 1.4rem;
        padding: 2rem 0;
    }

    .details-label {
        width: 50%;
        padding-left: 1.5rem !important;
    }

    .details-value {
        width: 100%;
    }

    .ticket-item-minimal {
        width: 100%;
        display: flex;
        line-height: 40px;
        font-size: 1.2rem;
    }

    .ticket-item-icon-minimal {
        width: 20% !important;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 20px;
        margin-right: 2rem;
    }

    .ticket-item-details-minimal {
        width: 30% !important;
    }
    .premium-type-table {
        width: 100%;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
    .premium-type-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .premium-type-header {
        font-size: 1.8rem;
        font-weight: bold;
        color: #fefefe;
    }

    .premium-type-container {
        width: 100%;
        margin: 2rem auto;
    }

    .ticket-item-icon {
        width: 30% !important;
        font-size: 18px;
        text-align: center;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 50px;
        padding: 2rem 1rem;
        cursor: pointer;
    }

    .ticket-item-details {
        font-size: 1.4rem;
        padding: 2rem 0;
    }

    .details-label {
        width: 50%;
        padding-left: 1.5rem !important;
    }

    .details-value {
        width: 100%;
    }

    .ticket-item-minimal {
        width: 100%;
        display: flex;
        line-height: 80px;
        font-size: 1.1rem;
    }

    .ticket-item-icon-minimal {
        width: 10% !important;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 20px;
        margin-right: 2rem;
    }

    .ticket-item-details-minimal {
        width: 30% !important;
    }
    .premium-type-table {
        width: 100%;
    }
}

@media screen and (min-width: 1200px) {
    .premium-type-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .premium-type-header {
        font-size: 1.8rem;
        font-weight: bold;
        color: #fefefe;
    }

    .premium-type-container {
        width: 80%;
        margin: 2rem auto;
    }

    .ticket-item-icon {
        width: 20% !important;
        font-size: 20px;
        text-align: center;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 50px;
        padding: 2rem 1rem;
        cursor: pointer;
    }

    .ticket-item-details {
        font-size: 1.4rem;
        padding: 2rem .5rem;
    }

    .details-label {
        width: 25%;
        padding-left: 1.5rem !important;
    }

    .details-value {
        width: 100%;
    }

    .ticket-item-minimal {
        width: 100%;
        display: flex;
        line-height: 80px;
        font-size: 1.2rem;
    }

    .ticket-item-icon-minimal {
        width: 8% !important;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 20px;
        margin-right: 2rem;
    }

    .ticket-item-details-minimal {
        width: 25% !important;
    }
    .premium-type-table {
        width: 60%;
        margin: auto;
    }
}

.ticket-item-icon:hover {
    background-color: rgba(0,0,0,0.05);
}

.button-link:hover {
    text-decoration: none;
}

.shadow-box {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    background-color: #00b4eb;
    padding: 1rem .5rem;
}