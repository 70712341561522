.avatar-category {
    width: 100%;
    margin: 1rem 0;

    @media screen and (max-width: 499px) {
        width: 85%;
        margin-left: 7.5%;
        margin-right: auto;
    }

    .avatar-name {
        width: 90%;
        margin-top: 1.5em;
        margin-bottom: 0.5em;

        font-family: MikadoBlack, sans-serif;
        font-size: 2em;
        font-weight: 400;
    }

    .avatar-item-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .avatar-item {
            text-align: center;
            display: block;
            margin: 10px 10px;
            border: solid 4px rgba(236, 131, 32, 0.6);
            background-color: rgba(236, 131, 32, 0.3);
            border-radius: 200px;
            cursor: pointer;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            width: 200px;

            @media screen and (max-width: 499px) {
                width: 40%;
            }

            &.selected {
                border: solid 4px rgba(98, 164, 82, 0.8);
                background-color: rgba(98,164,82,0.6);
                border-radius: 200px;
            }

            &:hover {
                background-color: rgba(236,131,32,1);
            }

            .avatar-image {
                width: 100%;
            }
        }
    }
}

