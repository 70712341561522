.tiger {
    z-index: 1000;
    width: 200px;
    height: 185px;
    margin: 5% auto 0;
    background: url("../../../assets/images/loader_bg.png") no-repeat;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tiger-text {
    z-index: 1000;
    margin: 2% auto;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
}