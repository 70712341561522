.profile-preferences-container {
    .profile-preferences-search-query-input {
        border: 0;
        background-color: rgba(100, 100, 100, 0.043);
        font-size: 18px;
    }

    .search-link {
        margin: 0.5em 0 0 1.6em;
        text-align: left;
    }

    .preferences-items-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;

        .preferences-item {
            display: flex;
            flex-direction: column;
            margin: 1rem .8rem;
            cursor: pointer;
            align-items: center;
            width: 180px;

            img {
                height: 150px;
                padding: .25rem;
                border-radius: 300px;

                &.selected-preferences {
                    border: solid .5rem rgba(98, 164, 82, 0.8);
                }
            }

            .preferences-text-container {
                font-weight: normal;
                color: #676767;
                text-align: center;
                font-size: 1.4em;
                margin-top: .5rem;

                .preferences-name {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;

                    svg {
                        vertical-align: text-top;
                    }
                }
            }
        }
    }
}

.preferences-item img {
    height: 150px;
}

.preferences-no-content {
    width: 98%;
    margin: 0 auto;
    text-align: center;
}

.preferences-no-content img {
    height: 150px;
}

.preferences-amount {
    float: right;
    color: #1bafb4;
    font-family: MikadoBlack, sans-serif;
    max-width: 50%;
    text-align: right;
}


.preferences-toggle-button {
    float: right;
    margin-top: .5rem;
}
