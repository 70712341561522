.wildcard-cover-container {
  position: relative;
  width: 100%;

  @media screen and (max-width: 399px) {
    width: 85%;
    margin: 0 auto;
  }

  @media screen and (min-width: 400px) and (max-width: 699px) {
    width: 85%;
    margin: 0 auto;
  }

  @media screen and (min-width: 700px) and (max-width: 799px) {
    width: 60%;
    margin: 0 auto;
  }

  .cover {
    border: 1px solid gray;
    border-radius: 1rem;

    img {
      aspect-ratio : 1 / 1;
      border-radius: 1rem;
    }
  }

  .lock {
    position: absolute;
    bottom: 1rem;
    right: 5%;
  }

  .badge-container {
    position: absolute;
    top: -1px;
    left: 5%;
  }

  .badge-icon {
    position: absolute;
    margin: 0.4rem 0 0 0.4rem;
    width: 83%;
    z-index: 99;
  }

  .button-container {
    position: absolute;
    top: 1rem;
    right: 5%;
    display: flex;
    justify-content: flex-end;
  }
}
