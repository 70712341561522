@import '~bootstrap/scss/bootstrap';

body {
  font-family: Mikado, sans-serif !important;
  color: #333;
}

/* ==========================================================================
Fonts
========================================================================== */
@font-face {
  font-family: Mikado;
  src: url("./assets/fonts/mikadoregular-webfont.woff2") format("woff2"), url("./assets/fonts/mikadoregular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: Mikado;
  src: url("./assets/fonts/mikadomedium-webfont.woff2") format("woff2"), url("./assets/fonts/mikadomedium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: Mikado;
  src: url("./assets/fonts/mikadobold-webfont.woff2") format("woff2"), url("./assets/fonts/mikadobold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: 'MikadoBlack';
  src: local('MikadoBlack'), url("./assets/fonts/MikadoBlack.otf") format("truetype");
  font-weight: 700;
  font-style: normal
}

/*
PDF
 */

.pdf-text {
  font-family: Mikado, sans-serif;
}

/* ==========================================================================
Content boxes
========================================================================== */

.content-box {
  width: 90%;
  margin: 1.5rem 5%;
  display: inline-block;
}

.content-header {
  display: inline-block;
  width: 100%;
  padding: 1rem 0;
  margin-top: 0.5em;
  margin-bottom: calc(1.6vw + 30px);
  font-size: calc(3.0vw + 16px);
  line-height: 2rem;

  font-family: MikadoBlack, sans-serif;
  color: rgb(100,100,100)
}

.content-details-header {
  display: inline-block;
  width: 100%;

  font-family: Mikado, sans-serif;
  color: #fefefe;
  border-radius: 40px;
  font-weight: bold;
  box-shadow: 0 0 0.3125rem rgba(0,0,0,0.5);

  a {
    color: #fefefe;

    &:hover {
      color: #fefefe;
    }
  }
}

@media screen and (min-width: 1200px) {

  .content-details-header {
    margin: 1rem 0;
    padding: 0 1rem;
    font-size: 1.6rem;
  }
  .content-details-header a{
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 800px) and (max-width: 1199px) {

  .content-details-header {
    margin: 1rem 0;
    padding: 0 1rem;
    font-size: 1.6rem;
  }
  .content-details-header a{
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 799px) {

  .content-details-header {
    margin: 1rem 0;
    padding: 0 1rem;
    font-size: 1.6rem;
  }
  .content-details-header a{
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 499px) {

  .content-details-header {
    margin: 1rem 0;
    padding: 0 1rem;
    font-size: 1.3rem;
  }
  .content-details-header a{
    font-size: 2.0rem;
  }
}

@media screen and (max-width: 399px) {

  .content-details-header {
    margin: 1rem 0;
    padding: 0 1rem;
    font-size: 1.0rem;
  }
  .content-details-header a{
    font-size: 1.5rem;
  }
}

.back-button a:hover {
  text-decoration: none;
}

/* ==========================================================================
Helpers
========================================================================== */

.select-none {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.img-cover {
  width: 90%;
  height: 100%;
  object-fit: cover;
}

.rounded-card {
  border-radius: 15px !important;
  margin: 1rem auto;
}

.round {
  width: 40px;
  height: 40px;
}

.rounded-card img {
  border-radius: 15px;
}

.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}

button svg, a svg {
  vertical-align: sub;
}

@media screen and (max-width: 399px) {
  .icon {
    font-size: 20px;
  }
  .content-box {
    width: 98%;
    margin: 1.5rem auto;
  }
}

@media screen and (min-width: 400px) and (max-width: 699px) {
  .icon {
    font-size: 20px;
  }
  .content-box {
    width: 98%;
    margin: 1.5rem auto;
    display: inline-block;
  }
}

@media screen and (min-width: 700px) and (max-width: 999px) {
  .icon {
    font-size: 20px;
  }
  .content-box {
    width: 90%;
    margin: 1.5rem 5%;
    display: inline-block;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .icon {
    font-size: 20px;
  }

  .content-box {
    width: 90%;
    margin: 1.5rem 5%;
    display: inline-block;
  }
}

@media screen and (min-width: 1200px) {
  .icon {
    font-size: 30px;
  }
  .content-box {
    width: 90%;
    margin: 1.5rem 5%;
    display: inline-block;
  }
}

.clickable {
  cursor: pointer;
}

/* ==========================================================================
  Watchlist
========================================================================== */
.watchlist-container {
  .watchlist-item {
    color: #676767;
    flex-direction: row;
    flex-wrap: wrap;

    @media screen and (max-width: 699px) {
      flex-direction: column;
      align-items: center;
    }

    .watchlist-item-cover-container {
      width: calc(200px + 2.5rem);
      max-width: calc(200px + 2.5rem);
      max-height: calc(200px + 2.5rem);
      padding: 1.25rem;

      @media screen and (max-width: 699px) {
        width: 85%;
        padding: 0;
        margin-top: 1.25rem;
      }
    }

    .watchlist-item-title {
      .product-title-product-title {
        font-size: 1.3rem;
      }

      .product-title-series-title {
        font-weight: normal;
        font-size: 1rem;
      }

      a {
        color: #676767;

        &:hover {
          color: #676767;
        }
      }

    }

    .card-text {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }

    .delete-watchlist-item-button {
      margin-right: 1.25rem;
      margin-bottom: 1.25rem;
    }
  }
}
/* ==========================================================================
  Last used items
========================================================================== */
.last-used-container {
  .last-used-item {
    color: #676767;
    flex-direction: row;
    flex-wrap: wrap;

    @media screen and (max-width: 699px) {
      flex-direction: column;
      align-items: center;
    }

    .last-used-item-cover-container {
      width: calc(200px + 2.5rem);
      max-width: calc(200px + 2.5rem);
      max-height: calc(200px + 2.5rem);
      padding: 1.25rem;

      @media screen and (max-width: 699px) {
        width: 85%;
        padding: 0;
        margin-top: 1.25rem;
      }
    }

    .last-used-item-title {
      .product-title-product-title {
        font-size: 1.3rem;
      }

      .product-title-series-title {
        font-weight: normal;
        font-size: 1rem;
      }

      a {
        color: #676767;

        &:hover {
          color: #676767;
        }
      }

    }

    .card-text {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }

    .delete-last-used-item-button {
      margin-right: 1.25rem;
      margin-bottom: 1.25rem;
    }
  }
}