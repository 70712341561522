/*! Theme Name:TigerMedia Theme URI:http://tiger.media Author:Diverently GmbH Author URI:https://www.diverently.com Description:Dieses Theme wurde von der Diverently GmbH entwickelt. Version:1.0.0 License:GNU General Public License v2 or later License URI:http://www.gnu.org/licenses/gpl-2.0.html Text Domain:tm */
.formInput,body
{ font-family:Mikado,sans-serif; }

.slick-list,.slick-slider,.slick-track
{ display:block;
  position:relative; }

.slick-loading .slick-slide,.slick-loading .slick-track
{ visibility:hidden; }

.slick-slider
{ -webkit-box-sizing:border-box;
  box-sizing:border-box;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  -ms-touch-action:pan-y;
  touch-action:pan-y;
  -webkit-tap-highlight-color:transparent; }

.slick-list
{ overflow:hidden;
  margin:0;
  padding:0; }

.slick-list:focus
{ outline:0; }

.slick-list.dragging
{ cursor:pointer;
  cursor:hand; }

.slick-slider .slick-list,.slick-slider .slick-track
{ -webkit-transform:translateZ(0);
  transform:translateZ(0); }

.slick-track
{ left:0;
  top:0;
  margin-left:auto;
  margin-right:auto; }

body,h1,h2,h3,h4,h5,h6,html,p
{ margin:0; }

.slick-track:after,.slick-track:before
{ content:"";
  display:table; }

.slick-track:after
{ clear:both; }

.slick-slide
{ float:left;
  height:100%;
  min-height:1px;
  display:none; }

[dir=rtl] .slick-slide
{ float:right; }

.slick-slide img
{ display:block; }

.slick-slide.slick-loading img
{ display:none; }

.slick-slide.dragging img
{ pointer-events:none; }

.slick-initialized .slick-slide
{ display:block; }

.slick-vertical .slick-slide
{ display:block;
  height:auto;
  border:1px solid transparent; }

.slick-arrow.slick-hidden
{ display:none; }

@font-face
{ src:url("./../../../assets/fonts/mikadoregular-webfont.woff2") format("woff2"),url("./../../../assets/fonts/mikadoregular-webfont.woff") format("woff");
  font-family:Mikado;
  font-weight:400;
  font-style:normal; }

@font-face
{ src:url("./../../../assets/fonts/mikadomedium-webfont.woff2") format("woff2"),url("./../../../assets/fonts/mikadomedium-webfont.woff") format("woff");
  font-family:Mikado;
  font-weight:500;
  font-style:normal; }

@font-face
{ src:url("./../../../assets/fonts/mikadobold-webfont.woff2") format("woff2"),url("./../../../assets/fonts/mikadobold-webfont.woff") format("woff");
  font-family:Mikado;
  font-weight:700;
  font-style:normal; }

*,:after,:before
{ -webkit-box-sizing:border-box;
  box-sizing:border-box; }

html
{ min-height:100%;
  -ms-text-size-adjust:100%;
  -webkit-text-size-adjust:100%; }

body
{ background:#fff; }

img
{ max-width:100%;
  height:auto; }

.siteMain
{ position:relative;
  z-index:2; }

p+p
{ margin-top:.5em; }

button,input
{ font-family:inherit; }

/* Korrektur NL-Formular mobil */
body.newsletter .formGroup {
	padding-bottom: 10px;
}
.form .formGroup input#email {
	background-color: #eee;
}
.form .formGroup .formInput {
	padding: 8px 22px 9px;
}
body.newsletter .formSubmit {
	display: block;
	width: 100%;
}
.pageContent { 
	padding:60px 20px 80px;
	max-width:1440px;
	margin:0 auto; 
}
.formLabel {
	display: block;
	padding-bottom: 10px;
	text-align: center;
}
body.newsletter .form {
	max-width: 350px;
	margin: 0 auto;
	padding: 60px 0 40px;
}
body.newsletter .formInput {
	border: 1px solid #dedede;
	width: 100%;
	text-align: left;
}
/* Korrektur Ende */

.siteNavigation-wrapper
{ position:fixed;
  z-index:6;
  top:0;
  right:0;
  left:0; }

.siteNavigation
{ position:relative;
  background-color:#fff;
  color:#404040;
  font-size:16px; }

.siteNavigation a
{ text-decoration:none;
  color:inherit; }

.siteNavigation-container
{ position:relative;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:end;
  -ms-flex-pack:end;
  justify-content:flex-end;
  white-space:nowrap;
  min-height:44px;
  margin:0 auto; }

.landingPremium-link:before,.landingSubnavigation-link:before,.siteLink:after
{ margin-bottom:-1px;
  overflow:hidden;
  visibility:hidden; }

.siteNavigation-container:before
{ content:"";
  display:block;
  width:140px; }

@media (max-width:849px)
{ .siteNavigation-container
{ -webkit-box-orient:horizontal;
  -webkit-box-direction:reverse;
  -ms-flex-direction:row-reverse;
  flex-direction:row-reverse; }

.siteNavigation-container:before
{ display:none; }

.siteNavigation-container:after
{ content:"";
  display:block;
  width:127px; }
}

.siteNavigation-brand
{ position:absolute;
  left:9px; }

.siteNavigation-brand { 
	top:calc(-.54545455vw + 13.64px); 
}

@media (min-width:850px)
{ .siteNavigation-brand
{ top:9px; }

}

.siteNavigation-brand svg
{ display:block;
  width:90px;
  height:18px; }

@media (min-width:300px)
{ .siteNavigation-brand svg
{ width:calc(6.52173913vw + 70.43px);
  height:calc(1.30434783vw + 14.09px); }

}

@media (min-width:760px)
{ .siteNavigation-brand svg
{ width:120px;
  height:24px; }

}

.siteheader-subnavigation
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex; }

@media (max-width:370px)
{ .siteheader-subnavigation
{ display:none!important; }

}

.landingPremium-link,.landingSubnavigation-link
{ position:relative;
  display:none;
  padding:3px 9px;
  text-align:center; }

.landingPremium-link:before,.landingSubnavigation-link:before
{ display:block;
  content:attr(title);
  font-weight:700;
  height:1px;
  color:transparent; }

.landingPremium-link:after,.landingSubnavigation-link:after
{ position:absolute;
  bottom:0;
  right:9px;
  left:9px;
  display:none;
  height:3px;
  background-color:#e50045;
  content:""; }

.landingSubnavigation-link.is-active .landingPremium-link.is-active
{ display:block; }

a.siteNavigation-overlayToggle
{ position:relative;
  top:5px;
  padding:3px 9px 3px 20px; }

a.siteNavigation-overlayToggle .siteNavigation-overlayToggleIcon
{ position:absolute;
  top:3px;
  left:0;
  display:block; }

a.siteNavigation-overlayToggle .siteNavigation-overlayToggleIcon .toggleIcon-active
{ display:none; }

.overlay-is-visible a.siteNavigation-overlayToggle .siteNavigation-overlayToggleIcon .toggleIcon-active
{ display:block; }

.overlay-is-visible a.siteNavigation-overlayToggle .siteNavigation-overlayToggleIcon .toggleIcon-default
{ display:none; }

a.siteNavigation-overlayToggle .siteNavigation-overlayToggleText
{ position:relative;
  display:block;
  text-align:center; }

a.siteNavigation-overlayToggle .siteNavigation-overlayToggleText:after
{ display:block;
  content:attr(title);
  font-weight:700;
  height:1px;
  color:transparent;
  overflow:hidden;
  visibility:hidden;
  margin-bottom:-1px; }

a.siteNavigation-overlayToggle:hover .siteNavigation-overlayToggleText
{ margin-top:-1px;
  font-weight:600;
  color:#e50045; }

.overlay-is-visible a.siteNavigation-overlayToggle .siteNavigation-overlayToggleText
{ font-weight:500; }

.overlay-is-visible a.siteNavigation-overlayToggle:hover .siteNavigation-overlayToggleText
{ margin-top:0;
  font-weight:500;
  color:inherit; }

.siteNavigation-siteLinks
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-preferred-size:100%;
  flex-basis:100%;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center; }

.siteLink
{ display:block;
  padding:3px 9px;
  font-size:19px;
  text-align:center; }

.siteLink:after
{ display:block;
  content:attr(title);
  font-weight:700;
  height:1px;
  color:transparent; }

.siteLink.is-current,.siteLink:focus,.siteLink:hover
{ font-weight:600;
  color:#e50045; }

.siteNavigation-siteLinks .siteLink
{ display:none; }

@media (min-width:850px)
{ .siteNavigation-siteLinks
{ -ms-flex-preferred-size:auto;
  flex-basis:auto;
  position:relative;
  top:5px; }

.siteLink
{ font-size:16px; }

.siteNavigation-siteLinks .siteLink
{ display:block; }

}

.siteLink--service
{ font-weight:400;
  color:#e50045; }

.siteLink--service:focus,.siteLink--service:hover
{ margin-top:0;
  font-weight:400; }

.product .siteheader-subnavigation
{ display:none; }

.product .siteheader-subnavigation.clone
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  position:fixed;
  visibility:hidden;
  opacity:0;
  pointer-events:none;
  z-index:-100;
  bottom:-100px; }

.product .siteheader-subnavigation.clone .landingPremium-link,.product .siteheader-subnavigation.clone .landingSubnavigation-link
{ display:block!important; }

.product.scrolled .siteNavigation-container
{ -webkit-box-orient:horizontal;
  -webkit-box-direction:normal;
  -ms-flex-direction:row;
  flex-direction:row;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between; }

.product.scrolled .siteNavigation-container:before
{ content:"";
  display:block;
  width:127px; }

.product.scrolled .siteNavigation-container:after
{ display:none; }

.product.scrolled .siteheader-subnavigation
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex; }

.product.scrolled .siteNavigation-siteLinks,.product.scrolled.overlay-is-visible .siteNavigation-container:before,.product.scrolled.overlay-is-visible .siteheader-subnavigation
{ display:none; }

.product.scrolled.overlay-is-visible .siteNavigation-container
{ -webkit-box-pack:end;
  -ms-flex-pack:end;
  justify-content:flex-end; }

.product.scrolled.overlay-is-visible .siteNavigation-siteLinks
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex; }

.overlay-is-visible .siteNavigation-container
{ -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-box-orient:horizontal;
  -webkit-box-direction:normal;
  -ms-flex-direction:row;
  flex-direction:row; }

.siteOverlay-container,.siteOverlay-footer,.siteOverlay-product
{ -webkit-box-orient:vertical;
  -webkit-box-direction:normal; }

.overlay-is-visible .siteNavigation-siteLinks
{ padding-top:30px;
  padding-bottom:10px; }

.overlay-is-visible .siteLink
{ display:block; }

.overlay-is-visible .siteLink--service
{ -webkit-box-ordinal-group:0;
  -ms-flex-order:-1;
  order:-1;
  -ms-flex-preferred-size:100%;
  flex-basis:100%;
  text-align:center; }

@media (min-width:850px)
{ .overlay-is-visible .siteNavigation-siteLinks
{ padding-top:0;
  padding-bottom:0; }

.overlay-is-visible .siteLink--service
{ -webkit-box-ordinal-group:1;
  -ms-flex-order:0;
  order:0;
  -ms-flex-preferred-size:auto;
  flex-basis:auto; }

}

body.overlay-is-visible
{ overflow:hidden; }

.siteOverlay-wrapper
{ display:none;
  position:fixed;
  z-index:5;
  top:0;
  right:0;
  bottom:0;
  left:0;
  overflow-y:scroll;
  background-color:#fff; }

.hero,.hero-background
{ z-index:1; }

.overlay-is-visible .siteOverlay-wrapper
{ display:block; }

.siteOverlay
{ background-color:#fff;
  background-color:hsla(0,0%,100%,.99);
  font-size:19px; }

.siteOverlay-container
{ padding-top:120px;
  padding-bottom:40px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-flow:column;
  flex-flow:column;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between;
  min-height:100vh;
  height:100px; }

@media (min-width:260px)
{ .siteOverlay-container
{ padding-top:120px; }

}

@media (min-width:849px)
{ .siteOverlay-container
{ padding-top:calc(-1700vw + 14553px); }

}

@media (min-width:850px)
{ .siteOverlay-container
{ padding-top:103px; }

}

@media (min-width:300px)
{ .siteOverlay-container
{ padding-bottom:calc(2.17391304vw + 33.48px); }

}

@media (min-width:760px)
{ .siteOverlay-container
{ padding-bottom:50px; }

}

.siteOverlay-products
{ padding:20px;
  overflow-y:scroll; }

@media (min-width:700px)
{ .siteOverlay-products
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-box-pack:start;
  -ms-flex-pack:start;
  justify-content:flex-start; }

}

.siteOverlay-product
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-flow:column;
  flex-flow:column;
  position:relative;
  padding:0 20px;
  border:5px solid #fff;
  background-color:#f8f8f8;
  -webkit-transition:background-color .1s;
  transition:background-color .1s;
  text-align:center; }

.siteOverlay-product:hover
{ background-color:hsla(0,0%,97%,0); }

@media (min-width:700px)
{ .siteOverlay-product
{ -webkit-box-flex:0;
  -ms-flex-positive:0;
  flex-grow:0;
  -ms-flex-negative:0;
  flex-shrink:0;
  -ms-flex-preferred-size:auto;
  flex-basis:auto;
  width:50%;
  padding:20px;
  border-width:10px; }

}

@media (min-width:1000px)
{ .siteOverlay-product
{ width:33.333%; }

}

@media (min-width:1300px)
{ .siteOverlay-product
{ width:25%; }

}

.overlayProduct-logo
{ margin:20px auto; }

.overlayProduct-picture
{ display:none; }

@media (min-width:700px)
{ .overlayProduct-logo
{ margin:0 0 20px; }

.overlayProduct-picture
{ display:block; }

.siteOverlay-product.superbuch .overlayProduct-logo
{ position:absolute;
  top:20px;
  left:20px; }

}

.siteOverlay-product.superbuch
{ padding-right:0;
  padding-bottom:0; }

.siteOverlay-product.superbuch .overlayProduct-logo
{ margin-top:5px;
  margin-bottom:5px; }

.siteOverlay-product.superbuch .overlayProduct-picture img
{ display:block;
  float:right;
  margin-top:10px; }

.siteOverlay-product.comingsoon:before,.siteOverlay-product.ohrenauf:before
{ position:absolute;
  right:-14px;
  top:50%;
  -webkit-transform:translateY(-50%);
  transform:translateY(-50%);
  display:block;
  background-position:50%;
  background-repeat:no-repeat;
  background-size:contain;
  content:""; }

.hero,.hero-background,.hero-container,.hero-content
{ position:relative; }

.hero--homepage .hero-background img,.hero--page .hero-background img
{ -webkit-transform:translate(-50%,-50%);
  -o-object-fit:cover;
  min-width:100%; }

@media (min-width:300px)
{ .siteOverlay-product.comingsoon:before,.siteOverlay-product.ohrenauf:before
{ right:calc(3.5vw - 24.5px); }

}

@media (min-width:500px)
{ .siteOverlay-product.comingsoon:before,.siteOverlay-product.ohrenauf:before
{ right:-7px; }

}

.siteOverlay-product.ohrenauf:before
{ width:70px;
  height:47px; }

@media (min-width:700px)
{ .siteOverlay-product.comingsoon:before,.siteOverlay-product.ohrenauf:before
{ right:auto;
  left:50%; }

.siteOverlay-product.ohrenauf:before
{ width:94px;
  height:64px;
  margin-left:-150px; }

}

.siteOverlay-product.comingsoon:before
{ width:82px;
  height:54px;
  background-image:url(./../../../assets/images/Overlay-ComingSoon.png); }

@media (min-width:700px)
{ .siteOverlay-product.comingsoon:before
{ width:151px;
  height:99px;
  margin-left:-75px; }

}

.siteOverlay-footer
{ display:none;
  -ms-flex-flow:column;
  flex-flow:column;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center; }

.siteOverlay-footer a
{ color:inherit;
  font-weight:500; }

@media (min-width:900px)
{ .siteOverlay-footer
{ -webkit-box-orient:horizontal;
  -webkit-box-direction:normal;
  -ms-flex-flow:row;
  flex-flow:row;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between;
  padding:30px 80px 0; }

}

.hero--product,.hero-home-slider-item-container,.newsletterForm
{ -webkit-box-orient:vertical;
  -webkit-box-direction:normal; }

.siteOverlay-siteLinks
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  max-width:250px; }

@media (min-width:900px)
{ .siteOverlay-siteLinks
{ -webkit-box-ordinal-group:0;
  -ms-flex-order:-1;
  order:-1;
  max-width:none; }

.siteOverlay-siteLinks a
{ font-weight:400; }

}

.siteOverlay-siteLink
{ display:block;
  padding:4px 10px;
  text-decoration:none;
  text-align:center; }

.siteOverlay-siteLink:after
{ display:block;
  content:attr(title);
  font-weight:700;
  height:1px;
  color:transparent;
  overflow:hidden;
  visibility:hidden;
  margin-bottom:-1px; }

.siteOverlay-siteLink.is-current,.siteOverlay-siteLink:focus,.siteOverlay-siteLink:hover
{ margin-top:-1px;
  font-weight:600;
  color:#e50045; }

.hero
{ padding-top:44px;
  overflow:hidden;
  background-color:#ca1524;
  color:#fff; }

.hero-container
{ padding-right:20px;
  padding-left:20px;
  max-width:1440px;
  margin:0 auto; }

@media (min-width:300px)
{ .hero-container
{ padding-right:calc(6.52173913vw + .43px);
  padding-left:calc(6.52173913vw + .43px); }

}

@media (min-width:760px)
{ .hero-container
{ padding-right:calc(6.25vw + 2.5px);
  padding-left:calc(6.25vw + 2.5px); }

}

@media (min-width:1400px)
{ .hero-container
{ padding-right:90px;
  padding-left:90px; }

}

.hero-content
{ z-index:2; }

.hero-scrollPrompt
{ position:relative;
  z-index:3;
  width:100%;
  text-align:center; }

@supports ((-webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)) or (clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)))
{ .hero
{ padding-bottom:7vw;
  -webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%);
  clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%); }
}

@media (min-width:1440px)
{ .hero
{ padding-bottom:100px;
  -webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 100px),0 100%);
  clip-path:polygon(0 0,100% 0,100% calc(100% - 100px),0 100%); }

}

.hero--homepage
{ min-height:96vh;
  background-color:#fff;
  background-image:url(./../../../assets/images/BackgroundPattern-Color@2x.png);
  background-size:1441px 810px;
  background-position:bottom; }

.hero--homepage .hero-background img
{ position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:auto;
  height:auto;
  min-height:100%;
  object-fit:cover; }

.hero--homepage .hero-content
{ padding-top:8vh; }

.hero--homepage .hero-subtitle,.hero--homepage .hero-title
{ text-align:center; }

.hero--homepage .hero-title
{ color:#00b6ed;
  font-size:40px;
  line-height:1.1; }

@media (min-width:300px)
{ .hero--homepage .hero-title
{ font-size:calc(3.04347826vw + 30.87px); }

}

@media (min-width:760px)
{ .hero--homepage .hero-title
{ font-size:calc(2.27272727vw + 36.73px); }

}

@media (min-width:1200px)
{ .hero--homepage .hero-title
{ font-size:64px; }

}

.hero--homepage .hero-subtitle
{ margin-top:.3em;
  font-size:16px;
  font-weight:500;
  line-height:1.3;
  color:#404040; }

@media (min-width:300px)
{ .hero--homepage .hero-subtitle
{ font-size:calc(3.04347826vw + 6.87px); }

}

@media (min-width:760px)
{ .hero--homepage .hero-subtitle
{ font-size:30px; }

}

.hero-home-slider-item
{ outline:0; }

.hero-home-slider-item-container
{ min-height:calc(96vh - 100px);
  padding:60px 0 0;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-direction:column;
  flex-direction:column;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  text-align:center; }

@media (max-width:700px)
{ .hero-home-slider-item-container
{ padding:20px 0 0; }

}

@media (min-width:700px)
{ .hero-home-slider-item-container
{ min-height:calc(96vh - 200px); }

}

.hero-home-slider-logo
{ margin-bottom:20px;
  max-width:55vw; }

.hero-home-slider-item-text
{ margin-bottom:20px;
  max-width:80vw;
  font-size:28px;
  font-weight:600;
  color:#404040; }

@media (min-width:760px)
{ .hero-home-slider-item-text
{ font-size:50px; }

}

.hero-home-slider-item-text.red
{ color:#e50045; }

.hero-home-slider-item-text.yellow
{ color:#f6a809; }

.hero-home-slider-item-text.green
{ color:#0a917c; }

.hero-home-slider-item-product
{ margin-top:20px;
  width:100%;
  max-width:90vw;
  min-height:400px;
  -webkit-box-flex:1;
  -ms-flex:1 1 auto;
  flex:1 1 auto;
  background-size:contain;
  background-repeat:no-repeat;
  background-position:top; }

.hero-home-slider-item-product img
{ width:auto;
  max-width:100%;
  height:auto;
  max-height:100%; }

@media (min-width:700px)
{ .hero-home-slider-item-product
{ margin-top:40px; }

}

@media (min-width:1040px)
{ .hero-home-slider-item-product
{ max-width:940px; }

}

.hero-home-slider-item-product.full-width
{ max-width:none;
  background-size:auto 100%; }

.slick-dots
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  padding:0;
  list-style:none; }

.hero--landing .hero-content,.hero--page .hero-content
{ padding-top:10vh;
  padding-bottom:6vh; }

.slick-dots li
{ margin:0 7px; }

.slick-dots button
{ font-size:0;
  background-color:#e50045;
  width:16px;
  height:16px;
  outline:0;
  border:0;
  border-radius:20px;
  color:transparent;
  cursor:pointer; }

.slick-dots .slick-active button
{ background-color:#d8d8d8; }

.hero-home-slider .slick-arrow
{ width:48px;
  height:48px;
  border-color:transparent;
  background:0 0;
  z-index:1;
  outline:0;
  position:absolute;
  top:50%;
  text-indent:-999px;
  cursor:pointer;
  margin-top:-31px; }

.hero-home-slider .slick-next,.hero-home-slider .slick-prev
{ border-bottom:6px solid #e50045;
  border-left:6px solid #e50045; }

.hero-home-slider .slick-next
{ transform:rotate(-135deg)!important;
  -webkit-transform:rotate(-135deg)!important;
  right:20px; }

.hero-home-slider .slick-prev
{ -webkit-transform:rotate(45deg)!important;
  left:20px; }

.hero-home-slider .slick-arrow:active
{ -webkit-transform:scale(.95);
  transform:scale(.95); }

.hero-home-slider .slick-next:active
{ -webkit-transform:scale(.95) rotate(180deg);
  transform:scale(.95) rotate(180deg); }

.hero--page
{ display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  min-height:100vh;
  background-color:#fff; }

.hero--page .hero-background,.hero--page .hero-background:after
{ position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0; }

.hero--page .hero-background:after
{ display:block;
  background-color:#fff;
  opacity:.66;
  content:""; }

.hero--page .hero-background img
{ position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:auto;
  height:auto;
  min-height:100%;
  object-fit:cover; }

.hero--page .hero-scrollPrompt
{ position:absolute;
  color:#404040; }

.hero--page .hero-subtitle,.hero--page .hero-title
{ text-align:center; }

.hero--page .hero-title
{ color:#e50045;
  font-size:40px;
  line-height:1.1; }

@media (min-width:300px)
{ .hero--page .hero-title
{ font-size:calc(3.04347826vw + 30.87px); }

}

@media (min-width:760px)
{ .hero--page .hero-title
{ font-size:calc(2.27272727vw + 36.73px); }

}

@media (min-width:1200px)
{ .hero--page .hero-title
{ font-size:64px; }

}

.hero--page .hero-subtitle
{ margin-top:.3em;
  font-size:16px;
  font-weight:500;
  line-height:1.3;
  color:#404040; }

@media (min-width:300px)
{ .hero--page .hero-subtitle
{ font-size:calc(3.04347826vw + 6.87px); }

}

@media (min-width:760px)
{ .hero--page .hero-subtitle
{ font-size:30px; }

}

@media (min-width:700px)
{ .hero--page .hero-container
{ width:50%; }

}

.hero--no-image
{ min-height:0;
  background-color:#ca1524; }

.hero--no-image .hero-subtitle,.hero--no-image .hero-title
{ color:#fff; }

.hero--no-image+.hero-scrollPrompt
{ display:none; }

.hero--landing
{ display:flex;
  -webkit-box-align:end;
  -ms-flex-align:end;
  align-items:flex-end;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  min-height:100vh;
  background-color:#fff; }

.hero--landing .hero-background
{ position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0; }

.hero--landing .hero-background img
{ position:absolute;
  top:50%;
  left:50%;
  -webkit-transform:translate(-50%,-50%);
  transform:translate(-50%,-50%);
  width:auto;
  height:auto;
  min-width:100%;
  min-height:100%;
  -o-object-fit:cover;
  object-fit:cover; }

.hero--landing .hero-scrollPrompt
{ position:absolute;
  color:#404040; }

.hero--landing .hero-subtitle,.hero--landing .hero-title
{ text-align:center; }

.hero--landing .hero-title
{ color:#e50045;
  font-size:40px;
  line-height:1.1; }

@media (min-width:300px)
{ .hero--landing .hero-title
{ font-size:calc(3.04347826vw + 30.87px); }

}

@media (min-width:760px)
{ .hero--landing .hero-title
{ font-size:calc(2.27272727vw + 36.73px); }

}

@media (min-width:1200px)
{ .hero--landing .hero-title
{ font-size:64px; }

}

.hero--landing .hero-subtitle
{ margin-top:.3em;
  font-size:16px;
  font-weight:500;
  line-height:1.3;
  color:#404040; }

@media (min-width:300px)
{ .hero--landing .hero-subtitle
{ font-size:calc(3.04347826vw + 6.87px); }

}

@media (min-width:760px)
{ .hero--landing .hero-subtitle
{ font-size:30px; }

}

@media (min-width:700px)
{ .hero--landing .hero-container
{ width:100%; }

.hero--landing .hero-content
{ max-width:50%; }

.hero--landing
{ -webkit-box-pack:start;
  -ms-flex-pack:start;
  justify-content:flex-start; }

.hero--landing .hero-subtitle,.hero--landing .hero-title
{ text-align:left; }

}

@media (min-width:1200px) { 
	.hero--landing {
		-webkit-box-align:start;
		-ms-flex-align:start;
		align-items:flex-start; 
	}
}

.hero--product .hero-container
{ -ms-flex-negative:0;
  flex-shrink:0;
  -webkit-box-flex:1;
  -ms-flex-positive:1;
  flex-grow:1;
  -ms-flex-preferred-size:auto;
  flex-basis:auto; }

.hero--product .hero-scrollPrompt
{ position:absolute;
  bottom:10vh;
  color:#404040; }

.hero-download-text p b,.hero-download-text p i,.hero-download-text p strong
{ color:inherit!important; }

.hero--product .hero-logo
{ margin:0 auto; }

.hero--product .hero-logo svg
{ display:block;
  max-width:100%;
  height:auto; }

.hero--product .hero-background
{ position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%; }

.hero--product .hero-content
{ padding-top:5vh;
  text-align:center; }

.hero--product .hero-title
{ margin-top:5vh;
  font-size:20px;
  line-height:1.25; }

@media (min-width:300px)
{ .hero--product .hero-title
{ font-size:calc(5.2173913vw + 4.35px); }

}

@media (min-width:760px)
{ .hero--product .hero-title
{ font-size:calc(1.36363636vw + 33.64px); }

}

@media (min-width:1200px)
{ .hero--product .hero-title
{ font-size:50px; }

}

.hero--product .hero-badge
{ position:absolute;
  top:0;
  right:0; }

@media (max-width:1000px)
{ .hero--product .hero-badge
{ -webkit-transform:translateX(calc(6.25vw + 2.5px));
  transform:translateX(calc(6.25vw + 2.5px)); }

}

@media (max-width:700px)
{ .hero--product .hero-badge
{ display:none; }

}

@media (-ms-high-contrast:none)
{ 
	.hero--product,::-ms-backdrop { 
		min-height:0; 
	}
}

.hero-download
{ margin-top:5vh; }

.hero-download-title
{ font-size:18px;
  font-weight:500; }

@media (min-width:340px)
{ .hero-download-title
{ font-size:calc(3.33333333vw + 6.67px); }

}

@media (min-width:760px)
{ .hero-download-title
{ font-size:32px; }

}

.hero-download-links
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  margin:0 -10px; }

.hero-download-link
{ display:block;
  padding:10px;
  text-decoration:none; }

.hero-download-link img
{ display:block; }

.hero-download-text p
{ margin-top:1em;
  font-size:18px;
  text-align:center; }

.hero-download-text p i
{ font-weight:inherit;
  font-style:italic; }

.hero-download-text p em
{ font-weight:inherit;
  font-style:inherit; }

.hero-download-text p a
{ color:inherit; }

@media (min-width:760px)
{ .hero-download-text p
{ font-size:33px; }

.hero-download-link
{ padding:20px; }

}

.hero-product-image-container,.hero-product-slider-container
{ margin-top:60px;
  -ms-flex-negative:0;
  flex-shrink:0; }
  
.product--tigerticket .hero--product .hero-product-image-container
{ margin-top: 0; }

.hero-product-image-container .hero-separator,.hero-product-slider-container .hero-separator
{ z-index:1;
  bottom:0;
  width:100%;
/*  height:35vw; */
  padding:0;
  margin:0; }

@media (min-width:1400px)
{ .hero-product-image-container .hero-separator,.hero-product-slider-container .hero-separator
{ height:490px; }
.product--tigertones .hero-product-image-container .hero-separator
{ height:250px; }
.product--tigerbox-touch .hero-product-image-container .hero-separator
{ height:10px; }
.product--tigerticket .hero-product-image-container .hero-separator, .product--tigercards .hero-product-image-container .hero-separator
{ height:190px; }
}

.hero-product-image img
{ display:block;
  margin:0 auto; }

.hero-product-image img,.hero-product-slider
{ position:relative;
  z-index:2; }

.hero-product-slider .slick-arrow
{ position:absolute;
  top:50%;
  z-index:3;
  -webkit-transition:-webkit-transform .03s;
  transition:-webkit-transform .03s;
  transition:transform .03s;
  transition:transform .03s,-webkit-transform .03s;
  -webkit-transform:translateY(-50%);
  transform:translateY(-50%);
  font-size:0;
  width:44px;
  height:44px;
  border-radius:44px;
  background-color:#e50045;
  outline:0;
  border:0;
  -webkit-box-shadow:0 1px 3px 0 rgba(0,0,0,.35);
  box-shadow:0 1px 3px 0 rgba(0,0,0,.35);
  cursor:pointer; }

.hero-product-slider .slick-arrow:before
{ position:absolute;
  top:12px;
  left:13px;
  display:block;
  width:0;
  height:0;
  border-top:10px solid transparent;
  border-bottom:10px solid transparent;
  content:""; }

.hero-product-slider .slick-arrow:focus,.hero-product-slider .slick-arrow:hover
{ -webkit-transform:translateY(-50%) scale(1.05);
  transform:translateY(-50%) scale(1.05); }

.hero-product-slider .slick-arrow:active
{ -webkit-transform:translateY(-50%) scale(.9);
  transform:translateY(-50%) scale(.9);
  -webkit-box-shadow:none;
  box-shadow:none; }

.hero-product-slider .slick-prev
{ left:20px; }

.hero-product-slider .slick-prev:before
{ border-right:13px solid #fff; }

.hero-product-slider .slick-next
{ right:20px; }

.hero-product-slider .slick-next:before
{ border-left:13px solid #fff;
  left:17px; }

@media (min-width:700px)
{ .hero-product-slider .slick-arrow
{ width:60px;
  height:60px;
  border-radius:60px; }

.hero-product-slider .slick-arrow:before
{ top:16px;
  left:18px;
  border-top:14px solid transparent;
  border-bottom:14px solid transparent; }

.hero-product-slider .slick-prev:before
{ border-right:17px solid #fff; }

.hero-product-slider .slick-next:before
{ border-left:17px solid #fff;
  left:24px; }

}

.hero-product-slider-item
{ outline:0; }

.hero-product-slider-item img
{ display:block;
  width:100%;
  height:auto; }

@media (min-width:800px)
{ .hero-product-slider-item
{ padding:0 10px; }

}

@supports ((-webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)) or (clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)))
{ .hero--product
{ padding-bottom:0; }

.hero-product-image-container .hero-separator
{ -webkit-clip-path:polygon(0 7vw,100% 0,100% 100%,0 100%);
  clip-path:polygon(0 7vw,100% 0,100% 100%,0 100%); }
}
@media (min-width:1440px)
{ .hero-product-image-container .hero-separator
{ -webkit-clip-path:polygon(0 100px,100% 0,100% 100%,0 100%);
  clip-path:polygon(0 100px,100% 0,100% 100%,0 100%); }
}

.product--tigerbooks .hero--product .hero-logo
{ width:208px; }

@media (min-width:370px)
{ .product--tigerbooks .hero--product .hero-logo
{ width:calc(44.1025641vw + 44.82px); }

}

@media (min-width:760px)
{ .product--tigerbooks .hero--product .hero-logo
{ width:calc(14.84375vw + 267.19px); }

}

@media (min-width:1400px)
{ .product--tigerbooks .hero--product .hero-logo
{ width:475px; }

}

.product--tigerbooks .hero--product .hero-separator
{ height:240px;
  margin-top:-100px; }

.product--tigerbooks .hero--product .hero-scrollPrompt
{ bottom:60px; }

@media (min-width:800px)
{ .product--tigerbooks .hero--product .hero-separator
{ height:350px;
  margin-top:-200px; }

.product--tigerbooks .hero--product .hero-scrollPrompt
{ bottom:80px; }

}

@media (min-width:1100px)
{ .product--tigerbooks .hero--product .hero-separator
{ height:40vw;
  margin-top:-27vw; }

.product--tigerbooks .hero--product .hero-scrollPrompt
{ bottom:7vw; }

}

.product--tigertones .hero--product,.product--tigercards .hero--product,.product--tigerticket .hero--product
{ background-color:#f6a809; }

.product--tigertones .hero--product .hero-logo,.product--tigercards .hero--product .hero-logo,.product--tigerticket .hero--product .hero-logo
{ width:208px; }

@media (min-width:370px)
{ .product--tigertones .hero--product .hero-logo,.product--tigercards .hero--product .hero-logo,.product--tigerticket .hero--product .hero-logo
{ width:calc(44.1025641vw + 44.82px); }

}

@media (min-width:760px)
{ .product--tigertones .hero--product .hero-logo,.product--tigercards .hero--product .hero-logo,.product--tigerticket .hero--product .hero-logo
{ width:calc(15.15625vw + 264.81px); }

}

@media (min-width:1400px) {
  .product--tigerticket .hero--product .hero-logo {
    width:420px; 
  }
  .product--tigercards .hero--product .hero-logo {
    width:477px; 
  }
  .product--tigertones .hero--product .hero-logo {
    width:477px; 
  }
}

.product--tigertones .hero--product .hero-scrollPrompt,.product--tigercards .hero--product .hero-scrollPrompt,.product--tigerticket .hero--product .hero-scrollPrompt
{ bottom:5vh; }

.product--tigertones .hero--product .hero-separator,.product--tigercards .hero--product .hero-separator,.product--tigerticket .hero--product .hero-separator
{ max-height:420px; }

.product--tigerticket .hero--product .hero-product-image
{ padding:0 40px;
  margin-bottom:7vw; }
.product--tigercards .hero--product .hero-product-image
{ padding:0 4px;
  margin-bottom:4vw; }
.product--tigertones .hero--product .hero-product-image
{ padding:0 40px;
  margin-bottom:8vw; }

@media (min-width:600px) { 
  .product--tigertones .hero--product .hero-scrollPrompt,.product--tigercards .hero--product .hero-scrollPrompt,.product--tigerticket .hero--product .hero-scrollPrompt { 
    bottom:8vh; 
}
  .product--tigerticket .hero--product .hero-product-image {  
    margin-bottom:7vw; 
  }
  .product--tigercards .hero--product .hero-product-image {  
    margin-bottom:4vw; 
  }
    .product--tigertones .hero--product .hero-product-image {  
    margin-bottom:8vw; 
  }

}

@media (min-width:900px) { 
  .product--tigerticket .hero--product .hero-product-image {
    margin-bottom:7vw; 
  }
  .product--tigercards .hero--product .hero-product-image {
    margin-bottom:4vw; 
  }
    .product--tigertones .hero--product .hero-product-image {
    margin-bottom:8vw; 
  }
}

@media (min-width:1000px) { 
  .product--tigercards .hero--product .hero-product-image {   
    margin-bottom:40px; 
  }
  .product--tigertones .hero--product .hero-product-image {   
    margin-bottom:80px; 
  }
}

@media (min-width:1400px) { 
  .product--tigertones .hero--product .hero-product-image {   
    margin-bottom:-140px; 
  }
  .product--tigerticket .hero--product .hero-product-image {   
    margin-bottom:-60px; 
  }
}

.product--tigertab .hero--product .hero-logo
{ width:177px; }

@media (min-width:370px)
{ .product--tigertab .hero--product .hero-logo
{ width:calc(34.1025641vw + 50.82px); }

}

@media (min-width:760px)
{ .product--tigertab .hero--product .hero-logo
{ width:calc(11.25vw + 224.5px); }

}

@media (min-width:1400px)
{ .product--tigertab .hero--product .hero-logo
{ width:382px; }

}

.product--tigertab .hero--product .hero-scrollPrompt
{ bottom:calc(30px + 2vw); }

.product--tigertab .hero--product .hero-container
{ width:100%;
  max-width:1200px;
  -webkit-box-flex:0;
  -ms-flex-positive:0;
  flex-grow:0; }

.product--tigertab .hero--product .hero-title
{ text-align:left;
  margin-left:0;
  margin-right:33%;
  max-width:480px; }

.product--tigertab .hero--product .hero-product-image-container
{ margin:0; }

.product--tigertab .hero--product .hero-product-image-container .hero-separator
{ height:40vw; }

@media (min-width:600px)
{ .product--tigertab .hero--product .hero-product-image-container .hero-separator
{ height:32vw; }

}

@media (min-width:800px)
{ .product--tigertab .hero--product .hero-product-image-container .hero-separator
{ height:28vw; }

}

.product--tigertab .hero--product .hero-product-image
{ padding:0 40px;
  margin-bottom:-18vw;
  margin-right:-100px;
  margin-top:-60px; }

@media (min-width:700px)
{ .product--tigertab .hero--product .hero-title
{ margin-top:8vh; }

.product--tigertab .hero--product .hero-product-image
{ margin-top:-140px; }

}

.product--tigerbox .hero--product, .product--tigerbox-touch .hero--product
{ background-color:#f6a809; }

.product--tigerbox .hero--product .hero-logo, .product--tigerbox-touch .hero--product .hero-logo
{ width:170px; }

@media (min-width:370px)
{ .product--tigerbox .hero--product .hero-logo, .product--tigerbox-touch .hero--product .hero-logo
{ width:calc(35.8974359vw + 37.18px); }

}

@media (min-width:760px)
{ .product--tigerbox .hero--product .hero-logo, .product--tigerbox-touch .hero--product .hero-logo
{ width:calc(13.4375vw + 207.88px); }

}

@media (min-width:1400px)
{ .product--tigertab .hero--product .hero-product-image-container .hero-separator
{ height:392px; }

.product--tigertab .hero--product .hero-product-image
{ margin-bottom:-260px; }

.product--tigerbox .hero--product .hero-logo, .product--tigerbox-touch .hero--product .hero-logo
{ width:396px; }

}

.product--tigerbox .hero--product .hero-product-image
{ padding:0 40px;
  margin-bottom:-10vw; }
  
.product--tigerbox-touch .hero--product .hero-product-image
{ padding:0 4px;
  margin-bottom:-24vw; }

@media (min-width:600px)
{ 
.product--tigerbox .hero--product .hero-product-image
{ margin-bottom:-16vw; }
.product--tigerbox-touch .hero--product .hero-product-image
{ margin-bottom:-14vw; }
}

@media (min-width:900px)
{ .product--tigerbox .hero--product .hero-product-image, .product--tigerbox-touch .hero--product .hero-product-image
{ margin-bottom:-10vw; }

}

@media (min-width:1200px)
{ .product--tigertab .hero--product .hero-product-image
{ margin-top:-200px; }

.product--tigerbox .hero--product .hero-product-image
{ margin-bottom:-420px; }

.product--tigerbox-touch .hero--product .hero-product-image
{ margin-bottom:-60px; }

}

.product--tigerbox .hero--product .hero-separator, .product--tigerbox-touch .hero--product .hero-separator
{ min-height:160px; }

.product--superbuch .hero--product
{ background-color:#4195a4;
  background-image:url(./../../../assets/images/Superbuch-Stars.png),url(./../../../assets/images/Superbuch-Landing-Background.jpg);
  background-position:center 80px,50%;
  background-size:auto,cover;
  background-repeat:repeat,no-repeat; }

.product--superbuch .hero--product .hero-scrollPrompt
{ bottom:5vh; }

.product--superbuch .hero--product .hero-container
{ width:100%; }

.product--superbuch .hero--product .hero-superbuch-wow
{ width:50vw;
  max-width:327px; }

.product--superbuch .hero--product .hero-superbuch-sticker
{ position:absolute;
  top:20px;
  right:-20px;
  width:129px; }

.product--superbuch .hero--product .hero-product-image
{ padding:0 40px;
  margin-bottom:-10vw; }

@media (min-width:700px)
{ .product--superbuch .hero--product .hero-superbuch-wow
{ margin:0 auto; }

.product--superbuch .hero--product .hero-product-image
{ margin-bottom:-14vw; }

.product--superbuch .hero--product .hero-superbuch-sticker
{ width:184px; }

}

@media (min-width:900px)
{ .product--superbuch .hero--product .hero-product-image
{ margin-bottom:-20vw; }

}

@media (min-width:1000px)
{ .product--superbuch .hero--product .hero-product-image
{ margin-bottom:-240px; }

.product--superbuch .hero--product .hero-superbuch-sticker
{ width:auto; }

}

.siteFooter
{ position:relative;
  z-index:0;
  overflow:hidden;
  background-position: bottom,50%;
  background-size:1441px 600px,100vw 100vw;
  background-repeat:repeat,no-repeat;
  background-color: #fab900;
  color:#fff;
}

.siteFooter-container
{ position:relative;
  padding-right:20px;
  padding-left:20px;
  max-width:1440px;
  margin:0 auto;
  bottom: 0;
}

@media (min-width:300px)
{ .siteFooter-container
{ padding-right:calc(6.52173913vw + .43px);
  padding-left:calc(6.52173913vw + .43px);
  bottom: 0;
}

}

@media (min-width:760px)
{ .siteFooter-container
{ padding-right:calc(6.25vw + 2.5px);
  padding-left:calc(6.25vw + 2.5px);
  bottom: 0;
}

}

@media (min-width:1400px)
{ .siteFooter-container
{ padding-right:90px;
  padding-left:90px;
  bottom: 0;
}

}

.siteFooter-background
{ position:relative;
  bottom: 0;
  left:0;
  width:100%;
  height:100%; }

@media (min-width:0px)
{ .siteFooter
{
  position: relative;
  bottom: 0;
  margin-bottom: 0;
  padding-top:200px;
  -webkit-clip-path:polygon(0 100px,100% 0,100% 100%,0 100%);
  clip-path:polygon(0 100px,100% 0,100% 100%,0 100%); }
}



.siteFooter-content
{ padding:20px 0 40px;
  position:relative;
  text-align: center;
}

@media (min-width:700px)
{ .siteFooter-content
{ padding-top:40px; }

}

@media (min-width:1000px)
{ .siteFooter-content
{ padding-top:50px; }

}

.siteFooter-title
{ font-size:23px;
  line-height:1.3; }

@media (min-width:300px)
{ .siteFooter-title
{ font-size:calc(6.0vw + .5px); }

}

.newsletterForm
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-flow:column;
  flex-flow:column;
  max-width:400px;
  margin-top:20px;
  margin-right:auto;
  margin-left:auto; }

.newsletterForm .formInput
{ margin:3px 10px 5px; }

.newsletterForm .formInput::-webkit-input-placeholder
{ color:#ababab; }

.newsletterForm .formInput:-ms-input-placeholder
{ color:#ababab; }

.newsletterForm .formInput::placeholder
{ color:#ababab; }

@media (min-width:700px)
{ .siteFooter-title
{ font-size:40px; }

.newsletterForm
{ -webkit-box-orient:horizontal;
  -webkit-box-direction:normal;
  -ms-flex-flow:row;
  flex-flow:row;
  max-width:800px;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center; }

.newsletterForm .formInput
{ -webkit-box-flex:1;
  -ms-flex-positive:1;
  flex-grow:1; }

}

.newsletterAlert
{ display:none;
  position:relative;
  z-index:10; }

.newsletterAlert-container
{ position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center; }

.newsletterAlert-content
{ padding:100px 50px;
  margin:20px;
  -webkit-box-shadow:0 0 10px 0 rgba(0,0,0,.5);
  box-shadow:0 0 10px 0 rgba(0,0,0,.5);
  opacity:.95;
  font-size:50px;
  font-weight:600;
  text-align:center; }

.success .newsletterAlert-content
{ color:#0a917c; }

.error .newsletterAlert-content
{ color:#ed6e1e; }

.siteFooter-navigation
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-flow:column;
  flex-flow:column;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  margin-bottom:22px;
  font-size:19px;
  color:#fff; }

.siteFooter-navigation.siteFooter-navigation a
{ color:inherit; }

@media (min-width:600px)
{ .product--superbuch .hero--product .hero-scrollPrompt
{ bottom:8vh; }

.siteFooter-navigation
{ -webkit-box-orient:horizontal;
  -webkit-box-direction:normal;
  -ms-flex-flow:row;
  flex-flow:row;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  margin-bottom:22px;
}

}

@media (min-width:1200px)
{ .siteFooter-navigation
{ margin-top:100px;}

}

.siteFooter-brand
{ -webkit-box-ordinal-group:2;
  -ms-flex-order:1;
  order:1;
  margin-top:50px; }

@media (min-width:1200px)
{ .siteFooter-brand
{ -webkit-box-ordinal-group:1;
  -ms-flex-order:0;
  order:0;
  margin:0; }

}

.siteFooter-siteLinks
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-flow:column;
  flex-flow:column;
  font-weight:400; }

.siteFooter-siteLinks a
{ display:block;
  padding:1px 15px;
  text-decoration:none;
  text-align:center; }

.siteFooter-siteLinks a:after
{ display:block;
  content:attr(title);
  font-weight:700;
  height:1px;
  color:transparent;
  overflow:hidden;
  visibility:hidden;
  margin-bottom:-1px; }

.siteFooter-siteLinks a.is-current
{ font-weight:400;
  text-decoration:underline; }

.siteFooter-siteLinks a:focus,.siteFooter-siteLinks a:hover
{ font-weight:600;
  margin-top:-1px;
  text-decoration:none; }

@media (min-width:600px)
{ .siteFooter-siteLinks
{ -ms-flex-preferred-size:100%;
  flex-basis:100%;
  -webkit-box-orient:horizontal;
  -webkit-box-direction:normal;
  -ms-flex-flow:row;
  flex-flow:row;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  margin-top:70px; }

}

@media (min-width:1200px)
{ .siteFooter-siteLinks
{ -ms-flex-preferred-size:auto;
  flex-basis:auto;
  -webkit-box-orient:horizontal;
  -webkit-box-direction:normal;
  -ms-flex-flow:row;
  flex-flow:row;
  margin-top:0; }

}

.landingTeaser-container,body.error .siteWrapper,body.homepage .homeSectionsNavigation,body.homepage .homeSectionsNavigation-container,body.page .siteWrapper,body.support .siteWrapper
{ -webkit-box-orient:vertical;
  -webkit-box-direction:normal; }

.siteFooter-social {
  font-size: 19px;
  margin-top:40px;
}

.siteFooter-social a
{ font-weight:600;
  color: #fff;
  text-decoration:none; }

.siteFooter-social a:focus,.siteFooter-social a:hover
{ text-decoration:underline; }

@media (min-width:600px)
{ .siteFooter-social
{ -webkit-box-ordinal-group:3;
  -ms-flex-order:2;
  order:2; }

}

@media (min-width:1200px)
{ .siteFooter-social
{ -webkit-box-ordinal-group:1;
  -ms-flex-order:0;
  order:0;
  margin: 50px 0 0;
}

}

.newsletterForm.is-disabled .formInput,.newsletterForm.is-disabled .formSubmit
{ opacity:.5; }

.siteSection
{ position:relative;
  z-index:1;
  padding:0 5vw;
  overflow:hidden; }

@media (min-width:1440px)
{ .siteSection
{ padding:0 100px; }

}

.siteSection-container
{ position:relative;
  padding:40px 20px;
  max-width:1440px;
  margin:0 auto; }

@media (min-width:300px)
{ .siteSection-container
{ padding-top:calc(3.26086957vw + 30.22px);
  padding-bottom:calc(3.26086957vw + 30.22px);
  padding-right:calc(6.52173913vw + .43px);
  padding-left:calc(6.52173913vw + .43px); }
  .sofi-box .siteSection-container
{ padding-top:calc(3.26086957vw + 20.22px);
  padding-bottom:calc(3.26086957vw + 20.22px);}
}

@media (min-width:760px)
{ .siteSection-container
{ padding-top:calc(8.59375vw - 10.31px);
  padding-bottom:calc(8.59375vw - 10.31px);
  padding-right:calc(6.25vw + 2.5px);
  padding-left:calc(6.25vw + 2.5px); }
  .sofi-box .siteSection-container
{ padding-top:calc(5.59375vw - 10.31px);
  padding-bottom:calc(5.59375vw - 10.31px);}
}

@media (min-width:1400px)
{ .siteSection-container
{ padding:110px 90px; }
.sofi-box .siteSection-container
{ padding:70px 90px; }

}

.siteSection--base+.siteSection--base .siteSection-container
{ padding-top:0; }

.siteSection--base
{ z-index:2; }

.siteSection--colored,.siteSection--coloredPlain
{ background-color:#404040;
  color:#fff; }

.siteSection--coloredPlain
{ background-image:url(./../../../assets/images/BackgroundPattern-White@2x.png);
  background-position:top;
  background-size:1441px 810px; }

@supports ((-webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)) or (clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)))
{ .siteSection
{ padding:7vw 0; }

.siteSection--base
{ padding:7vw 0;
  margin:-7vw 0;
  -webkit-clip-path:polygon(0 7vw,100% 0,100% calc(100% - 7vw),0 100%);
  clip-path:polygon(0 7vw,100% 0,100% calc(100% - 7vw),0 100%); }
}

@media (min-width:1440px)
{ .siteSection
{ padding:100px 0; }

.siteSection--base
{ padding:100px 0;
  margin:-100px 0;
  -webkit-clip-path:polygon(0 100px,100% 0,100% calc(100% - 100px),0 100%);
  clip-path:polygon(0 100px,100% 0,100% calc(100% - 100px),0 100%); }
}

.sectionSeparator
{ position:relative;
  z-index:2;
  padding:0 0 10%;
  background-color:#fff;
  background-image:url(./../../../assets/images/BackgroundPattern-Color@2x.png);
  background-position:50%;
  background-size:1441px 810px; }

@supports ((-webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)) or (clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)))
{ .sectionSeparator
{ padding:calc(7vw + 20px) 0;
  margin:-7vw 0;
  -webkit-clip-path:polygon(0 7vw,100% 0,100% calc(100% - 7vw),0 100%);
  clip-path:polygon(0 7vw,100% 0,100% calc(100% - 7vw),0 100%); }
}

@media (min-width:1440px)
{ .sectionSeparator
{ padding:120px 0;
  margin:-100px 0;
  -webkit-clip-path:polygon(0 100px,100% 0,100% calc(100% - 100px),0 100%);
  clip-path:polygon(0 100px,100% 0,100% calc(100% - 100px),0 100%); }
}

.formField--center,.formField--centered
{ text-align:center; }

.formLabel
{ font-size:24px;
  font-weight:500; }

.formInput
{ padding:8px 22px 9px;
  border-radius:35px;
  border:1px solid transparent;
  outline:0;
  font-size:20px;
  text-align:center;
  color:#404040; }

@media (min-width:700px)
{ .formInput
{ font-size:24px; }

}

.button
{ font-size:21px;
  text-decoration:none; }

.siteFooter .formInput,.siteSection--colored .formInput
{ border-color:#dedede;
  -webkit-box-shadow:inset 0 0 2px 0 rgba(0,0,0,.5);
  box-shadow:inset 0 0 2px 0 rgba(0,0,0,.5); }

.button
{ -webkit-transition:all .03s;
  transition:all .03s;
  display:inline-block;
  background-color:hsla(0,0%,100%,.2);
  padding:10px;
  border-radius:35px;
  outline:0;
  border:0;
  -webkit-box-shadow:0;
  box-shadow:0;
  color:#ca1524;
  cursor:pointer; }

.button.button--colored
{ background-color:rgba(229,0,69,.2);
  color:#fff; }

.button-inner,.button-inner--mobile
{ display:block;
  padding:11px 28px 10px;
  border-radius:27px;
  -webkit-box-shadow:0 1px 2px 0 rgba(0,0,0,.25);
  box-shadow:0 1px 2px 0 rgba(0,0,0,.25);
  background-color:#fff;
  font-weight:700; }

.button--colored .button-inner,.button--colored .button-inner--mobile
{ background-color:#e50045; }

.button-inner--mobile
{ display:block; }

.button-inner--mobile+.button-inner
{ display:none; }

@media (min-width:760px)
{ .button-inner--mobile
{ display:none; }

.button-inner--mobile+.button-inner
{ display:block; }

}

strong.button-inner
{ text-transform:uppercase;
  text-align: center; }

.button:focus,.button:hover
{ -webkit-transform:scale(1.05);
  transform:scale(1.05); }

.button:active
{ -webkit-transform:scale(.95);
  transform:scale(.95);
  padding:5px;
  background-color:hsla(0,0%,100%,.1); }

.button:active.button--colored
{ background-color:rgba(229,0,69,.1); }

.button:active .button-inner
{ -webkit-box-shadow:none;
  box-shadow:none; }

.swirl-large,.swirl-small
{ background-position:0 0;
  background-repeat:no-repeat; }

.swirl-small
{ width:216px;
  height:54px;
  background-image:url(./../../../assets/images/Swirl-Small@2x.png);
  background-size:216px 54px; }

.swirl-large
{ width:400px;
  height:64px;
  background-image:url(./../../../assets/images/Swirl-Large@2x.png);
  background-size:400px 64px; }

/*.pagination
{ -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  margin-top:40px; }

.pagination,.pagination .page-numbers
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center; }

.pagination .page-numbers
{ -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  width:49px;
  height:49px;
  margin:10px;
  border-radius:100px;
  -webkit-box-shadow:0 1px 2px 0 rgba(0,0,0,.25);
  box-shadow:0 1px 2px 0 rgba(0,0,0,.25);
  background-color:#e50045;
  font-weight:500;
  color:#fff; }

.pagination .page-numbers.current
{ -webkit-box-shadow:none;
  box-shadow:none;
  background-color:#c2c2c2;
  color:#9b9b9b; }*/

.hero-scrollPrompt-wrapper
{ position:relative;
  z-index:2; }

.hero-scrollPrompt-button
{ position:fixed;
  bottom:50px;
  left:50%;
  margin-left:-32px;
  display:none;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  width:64px;
  height:64px;
  border-radius:35px;
  background-color:#fff;
  background-color:hsla(0,0%,100%,.9);
  -webkit-box-shadow:0 1px 2px 0 rgba(0,0,0,.3);
  box-shadow:0 1px 2px 0 rgba(0,0,0,.3);
  -webkit-animation-name:scrollPromptButtonBounce;
  animation-name:scrollPromptButtonBounce;
  -webkit-animation-duration:3s;
  animation-duration:3s;
  -webkit-animation-fill-mode:both;
  animation-fill-mode:both;
  -webkit-animation-timing-function:linear;
  animation-timing-function:linear;
  -webkit-animation-iteration-count:infinite;
  animation-iteration-count:infinite;
  cursor:default; }

@media (min-width:700px)
{ .hero-scrollPrompt-button
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex; }

}

.hero-scrollPrompt-button .hero-scrollPrompt
{ position:static;
  color:#000; }

.hero-scrollPrompt-button .hero-scrollPrompt svg
{ display:block;
  margin:0 auto; }

.hero-scrollPrompt
{ -webkit-animation-name:scrollPromptBounce;
  animation-name:scrollPromptBounce;
  -webkit-animation-duration:3s;
  animation-duration:3s;
  -webkit-animation-fill-mode:both;
  animation-fill-mode:both;
  -webkit-animation-timing-function:linear;
  animation-timing-function:linear;
  -webkit-animation-iteration-count:infinite;
  animation-iteration-count:infinite; }

@-webkit-keyframes scrollPromptBounce
{ 0%,20%
{ -webkit-transform:translateY(0);
  transform:translateY(0); }

10%
{ -webkit-transform:translateY(-5px);
  transform:translateY(-5px); }

15%
{ -webkit-transform:translateY(4px);
  transform:translateY(4px); }

}

@keyframes scrollPromptBounce
{ 0%,20%
{ -webkit-transform:translateY(0);
  transform:translateY(0); }

10%
{ -webkit-transform:translateY(-5px);
  transform:translateY(-5px); }

15%
{ -webkit-transform:translateY(4px);
  transform:translateY(4px); }

}

@-webkit-keyframes scrollPromptButtonBounce
{ 0%,10%,20%
{ -webkit-transform:translateY(0);
  transform:translateY(0); }

15%
{ -webkit-transform:translateY(3px);
  transform:translateY(3px); }

}

@keyframes scrollPromptButtonBounce
{ 0%,10%,20%
{ -webkit-transform:translateY(0);
  transform:translateY(0); }

15%
{ -webkit-transform:translateY(3px);
  transform:translateY(3px); }

}

body.homepage .hero-background
{ position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%; }

body.homepage .siteMain
{ z-index:1; }

body.homepage .hero-scrollPrompt-button
{ bottom:30px; }

@media (min-height:800px)
{ body.homepage .hero-scrollPrompt-button
{ bottom:100px; }

}

body.homepage .homeSectionsNavigation-wrapper
{ opacity:0;
  position:relative;
  z-index:4;
  -webkit-transition:opacity .15s;
  transition:opacity .15s;
  pointer-events:none; }

@media (min-width:700px)
{ body.homepage .homeSectionsNavigation-wrapper.is-visible
{ opacity:1;
  pointer-events:all; }

}

body.homepage .homeSectionsNavigation
{ position:fixed;
  left:20px;
  top:0;
  bottom:0;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-flow:column;
  flex-flow:column;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center; }

@media (min-width:1000px)
{ body.homepage .homeSectionsNavigation
{ left:40px; }

}

body.homepage .homeSectionsNavigation-container
{ display:flex;
  -ms-flex-flow:column;
  flex-flow:column;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center; }

body.homepage .homeSectionsNavigation-item
{ display:block;
  text-decoration:none;
  cursor:default; }

body.homepage .homeSectionsNavigation-item .icon,body.homepage .homeSectionsNavigation-item svg
{ display:block; }

body.homepage .homeSectionsNavigation-item .icon
{ width:60px;
  height:60px;
  border-radius:30px;
  -webkit-box-shadow:0 1px 8px rgba(0,0,0,.15);
  box-shadow:0 1px 8px rgba(0,0,0,.15);
  -webkit-transition:-webkit-transform .15s;
  transition:-webkit-transform .15s;
  transition:transform .15s;
  transition:transform .15s,-webkit-transform .15s;
  -webkit-transform:scale(.83);
  transform:scale(.83);
  cursor:pointer; }

body.homepage .homeSectionsNavigation-item svg
{ opacity:.5; }

body.homepage .homeSectionsNavigation-item .icon:hover,body.homepage .homeSectionsNavigation-item.is-active .icon
{ -webkit-box-shadow:0 1px 8px rgba(0,0,0,.3);
  box-shadow:0 1px 8px rgba(0,0,0,.3);
  -webkit-transform:scale(1);
  transform:scale(1); }

body.homepage .homeSectionsNavigation-item .icon:hover svg,body.homepage .homeSectionsNavigation-item.is-active .icon svg
{ opacity:1; }

body.homepage .homeSectionsNavigation-item:before
{ display:block;
  width:2px;
  height:22px;
  margin:5px auto;
  border-radius:1px;
  background-color:hsla(0,0%,100%,.2);
  content:""; }

body.homepage .homeSectionsNavigation-item:first-child:before
{ display:none; }

.homeSection
{ position:relative;
  color:#fff;
  font-weight:700;
  padding-right:0;
  padding-left:0;
  padding-bottom:0; }

.homeSection .siteSection-background
{ z-index:1; }

.homeSection .siteSection-container
{ padding-right:5vw;
  padding-left:5vw;
  z-index:3; }

.homeSection .sectionSeparator
{ z-index:2; }

@media (min-width:700px)
{ .homeSection .siteSection-content
{ padding-left:7vw; }

}

@media (min-width:1440px)
{ .homeSection .siteSection-content
{ padding-left:100px; }

}

.homeSection-logo svg
{ display:block;
  max-width:100%;
  max-height:100%; }

.homeSection-description,.homeSection-description p
{ font-size:18px;
  line-height:1.35; }

@media (min-width:360px)
{ .homeSection-description,.homeSection-description p
{ font-size:calc(1.76470588vw + 11.65px); }

}

.homeSection-button
{ margin:2vw 0 6vw;
  text-align:center; }

@supports ((-webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)) or (clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)))
{ .homeSection
{ padding:0 0 20vw;
  margin:-7vw 0 0;
  -webkit-clip-path:polygon(0 7vw,100% 0,100% calc(100% - 7vw),0 100%);
  clip-path:polygon(0 7vw,100% 0,100% calc(100% - 7vw),0 100%); }

.homeSection .sectionSeparator
{ position:absolute;
  bottom:0;
  left:0;
  width:100%;
  height:25vw; }
}

@media (min-width:1440px)
{ .homeSection
{ padding:0 0 280px;
  margin:-100px 0 0;
  -webkit-clip-path:polygon(0 100px,100% 0,100% calc(100% - 100px),0 100%);
  clip-path:polygon(0 100px,100% 0,100% calc(100% - 100px),0 100%); }

.homeSection .sectionSeparator
{ height:355px; }
}

.homeSection--tigerbox .siteSection-content,.homeSection--tigerbox-touch .siteSection-content,.homeSection--tigertab .siteSection-content
{ padding-top:8vw; }

.homeSection--tigerbox, .homeSection--tigerbox-touch
{ background-color:#0a917c; }

.homeSection--tigerbox .homeSection-logo
{ width:262px;
  height:67px;
  margin:0 auto; }
  
.homeSection--tigerbox-touch .homeSection-logo
{ width: 500px;
  height: 150px;
  margin: 0 auto; 
  max-width: 90%; }

.homeSection--tigerbox .homeSection-description,.homeSection--tigerbox-touch .homeSection-description
{ margin-top:6vh; }

@media (min-width:700px)
{ .homeSection-description,.homeSection-description p
{ font-size:24px; }

.homeSection--tigerbox .homeSection-logo
{ width:374px;
  height:96px; }
  
.homeSection--tigerbox-touch .homeSection-logo
{ width:500px;
  height:142px; }

.homeSection--tigerbox .siteSection-content
{ padding-bottom:20px; }

.homeSection--tigerbox .homeSection-button, .homeSection--tigerbox-touch .homeSection-button
{ text-align:center;
  margin:10px 0 60px; }

}

.homeSection--tigertunes,.homeSection--tigercards,.homeSection--tigerticket
{ background-color:#f6a809; }

.homeSection--newsletter.red
{ background-color:#ca1524; }

.homeSection--newsletter.yellow
{ background-color:#f6a809; }

.homeSection--newsletter.blue
{ background-color:#00abed; }

.homeSection--newsletter.green
{ background-color:#0a917c; }

.homeSection--tigerbooks
{ background-color:#ca1524; }

.errorPage-button,.errorPage-image,.homeSection--newsletter,.landingPremium-premiums,.page-headline,.supportHome-headline,.supportProductPage-title,body.newsletter .newsletter-description,body.newsletter .newsletter-unsubscribe-teaser
{ text-align:center; }

.homeSection--newsletter .siteSection-content
{ padding-top:60px; }

.homeSection--newsletter h2
{ font-size:42px;
  line-height:1.3; }
  
.homeSection--superbuch .siteSection-content,.homeSection--tigerbooks .siteSection-content,.homeSection--tigertunes .siteSection-content,.homeSection--tigercards .siteSection-content,.homeSection--tigerticket .siteSection-content
{ padding-top:3vw; }


@media (min-width:550px) {
.homeSection--tigertunes .homeSection-logo,.homeSection--tigercards .homeSection-logo,.homeSection--tigerticket .homeSection-logo
{ width:500px;
  height:104px;
  margin:0 auto; }
}  

@media (min-width:900px)
{ .homeSection--tigerbox .homeSection-logo
{ width:498px;
  height:126px;
  margin:0; }
  
.homeSection--tigerbox-touch .homeSection-logo
{ height: 190px; }

.homeSection--tigerbox .siteSection-content, .homeSection--tigerbox-touch .siteSection-content
{ padding-bottom:0; }

.homeSection--tigerbox .homeSection-description, .homeSection--tigerbox-touch .homeSection-description
{ width: 100%; }

}

.homeSection--tigertab
{ background-color:#00abed; }

.homeSection--tigertab .homeSection-logo
{ width:272px;
  height:70px;
  margin:0 auto; }

.homeSection--tigertab .homeSection-description
{ position:relative;
  margin-top:20px;
  z-index:3; }

@media (min-width:700px)
{ .homeSection--tigertab .homeSection-logo
{ width:362px;
  height:93px; }

.homeSection--tigertab .homeSection-button
{ margin-top:10px;
  text-align:right; }

}

@media (min-width:1100px)
{ .homeSection--tigertab .homeSection-logo
{ width:483px;
  height:124px;
  margin:0;
  position:relative;
  left:-30px;
  z-index:2; }

.homeSection--tigertab .homeSection-description
{ padding-left:56vw;
  padding-top:120px; }

.homeSection--tigertab .homeSection-button
{ margin-top:30px;
  text-align:left; }

}

.homeSection--tigerbooks .homeSection-logo
{ width:308px;
  height:65px;
  margin:0 auto; }

.homeSection--tigerbooks .homeSection-description
{ margin-top:6vh; }

@media (min-width:700px)
{ .homeSection--tigerbooks .homeSection-logo
{ width:443px;
  height:93px; }

.homeSection--tigerbooks .homeSection-description
{ max-width:500px;
  margin:30px auto 0; }

.homeSection--tigerbooks .homeSection-button
{ margin-top:20px;
  text-align:left; }

}

@media (min-width:1000px)
{ .homeSection--tigerbooks .homeSection-logo
{ width:590px;
  height:124px;
  margin:0; }

.homeSection--tigerbooks .homeSection-description
{ max-width:500px;
  margin:60px 40vw 0 0; }

.homeSection--tigerbooks .homeSection-button
{ margin-top:20px; }

}

@media (min-width:1440px)
{ .homeSection--tigertab .homeSection-description
{ padding-left:810px; }

.homeSection--tigerbooks .homeSection-description
{ margin-right:576px; }

}

.homeSection--tigertunes .homeSection-description,.homeSection--tigercards .homeSection-description,.homeSection--tigerticket .homeSection-description
{ margin-top:2vh; }

@media (min-width:700px)
{ .homeSection--tigertunes .homeSection-logo,.homeSection--tigercards .homeSection-logo,.homeSection--tigerticket .homeSection-logo
{ width:448px;
  height:92px; }

.homeSection--tigertunes .homeSection-description,.homeSection--tigercards .homeSection-description,.homeSection--tigerticket .homeSection-description
{ max-width:500px;
  margin:30px auto 0; }

.homeSection--tigertunes .homeSection-button,.homeSection--tigercards .homeSection-button,.homeSection--tigerticket .homeSection-button
{ margin-top:20px;
  text-align:right; }

}

@media (min-width:1000px)
{ 
.homeSection--tigertunes .siteSection-content, .homeSection--tigerticket .siteSection-content
{ padding-bottom:250px; }

.homeSection--tigercards .siteSection-content
{ padding-bottom:310px; }

.homeSection--tigertunes .homeSection-logo,.homeSection--tigercards .homeSection-logo,.homeSection--tigerticket .homeSection-logo
{ width:600px;
  height:130px; }

.homeSection--tigertunes .homeSection-description,.homeSection--tigercards .homeSection-description,.homeSection--tigerticket .homeSection-description
{ max-width:none;
  margin:60px 7vw 0 0; }
  
.homeSection--tigerticket .homeSection-description
{ max-width:60%;
  margin-left: 5%; }
  
.homeSection--tigercards .homeSection-description
{ max-width:48%;
  margin-left: 5%; }

.homeSection--tigertunes .homeSection-button
{ margin-top:20px;
  text-align:center; }

.homeSection--tigercards .homeSection-button
{ margin-top:20px;
  text-align:left; }
  
.homeSection--tigerticket .homeSection-button
{ margin-top:20px;
  text-align:left; }

}

.homeSection--superbuch
{ background-color:#a4cde9; }

.homeSection--superbuch .sectionSeparator
{ z-index:3; }

.siteMain-subnavigation,body.error .siteWrapper,body.page .siteWrapper,body.support .siteWrapper
{ background-image:url(./../../../assets/images/BackgroundPattern-Color@2x.png);
  background-size:1441px 810px; }

.homeSection--superbuch .homeSection-description
{ margin-top:110px; }

@media (min-width:700px)
{ .homeSection--superbuch .homeSection-description
{ max-width:500px;
  margin:160px auto 0; }

.homeSection--superbuch .homeSection-button
{ margin-top:20px;
  text-align:right; }

}

@media (min-width:1000px)
{ .homeSection--superbuch .homeSection-description
{ max-width:500px;
  margin:260px 40vw 0 0; }

.homeSection--superbuch .homeSection-button
{ margin-top:20px;
  text-align:left; }

.homeSection--superbuch .siteSection-background
{ background-position:top; }

}

@media (min-width:1440px)
{ .homeSection--superbuch .homeSection-description
{ margin-right:576px; }

}

@media (min-width:360px)
{ .homeSection--newsletter h2
{ font-size:calc(8.52941176vw - 9.71px); }

}

@supports ((-webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)) or (clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)))
{ .homeSection--newsletter .siteSection-container
{ padding-bottom:0; }

}

body.page .siteWrapper
{ display:flex;
  -ms-flex-flow:column;
  flex-flow:column;
  min-height:100vh;
  height:100vh;
  background-color:#fff;
  background-position:top; }

body.page p a
{ color:inherit; }

body.page b,body.page p a,body.page strong
{ font-weight:600; }

.pageMain
{ -webkit-box-flex:1 0 auto;
  -ms-flex:1 0 auto;
  flex:1 0 auto;
  padding-top:44px; }

.hero~.pageMain
{ padding-top:0; }

.pageContent
{ padding:40px 20px 80px;
  max-width:1440px;
  margin:0 auto; }

@media (min-width:700px)
{ .homeSection--newsletter h2
{ font-size:50px; }

.pageContent
{ padding-top:60px;
  padding-bottom:120px; }

}

@media (min-width:1300px)
{ .pageContent
{ padding-top:80px;
  padding-bottom:160px; }

}

.page-headline
{ font-size:30px;
  margin-bottom:.8em; }

@media (min-width:300px)
{ .pageContent
{ padding-right:calc(6.52173913vw + .43px);
  padding-left:calc(6.52173913vw + .43px); }

.page-headline
{ font-size:calc(5.2173913vw + 14.35px); }

}

@media (min-width:760px)
{ .pageContent
{ padding-right:calc(6.25vw + 2.5px);
  padding-left:calc(6.25vw + 2.5px); }

.page-headline
{ font-size:calc(.625vw + 49.25px); }

}

@media (min-width:1400px)
{ .pageContent
{ padding-right:90px;
  padding-left:90px; }

.page-headline
{ font-size:58px; }

}

.pageContent p
{ font-size:16px; }

.company-subnavigation
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  max-width:1000px;
  padding-right:20px;
  padding-left:20px;
  margin:40px auto 0; }

@media (min-width:300px)
{ .pageContent p
{ font-size:calc(1.52173913vw + 11.43px); }
	
	.wpsl-store-location p, .wpsl-info-window p
	{ font-size:16px; }


.company-subnavigation
{ padding-right:calc(6.52173913vw + .43px);
  padding-left:calc(6.52173913vw + .43px); }

}

@media (min-width:760px)
{ .pageContent p
{ font-size:calc(1.5625vw + 11.13px); }
	
	.wpsl-store-location p, .wpsl-info-window p
	{ font-size:16px; }


.company-subnavigation
{ padding-right:calc(6.25vw + 2.5px);
  padding-left:calc(6.25vw + 2.5px);
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between; }

}

@media (min-width:1400px)
{ .pageContent p
{ font-size:33px; }
	
	.wpsl-store-location p, .wpsl-info-window p
	{ font-size:16px; }

.company-subnavigation
{ padding-right:90px;
  padding-left:90px; }

}

.company-subnavigation-link
{ display:block;
  padding:5px 15px;
  font-size:19px;
  position:relative;
  text-align:center;
  text-decoration:none;
  color:inherit; }

@media (min-width:760px)
{ .company-subnavigation-link
{ font-size:calc(.78125vw + 13.06px); }

}

@media (min-width:1400px)
{ .company-subnavigation-link
{ font-size:24px; }

}

.company-subnavigation-link:before
{ display:block;
  content:attr(title);
  font-weight:700;
  height:1px;
  color:transparent;
  overflow:hidden;
  visibility:hidden;
  margin-bottom:-1px; }

.page-contact-content a,body.support p a
{ color:#e50045; }

.company-subnavigation-link.is-active
{ font-weight:700;
  margin-top:-1px; }

.company-contact-footer a,body.support b,body.support strong
{ font-weight:500; }

.company-contact-footer,body.support .company-contact-footer
{ margin-top:60px; }

.company-subnavigation-link.is-active:after
{ position:absolute;
  bottom:0;
  right:15px;
  left:15px;
  display:block;
  height:3px;
  background-color:#e50045;
  content:""; }

body.error .siteWrapper,body.support .siteWrapper
{ min-height:100vh;
  height:100vh;
  background-color:#fff;
  background-position:top; }

.landingPremium li:before,body.forgotPassword .forgotPassword-description li:before,body.newsletter .newsletter-description li:before,body.newsletter .newsletter-unsubscribe-teaser li:before
{ content:"\2022 ";
  color:#e50045; }

body.support .siteWrapper
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-flow:column;
  flex-flow:column; }

.supportHome-headline
{ margin-top:-30px;
  font-size:30px; }

.supportHome-intro
{ max-width:770px;
  margin:20px auto;
  font-size:16px; }

@media (min-width:760px)
{ .supportHome-headline
{ margin-top:-10px;
  font-size:54px; }

.supportHome-intro
{ margin-top:30px;
  margin-bottom:30px;
  text-align:center;
  font-size:23px; }

}

@media (min-width:1400px)
{ .supportHome-headline
{ margin-top:0;
  font-size:59px; }

.supportHome-intro
{ margin-top:80px;
  font-size:30px;
  font-weight:500; }

}

.supportProductPage-header
{ position:relative;
  margin-top:30px;
  margin-bottom:30px; }

.supportProductPage-title
{ font-size:30px; }

.supportProductPage-description
{ margin-top:1em; }

.supportProductPage-backButton
{ position: relative;
  top: 0;
  left:0; }

@media (min-width:760px)
{ .supportProductPage-header
{ margin-bottom:40px; }

.supportProductPage-title
{ font-size:33px; }

.supportProductPage-description
{ text-align:center; }

}

@media (min-width:1400px)
{ .supportProductPage-header
{ margin-bottom:50px; }
}

@media (min-width:1000px)
{ .supportMain
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between; }

.supportMain .supportCategories,.supportMain .supportFaq,.supportMain .supportPost
{ -webkit-box-flex:0;
  -ms-flex:0 1 45%;
  flex:0 1 45%; }

.supportMain .supportPost
{ -webkit-box-ordinal-group:2;
  -ms-flex-order:1;
  order:1; }

}

.supportPost
{ margin-bottom:30px; }

.supportPost,.supportPost-header
{ padding-bottom:20px;
  border-bottom:1px solid #d8d8d8; }

.supportPost-header
{ margin-bottom:20px; }

.supportPost-title
{ font-size:27px; }

@media (min-width:1400px)
{ .supportPost-title
{ font-size:30px; }

}

.supportPost-content p, .supportPost-content ul
{ font-size:18px; }

.supportPost-content .aligncenter { 
	margin-left: auto;
	margin-right: auto;
}

.supportPost-content .wp-caption {
	margin-top: 1em;
	margin-bottom: 1em;
}

.supportPost-content .wp-caption-text {
	font-size:0.9em; 	
}

body.landing .newsletterForm,body.landing .siteFooter-title
{ display:none; }

body.landing .siteFooter-navigation
{ margin-top:0; }

@supports ((-webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)) or (clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)))
{ .landingMain
{ margin:-7vw 0;
  -webkit-clip-path:polygon(0 7vw,100% 0,100% calc(100% - 7vw),0 100%);
  clip-path:polygon(0 7vw,100% 0,100% calc(100% - 7vw),0 100%); }
}

@media (min-width:1440px)
{ .sectionSeparator
{ margin:-100px 0;
  -webkit-clip-path:polygon(0 100px,100% 0,100% calc(100% - 100px),0 100%);
  clip-path:polygon(0 100px,100% 0,100% calc(100% - 100px),0 100%); }
}

.product--superbuch .landingFeatures-feature-title
{ font-size:21px; }

.page-contact
{ margin-top:40px; }

@media (min-width:700px)
{ .product--superbuch
{ font-size:28px; }

.page-contact
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  margin-top:70px; }

}

.page-contact-image img
{ display:block;
  margin:0 auto;
  width:60vw;
  max-width:280px;
  border-radius:200px; }

.page-contact-content
{ margin-top:20px; }

@media (min-width:700px)
{ .page-contact-image img
{ width:25vw;
  max-width:340px; }

.page-contact-content
{ margin-top:0; }

.page-contact-image+.page-contact-content
{ margin-left:40px; }

}

.page-contact-label
{ font-size:18px;
  margin-bottom:.5em; }

@media (min-width:760px)
{ .page-contact-label
{ font-size:28px; }

}

@media (min-width:1400px)
{ .page-contact-label
{ font-size:33px; }

}

body.error .siteWrapper
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-flow:column;
  flex-flow:column; }

body.error .pageMain
{ -webkit-box-flex:1;
  -ms-flex:1;
  flex:1;
  padding-top:44px; }

body.error .page-headline
{ margin-right:auto;
  margin-left:auto;
  max-width:600px; }

body.error p a
{ color:inherit;
  font-weight:600;
  text-decoration:none; }

body.error p a:focus,body.error p a:hover
{ text-decoration:underline; }

.errorPage-image
{ max-width:800px;
  margin:40px auto 0; }

.errorPage-button
{ margin-top:40px; }

body.newsletter .siteFooter-navigation
{ margin-top:0; }

.landingPremium ul,body.newsletter .newsletter-description p,body.newsletter .newsletter-description ul,body.newsletter .newsletter-unsubscribe-teaser p,body.newsletter .newsletter-unsubscribe-teaser ul
{ font-size:inherit; }

.landingPremium ul,body.newsletter .newsletter-description ul,body.newsletter .newsletter-unsubscribe-teaser ul
{ list-style:none;
  margin:1em 0 0;
  padding:0;
  line-height:1.4em; }

.landingPremium li,body.newsletter .newsletter-description li,body.newsletter .newsletter-unsubscribe-teaser li
{ padding-left:1em;
  text-indent:-.7em; }

.landingPremium .premium-features,body.newsletter .newsletter-description
{ font-size:20px; }

body.newsletter .newsletter-unsubscribe-teaser
{ font-size:14px; }

body.newsletter .newsletter-unsubscribe-teaser a
{ color:#e50045;
  font-weight:inherit; }

body.newsletter .form
{ max-width:420px;
  margin:0 auto;
  padding:60px 0 40px; }

body.newsletter .formGroup
{ padding-bottom:10px; }

body.newsletter .formLabel
{ display:block;
  padding-left:10px; }

body.newsletter .formInput
{ border:1px solid #dedede;
  width:100%;
  text-align:left; }

body.newsletter .formSubmit
{ display:block;
  width:100%; }

body.forgotPassword .siteFooter-navigation
{ margin-top:0; }

body.forgotPassword .forgotPassword-description
{ text-align:center;
  font-size:20px; }

body.forgotPassword .forgotPassword-description p,body.forgotPassword .forgotPassword-description ul
{ font-size:inherit; }

body.forgotPassword .forgotPassword-description ul
{ list-style:none;
  margin:1em 0 0;
  padding:0;
  line-height:1.4em; }

body.forgotPassword .forgotPassword-description li
{ padding-left:1em;
  text-indent:-.7em; }

body.forgotPassword .form
{ max-width:420px;
  margin:0 auto;
  padding:60px 0 40px; }

body.forgotPassword .formGroup
{ padding-bottom:10px; }

body.forgotPassword .formLabel
{ display:block;
  padding-left:10px; }

body.forgotPassword .formInput
{ border:1px solid #dedede;
  width:100%;
  text-align:left; }

.appDownload-headline,.landingFeatures-feature,.landingSection-title
{ text-align:center; }

body.forgotPassword .formSubmit
{ display:block;
  width:100%; }

body.appCrossSelling .siteFooter-navigation
{ margin-top:0; }

.appDownload-items
{ margin-top:3em; }

.appDownload-item+.appDownload-item
{ margin-top:2em; }

.appDownload-apps
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  margin:1em -10px 0; }

.appDownload-app
{ display:block;
  padding:10px;
  text-decoration:none; }

.appDownload-app img
{ display:block; }

@media (min-width:760px)
{ .appDownload-app
{ padding:20px; }

}

.landingTeaser
{ padding:7vw 0;
  position:relative;
  background-color:#ca1524; }

@media (min-width:1440px)
{ .landingTeaser
{ padding:100px 0; }

}

.landingTeaser-container
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-flow:column;
  flex-flow:column;
  padding-right:40px;
  padding-left:40px;
  max-width:1440px;
  margin-right:auto;
  margin-left:auto; }

.landingTeaser-image img
{ display:block;
  margin:0 auto; }

.landingTeaser-main
{ margin-top:30px; }

.landingTeaser-title
{ font-size:30px; }

@media (min-width:760px)
{ .landingTeaser-container
{ padding-right:calc(7.8125vw - 19.38px);
  padding-left:calc(7.8125vw - 19.38px); }

.landingTeaser-title
{ font-size:calc(4.0625vw - .88px); }

}

@media (min-width:1400px)
{ .landingTeaser-container
{ padding-right:90px;
  padding-left:90px; }

.landingTeaser-title
{ font-size:56px; }

}

.landingTeaser-text
{ margin-top:.6em;
  font-size:18px;
  font-weight:500; }

@media (min-width:760px)
{ .landingTeaser-text
{ font-size:calc(3.18181818vw - 6.18px); }

}

@media (min-width:1200px)
{ .landingTeaser-text
{ font-size:32px; }

}

.landingTeaser-button
{ margin-top:1.5em; }

@media (min-width:760px)
{ .landingTeaser-container
{ -webkit-box-orient:horizontal;
  -webkit-box-direction:normal;
  -ms-flex-flow:row;
  flex-flow:row;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center; }

.landingTeaser-main
{ margin-top:-30px;
  margin-left:60px; }

.landingTeaser-image
{ -ms-flex-negative:0;
  flex-shrink:0; }

}

.landingBenefits-group,.landingBenefits-item
{ -webkit-box-orient:vertical;
  -webkit-box-direction:normal; }

@media (min-width:1400px)
{ .landingTeaser-content
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:end;
  -ms-flex-align:end;
  align-items:flex-end; }

.landingTeaser-button
{ -webkit-box-flex:0;
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  margin-left:20px; }

}

@supports ((-webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)) or (clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)))
{ .landingTeaser
{ padding:calc(7vw + 20px) 0;
  margin:-7vw 0;
  -webkit-clip-path:polygon(0 7vw,100% 0,100% calc(100% - 7vw),0 100%);
  clip-path:polygon(0 7vw,100% 0,100% calc(100% - 7vw),0 100%); }

.landingTeaser-main
{ -webkit-transform:rotate(-4deg);
  transform:rotate(-4deg); }
}

@media (min-width:1440px)
{ .landingTeaser
{ padding:120px 0;
  margin:-100px 0;
  -webkit-clip-path:polygon(0 100px,100% 0,100% calc(100% - 100px),0 100%);
  clip-path:polygon(0 100px,100% 0,100% calc(100% - 100px),0 100%); }
}

.siteMain-subnavigation
{ padding-top:calc(7vw + 40px);
  background-color:#fff;
  background-position:bottom; }

.landing .siteSection--colored,.product--tigerbooks .siteSection--colored
{ background-color:#ca1524; }

.siteMain-subnavigation a
{ color:inherit;
  text-decoration:none; }

.landing .siteSection--base em,.landing .siteSection--base strong,.product--tigerbooks .siteSection--base em,.product--tigerbooks .siteSection--base strong
{ color:#ca1524; }

.siteMain-subnavigation-container
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  max-width:1440px;
  padding-right:20px;
  padding-left:20px;
  margin:0 auto; }

@media (min-width:300px)
{ .siteMain-subnavigation-container
{ padding-right:calc(6.52173913vw + .43px);
  padding-left:calc(6.52173913vw + .43px); }

}

@media (min-width:760px)
{ .siteMain-subnavigation-container
{ padding-right:calc(6.25vw + 2.5px);
  padding-left:calc(6.25vw + 2.5px); }

}

@media (min-width:1400px)
{ .siteMain-subnavigation-container
{ padding-right:90px;
  padding-left:90px; }

}

.siteMain-subnavigation .landingPremium-link,.siteMain-subnavigation .landingSubnavigation-link
{ display:block;
  padding:5px 15px;
  font-size:19px; }

@media (min-width:730px)
{ .siteMain-subnavigation .landingPremium-link,.siteMain-subnavigation .landingSubnavigation-link
{ font-size:calc(.78125vw + 13.06px); }

.siteMain-subnavigation-container
{ -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between; }

}

@media (min-width:1400px)
{ .siteMain-subnavigation .landingPremium-link,.siteMain-subnavigation .landingSubnavigation-link
{ font-size:24px; }

}

.siteMain-subnavigation .landingPremium-link:after,.siteMain-subnavigation .landingSubnavigation-link:after
{ right:15px!important;
  left:15px!important; }

@media (min-width:1440px)
{ .siteMain-subnavigation
{ padding-top:140px; }

}

.landingSection-title
{ font-size:30px;
  line-height:1.1; }

@media (min-width:340px)
{ .landingSection-title
{ font-size:calc(5.55555556vw + 11.11px); }

}

@media (min-width:700px)
{ .landingSection-title
{ font-size:calc(.71428571vw + 45px); }

}

@media (min-width:1400px)
{ .landingSection-title
{ font-size:55px; }

}

.landingSection-subtitle
{ font-size:22px;
  text-align:center; }

@media (min-width:700px)
{ .landingSection-subtitle
{ font-size:calc(1.57142857vw + 11px); }

}

@media (min-width:1400px)
{ .landingSection-subtitle
{ font-size:33px; }

}

*+.landingSection-subtitle
{ margin-top:1.5em; }

.product--tigertab .siteSection--colored
{ background-color:#00abed; }

.product--tigertab .siteSection--base em,.product--tigertab .siteSection--base strong
{ color:#00abed; }

.product--tigertones .siteSection--colored,.product--tigercards .siteSection--colored,.product--tigerticket .siteSection--colored
{ background-color:#f6a809; }

.product--tigertones .siteSection--base em,.product--tigertones .siteSection--base strong,.product--tigercards .siteSection--base em,.product--tigercards .siteSection--base strong,.product--tigerticket .siteSection--base em,.product--tigerticket .siteSection--base strong
{ color:#f6a809; }

.product--tigerbox .siteSection--colored, .product--tigerbox-touch .siteSection--colored
{ background-color:#0a917c; }

.product--tigerbox .siteSection--base em,.product--tigerbox .siteSection--base strong,.product--tigerbox-touch .siteSection--base em,.product--tigerbox-touch .siteSection--base strong
{ color:#0a917c; }

.product--superbuch .siteSection--colored
{ background-color:#4195a4; }

.product--superbuch .siteSection--base em,.product--superbuch .siteSection--base strong
{ color:#e50045; }

.landingSection-title+.landingSection-content
{ margin-top:1.2em; }

@supports ((-webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)) or (clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)))
{ .landingSections .siteSection:first-child
{ margin-top:0;
  -webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%);
  clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%); }

.product .landingSections .siteSection:first-child
{ padding-top:0; }
}

@media (min-width:1440px)
{ .landingSections .siteSection:first-child
{ margin-top:0;
  -webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 100px),0 100%);
  clip-path:polygon(0 0,100% 0,100% calc(100% - 100px),0 100%); }

.product .landingSections .siteSection:first-child
{ padding-top:0; }
}

.landingIntroduction .landingSection-content
{ font-size:16px;
  line-height:1.3; }

@media (min-width:340px)
{ .landingIntroduction .landingSection-content
{ font-size:calc(1.94444444vw + 9.39px); }

}

@media (min-width:700px)
{ .landingIntroduction .landingSection-content
{ font-size:calc(1.42857143vw + 13px); }

}

@media (min-width:1400px)
{ .landingIntroduction .landingSection-content
{ font-size:33px; }

}

.landingIntroduction .landingSection-content .responsive-oembed-wrapper
{ margin-right:-20px;
  margin-left:-20px;
  min-width:100vw;
  margin-top:1.2em; }

@media (min-width:300px)
{ .landingIntroduction .landingSection-content .responsive-oembed-wrapper
{ margin-right:calc(-6.52173913vw - .43px);
  margin-left:calc(-6.52173913vw - .43px); }

}

@media (min-width:760px)
{ .landingIntroduction .landingSection-content .responsive-oembed-wrapper
{ margin-right:calc(5000vw - 38050px);
  margin-left:calc(5000vw - 38050px); }

}

@media (min-width:761px)
{ .landingIntroduction .landingSection-content .responsive-oembed-wrapper
{ margin-right:0;
  margin-left:0;
  min-width:0; }

}

.landingIntroduction a,.landingIntroduction em,.landingIntroduction strong
{ font-weight:inherit;
  color:#e50045; }

.landing--tigerbooks .landingIntroduction a,.landing--tigerbooks .landingIntroduction em,.landing--tigerbooks .landingIntroduction strong
{ color:#ca1524; }

.landing--tigerbox .landingIntroduction a,.landing--tigerbox .landingIntroduction em,.landing--tigerbox .landingIntroduction strong
{ color:#0a917c; }

.landing--tigertones .landingIntroduction a,.landing--tigertones .landingIntroduction em,.landing--tigertones .landingIntroduction strong
{ color:#f6a809; }

.landing--tigertab .landingIntroduction a,.landing--tigertab .landingIntroduction em,.landing--tigertab .landingIntroduction strong
{ color:#00abed; }

.landingFeatures .landingSection-title
{ margin-bottom:40px; }

.landingFeatures .siteSection-container
{ padding-bottom:40px; }

.landingFeatures-feature:not(:first-child)
{ margin-top:40px; }

.landingFeatures-feature-title
{ margin-bottom:20px;
  margin-right:auto;
  margin-left:auto;
  max-width:480px;
  font-size:28px;
  font-weight:500;
  line-height:1.35;
  text-align: center; }

.landingFeatures-feature-text
{ margin-right:auto;
  margin-left:auto;
  max-width:480px;
  font-size:18px;
  line-height:1.5; 
  text-align: center; }

@media (min-width:700px)
{ .landingFeatures-feature-title
{ margin-bottom:10px; }

}

@media (min-width:1100px)
{ .landingFeatures-features
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-pack:distribute;
  justify-content:space-around;
  margin:0 -25px; }

.landingFeatures-feature
{ max-width:340px;
  padding:0 25px;
  text-align:left; }

.landingFeatures-feature:not(:first-child)
{ margin-top:0; }

.landingFeatures-feature-title
{ margin-bottom:20px; }

}

.landingHeroes-heroes
{ -ms-flex-wrap:wrap;
  flex-wrap:wrap; }

.landingHeroes-hero,.landingHeroes-heroes
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex; }

.landingHeroes-hero
{ -webkit-box-flex:0;
  -ms-flex:0 1 auto;
  flex:0 1 auto;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  padding:20px; }

.landingHeroes-hero-image
{ display:block; }

@media (min-width:600px)
{ .landingHeroes-hero
{ width:33.333%;
  padding:30px; }

}

.landingDownload p
{ margin-top:1em;
  font-size:18px;
  text-align:center; }

.landingDownload p b,.landingDownload p i,.landingDownload p strong
{ color:inherit!important; }

.landingDownload p i
{ font-weight:inherit;
  font-style:italic; }

.landingDownload p em
{ font-weight:inherit;
  font-style:inherit; }

.landingDownload p a
{ color:inherit; }

.landingDownload-links
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  margin:2em -10px 0; }

.landingDownload-link
{ display:block;
  padding:10px;
  text-decoration:none; }

.landingDownload-link img
{ display:block; }

@media (min-width:760px)
{ .landingDownload p
{ font-size:33px; }

.landingDownload-link
{ padding:20px; }

}

.landingPartners .landingSection-content
{ font-size:22px;
  line-height:1.3; }

@media (min-width:700px)
{ .landingPartners .landingSection-content
{ font-size:calc(1.57142857vw + 11px); }

}

@media (min-width:1400px)
{ .landingPartners .landingSection-content
{ font-size:33px; }

}

.landingPartners-partners
{ margin-top:1.5em; }

.landingPartners-partner:not(:first-child)
{ margin-top:3em; }

.landingPartners-partner-logo
{ padding:0 20px; }

.landingPartners-partner-logo img
{ display:block;
  margin:0 auto; }

.landingPartners-partner-text
{ margin-top:1em; }

.landingPartners-partner-apps
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  max-width:430px;
  margin:30px auto 0;
  padding:0;
  list-style:none; }

.landingPartners-partner-app
{ -webkit-box-flex:1;
  -ms-flex:1 1 33%;
  flex:1 1 33%;
  padding:15px 10px; }

.landingPartners-partner-app img
{ display:block;
  border:0;
  outline:0; }

@media (min-width:900px)
{ .landingHeroes-hero
{ width:25%; }

.landingPartners-partner-apps
{ max-width:none; }

.landingPartners-partner-app
{ -webkit-box-flex:0;
  -ms-flex:0 1 auto;
  flex:0 1 auto; }

}

.landingPartners a,.landingPartners em,.landingPartners strong
{ font-weight:inherit; }

.product--tigerbooks .landingPartners a,.product--tigerbooks .landingPartners em,.product--tigerbooks .landingPartners strong
{ color:#ca1524; }

.product--tigerbox .landingPartners a,.product--tigerbox .landingPartners em,.product--tigerbox .landingPartners strong,.product--tigerbox-touch .landingPartners a,.product--tigerbox-touch .landingPartners em,.product--tigerbox-touch .landingPartners strong
{ color:#0a917c; }

.product--tigertones .landingPartners a,.product--tigertones .landingPartners em,.product--tigertones .landingPartners strong,.product--tigercards .landingPartners a,.product--tigercards .landingPartners em,.product--tigercards .landingPartners strong,.product--tigerticket .landingPartners a,.product--tigerticket .landingPartners em,.product--tigerticket .landingPartners strong
{ color:#f6a809; }

.product--tigertab .landingPartners a,.product--tigertab .landingPartners em,.product--tigertab .landingPartners strong
{ color:#00abed; }

.landingBenefits .landingSection-content
{ font-size:22px;
  line-height:1.3; }

@media (min-width:700px)
{ .landingBenefits .landingSection-content
{ font-size:calc(1.57142857vw + 11px); }

}

@media (min-width:1400px)
{ .landingBenefits .landingSection-content
{ font-size:33px; }

}

.landingBenefits-group
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-flow:column;
  flex-flow:column; }

.landingBenefits-group:not(:first-child)
{ margin-top:80px; }

.landingBenefits-desktop-image,.landingBenefits-desktop-text,.landingBenefits-tablet-image,.landingBenefits-tablet-text
{ display:none; }

.landingBenefits-group-title
{ text-align:center;
  margin-bottom:30px;
  font-size:30px; }

.landingBenefits-item
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-flow:column;
  flex-flow:column;
  margin-top:50px;
  cursor:pointer; }

.landingBenefits-item:first-child
{ margin-top:0; }

.landingBenefits--phone .landingBenefits-item
{ display:block;
  max-width:500px;
  margin-right:auto;
  margin-left:auto; }

.landingBenefits-item-title
{ font-size:16px;
  font-weight:700; }

.landingBenefits--tablet .landingBenefits-item-title
{ text-align:center; }

.landingBenefits-item-text
{ font-size:14px;
  line-height:1.35; }

.landingBenefits--phone .landingBenefits-item-text,.landingBenefits--tigerbox .landingBenefits-item-text
{ margin-top:.8em; }

@supports (display:grid){
	.landingBenefits--phone .landingBenefits-item{
		display:grid;
		grid-template-columns:1fr 1fr;
		grid-template-rows:auto 1fr auto;
		grid-template-areas:"phone title" "phone none" "phone text";
		grid-column-gap:20px;
	}
	.landingBenefits--phone .landingBenefits-item:nth-child(2n){
		grid-template-areas:"title phone" "none phone" "text phone";
	}
	.landingBenefits--phone .landingBenefits-item-title{
		grid-area:title;
	}
	.landingBenefits--phone .landingBenefits-item-text{
		grid-area:text;
		margin-top:0;
	}
}
.landingBenefits--tablet .landingBenefits-item-image{
	margin:14px -50px 0;
}
.landingBenefits--tigerbox .landingBenefits-item-image{
	-webkit-box-ordinal-group:0;
	-ms-flex-order:-1;
	order:-1;
}
.landingBenefits--phone .landingBenefits-item-image{
	margin:14px 0 0;
	width:60vw;
	max-width:320px;
}
@supports (display:grid){
	.landingBenefits--phone .landingBenefits-item-image{
		grid-area:phone;
		width:auto;
		max-width:none;
		margin:0;
	}
}
@media (min-width:760px){
	.landingBenefits-groups{
		max-width:900px;
		margin-right:auto;
		margin-left:auto;
	}
	.landingBenefits-group-title{
		text-align:left;
		font-size:46px;
	}
	.landingBenefits-tablet-image,.landingBenefits-tablet-text{
		display:block;
	}
	.landingBenefits-item{
		display:block;
		margin-top:0;
	}
	.landingBenefits-item-title{
		display:inline-block;
		padding:8px 28px;
		border-radius:20px;
		font-weight:400;
	}
	.landingBenefits-item-title:hover{
		background-color:hsla(0,0%,100%,.2);
		font-weight:700;
		}
	.is-active .landingBenefits-item-title{
		background-color:#fff;
		font-weight:700;
		color:#ca1524;
	}
	.landingBenefits-item-title:after{
		content:"\BB";
	}
	.landingBenefits-item-title:before{
		display:block;
		content:attr(title);
		font-weight:700;
		height:1px;
		color:transparent;
		overflow:hidden;
		visibility:hidden;
		margin-bottom:-1px;
	}
	.landingBenefits-item-image,.landingBenefits-item-text{
		display:none;
	}
	.landingBenefits--tablet{
		padding-right:0;
		padding-left:0;
		padding-bottom:0;
		z-index:5;
	}
}
.landingSuperDetails-image:before,.landingTabDetails-image:before,.landingTabVendors-image:before{
	background-image:url(./../../../assets/images/BackgroundPattern-Color@2x.png);
	background-size:1441px 810px;
	content:"";
}
@media (min-width:760px) and (min-width:1400px){
	.landingBenefits--tablet{
		padding-top:50px;
	}
}
@media (min-width:760px){
	@supports ((-webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)) or (clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%))){
		.landingBenefits--tablet{
			-webkit-clip-path:polygon(0 7vw,100% 0,100% 100%,0 100%);
			clip-path:polygon(0 7vw,100% 0,100% 100%,0 100%);
		}
		@media (min-width:1400px){
			.landingBenefits--tablet{
				-webkit-clip-path:polygon(0 98px,100% 0,100% 100%,0 100%);
				clip-path:polygon(0 98px,100% 0,100% 100%,0 100%);
				padding-top:100px;
			}
		}
	}
	.landingBenefits--tablet .landingBenefits-group{
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-ms-flex-flow:row;
		flex-flow:row;
		-ms-flex-wrap:wrap;
		flex-wrap:wrap;
		-webkit-box-pack:justify;
		-ms-flex-pack:justify;
		justify-content:space-between
	}
	.landingBenefits--tablet .landingBenefits-group-title{
		-webkit-box-ordinal-group:1;
		-ms-flex-order:0;
		order:0;
		-webkit-box-flex:0;
		-ms-flex:0 0 100%;
		flex:0 0 100%;
	}
	.landingBenefits--tablet .landingBenefits-items{
		-webkit-box-ordinal-group:2;
		-ms-flex-order:1;
		order:1;
	}
	.landingBenefits--tablet .landingBenefits-tablet-image{
		z-index:1;
		-webkit-box-ordinal-group:4;
		-ms-flex-order:3;
		order:3;
		margin:40px auto 0;
	}
	.landingBenefits--tablet .landingBenefits-group:last-child .landingBenefits-tablet-image{
		margin-bottom:-280px;
	}
	.landingBenefits--tablet:after{
		position:relative;
		display:none;
		width:100%;
		height:200px;
		background-color:#fff;
		background-image:url(./../../../assets/images/BackgroundPattern-Color@2x.png);
		background-position:top;
		background-size:1441px 810px;
		-webkit-clip-path:polygon(0 7vw,100% 0,100% 100%,0 100%);
		clip-path:polygon(0 7vw,100% 0,100% 100%,0 100%);
		content:"";
	}
	@supports ((-webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)) or (clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%))){
		.landingBenefits--tablet:after{
			display:block;
		}
	}
	.landingBenefits--tablet .landingBenefits-tablet-text{
		-webkit-box-ordinal-group:3;
		-ms-flex-order:2;
		order:2;
		-ms-flex-preferred-size:250px;
		flex-basis:250px;
	}
	.landingBenefits--tablet .landingBenefits-item-title{
		text-align:left;
	}
	.landingBenefits--tigerbox .landingBenefits-groups{
		margin-top:-40px;
	}
	.landingBenefits--tigerbox .landingBenefits-group{
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-ms-flex-flow:row;
		flex-flow:row;
		-ms-flex-wrap:wrap;
		flex-wrap:wrap;
	}
	.landingBenefits--tigerbox .landingBenefits-group-title{
		-webkit-box-flex:0;
		-ms-flex:0 0 100%;
		flex:0 0 100%;
		padding-right:60%;
	}
	.landingBenefits--tigerbox .landingBenefits-items{
		-webkit-box-flex:0;
		-ms-flex:0 0 40%;
		flex:0 0 40%;
	}
	.landingBenefits--tigerbox .landingBenefits-tablet-image{
		-webkit-box-flex:0;
		-ms-flex:0 0 60%;
		flex:0 0 60%;
		margin-top:-160px;
		margin-bottom:-40px;
	}
	.landingBenefits--tigerbox .landingBenefits-tablet-text{
		margin-top:40px;
		padding-left:27px;
	}
	.landingBenefits--phone .siteSection-container{
		padding-bottom:40px;
	}
	.landingBenefits--phone .landingBenefits-group{
		display:block;
		overflow:hidden;
		max-width:800px;
	}
	@supports (display:grid){
		.landingBenefits--phone .landingBenefits-group{
			display:grid;
			grid-template-columns:1fr 260px;
			grid-template-rows:auto auto 1fr auto;
			grid-column-gap:40px;
			grid-template-areas:"title phone" "items phone" "none phone" "text phone";
		}
		.landingBenefits--phone .landingBenefits-group:nth-child(2n){
			grid-template-columns:260px 1fr;
			grid-template-areas:"phone title" "phone items" "phone none" "phone text";
		}
	}
	.landingBenefits--phone .landingBenefits-tablet-text{
		padding-left:28px;
		margin-top:50px;
	}
	.landingBenefits--phone .landingBenefits-items,.landingBenefits--phone .landingBenefits-tablet-text{
		float:left;
		width:calc(100% - 300px);
	}
	.landingBenefits--phone .landingBenefits-tablet-image{
		float:right;
		width:260px
	}
	.landingBenefits--phone .landingBenefits-item{
		display:block;
		max-width:none;
		margin-right:0;
		margin-left:0;
	}
	@supports (display:grid){
		.landingBenefits--phone .landingBenefits-group-title{
			grid-area:title;
		}
		.landingBenefits--phone .landingBenefits-items{
			grid-area:items;
			float:none;
			width:auto;
		}
		.landingBenefits--phone .landingBenefits-tablet-text{
			grid-area:text;
			float:none;
			width:auto;
		}
		.landingBenefits--phone .landingBenefits-tablet-image{
			grid-area:phone;
			float:none;
			width:auto;
		}
		.landingBenefits--phone .landingBenefits-item{
			grid-template-columns:auto;
			grid-template-rows:auto;
		}
		.landingBenefits--phone .landingBenefits-item-image,.landingBenefits--phone .landingBenefits-item-text,.landingBenefits--phone .landingBenefits-item-title{
			grid-area:unset;
		}
	}
}

@media (min-width:760px) and (min-width:1400px)
{ .landingBenefits--tablet:after
{ -webkit-clip-path:polygon(0 98px,100% 0,100% 100%,0 100%);
  clip-path:polygon(0 98px,100% 0,100% 100%,0 100%); }

}

.landingBenefits-tablet-text
{ font-size:16px; }

.landingBenefits--tablet .landingBenefits-tablet-text
{ font-size:14px; }

.landingBenefits-desktop-text
{ font-size:18px; }

.landingBenefits--tablet .landingBenefits-desktop-text
{ font-size:16px; }

@media (min-width:1400px)
{ .landingBenefits-groups
{ max-width:none; }

.landingBenefits-tablet-image,.landingBenefits-tablet-text
{ display:none; }

.landingBenefits-item-title
{ font-size:18px; }

.landingBenefits-desktop-image,.landingBenefits-desktop-text
{ display:block; }

.is-active .landingBenefits-item-title
{ background-color:transparent;
  font-weight:400;
  color:#fff; }

.is-active-on-desktop .landingBenefits-item-title
{ background-color:#fff;
  font-weight:700;
  color:#ca1524; }

@supports ((-webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)) or (clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)))
{ .landingBenefits--tablet
{ margin-bottom:-160px; }

}

.landingBenefits--tablet .landingSection-content
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap; }

.landingBenefits--tablet .landingBenefits-groups
{ -webkit-box-ordinal-group:2;
  -ms-flex-order:1;
  order:1;
  -webkit-box-flex:0;
  -ms-flex:0 0 40%;
  flex:0 0 40%;
  margin:0; }

.landingBenefits--tablet .landingBenefits-desktop-image
{ z-index:1;
  -webkit-box-ordinal-group:3;
  -ms-flex-order:2;
  order:2;
  -webkit-box-flex:0;
  -ms-flex:0 0 60%;
  flex:0 0 60%; }

.landingBenefits--tablet .landingBenefits-desktop-image img
{ position:absolute; }

.landingBenefits--tablet .landingBenefits-desktop-text
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:end;
  -ms-flex-pack:end;
  justify-content:flex-end;
  -webkit-box-ordinal-group:1;
  -ms-flex-order:0;
  order:0;
  width:100%;
  margin-top:-100px;
  margin-bottom:30px; }

.landingBenefits--tablet .landingBenefits-desktop-text .landingBenefits-desktop-text-inner
{ max-width:550px; }

.landingBenefits--tigerbox .landingBenefits-groups
{ margin-top:0; }

.landingBenefits--tigerbox .landingBenefits-group
{ -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-flow:column;
  flex-flow:column;
  padding-right:55%; }

.landingBenefits--tigerbox .landingBenefits-group-title
{ -webkit-box-flex:1;
  -ms-flex:auto;
  flex:auto;
  padding-right:0; }

.landingBenefits--tigerbox .landingBenefits-items
{ -webkit-box-flex:1;
  -ms-flex:auto;
  flex:auto; }

.landingBenefits--tigerbox .landingBenefits-desktop-image
{ position:absolute;
  left:50%;
  top:0; }

.landingBenefits--tigerbox .landingBenefits-desktop-text
{ margin-top:60px;
  padding-left:27px;
  padding-right:55%; }

.landingBenefits--phone
{ padding-top:50px;
  padding-bottom:50px; }

@supports ((-webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)) or (clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)))
{ .landingBenefits--phone
{ padding-top:100px;
  padding-bottom:100px; }

}

.landingBenefits--phone .landingBenefits-group
{ display:block;
  grid-template-columns:auto;
  grid-template-rows:auto;
  grid-column-gap:0;
  max-width:none;
  white-space:nowrap; }

.landingBenefits--phone .landingBenefits-group:nth-child(2n)
{ grid-template-columns:auto; }

.landingBenefits--phone .landingBenefits-group:not(:first-child)
{ margin-top:40px; }

.landingBenefits--phone .landingBenefits-group-title
{ grid-area:unset;
  margin-bottom:10px; }

.landingBenefits--phone .landingBenefits-items
{ grid-area:unset; }

.landingBenefits--phone .landingSection-content
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center; }

.landingBenefits--phone .landingBenefits-desktop-text,.landingBenefits--phone .landingBenefits-groups
{ -webkit-box-flex:1;
  -ms-flex:1 1 auto;
  flex:1 1 auto; }

.landingBenefits--phone .landingBenefits-desktop-image
{ -webkit-box-flex:0;
  -ms-flex:0 0 398px;
  flex:0 0 398px;
  margin:-110px 80px; }

}

.landingVendors-vendors
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  margin-top:2.5em;
  margin-right:calc(-6.52173913vw + 5px);
  margin-left:calc(-6.52173913vw + 5px); }

@media (min-width:500px)
{ .landingVendors-vendors
{ margin:2.5em auto 0;
  max-width:1260px; }

}

.landingVendors-vendor
{ -webkit-box-flex:0;
  -ms-flex:0 1 auto;
  flex:0 1 auto;
  width:50%;
  padding:5px; }

.landingVendors-vendor img
{ display:block;
  width:100%; }

@media (min-width:760px)
{ .landingVendors-vendor
{ width:33.333%; }

}

@media (min-width:900px)
{ .landingVendors-vendor
{ width:25%;
  padding:10px; }

}

.landingTabDetails-image:before,.landingTabVendors-image:before
{ width:100%;
  background-position:top; }

.landingTabDetails,.landingTabVendors
{ padding-top:0;
  padding-right:0;
  padding-left:0;
  z-index:2; }

@media (min-width:1000px)
{ .landingVendors-details
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between; }

.landingVendors-detail
{ -webkit-box-flex:0;
  -ms-flex:0 1 44%;
  flex:0 1 44%;
  text-align:left; }

}

.landingTabDetails
{ background-color:#00abed; }

.landingTabDetails-image:before
{ display:block;
  height:20vw;
  margin-top:-1px;
  background-color:#fff; }

.landingTabDetails-image img
{ position:relative;
  display:block;
  margin:-20vw auto 0; }

.landingTabDetails-detail
{ margin-top:1.5em;
  text-align:center;
  font-size:16px; }

@media (min-width:320px)
{ .landingTabDetails-detail
{ font-size:calc(2.72727273vw + 7.27px); }

}

@media (min-width:760px)
{ .landingTabDetails-detail
{ font-size:calc(.78125vw + 22.06px); }

}

@media (min-width:1400px)
{ .landingTabDetails-detail
{ font-size:33px; }

}

@media (min-width:1000px)
{ .landingTabDetails-image:before
{ height:280px; }

.landingTabDetails-image img
{ margin-top:-280px; }

.landingTabDetails-details
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between; }

.landingTabDetails-detail
{ -webkit-box-flex:0;
  -ms-flex:0 1 44%;
  flex:0 1 44%;
  text-align:left; }

}

@supports ((-webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)) or (clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)))
{ .landingTabDetails-image:before
{ height:40vw;
  -webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%);
  clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%); }

.landingTabDetails-image img
{ margin-top:-40vw; }
}

@media (min-width:1400px)
{ .landingTabDetails-image:before
{ height:576px;
  -webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 98px),0 100%);
  clip-path:polygon(0 0,100% 0,100% calc(100% - 98px),0 100%); }

.landingTabDetails-image img
{ margin-top:-560px; }
}

.landingTabVendors
{ background-color:#00abed; }

.landingTabVendors-image:before
{ display:block;
  height:20vw;
  margin-top:-1px;
  background-color:#fff; }

.landingTabVendors-image img
{ position:relative;
  display:block;
  margin:-20vw auto 0; }

.landingTabVendors-vendors
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  margin-top:2.5em;
  margin-right:calc(-6.52173913vw + 5px);
  margin-left:calc(-6.52173913vw + 5px); }

@media (min-width:500px)
{ .landingTabVendors-vendors
{ margin:2.5em auto 0;
  max-width:1260px; }

}

.landingTabVendors-vendor
{ -webkit-box-flex:0;
  -ms-flex:0 1 auto;
  flex:0 1 auto;
  width:50%;
  padding:5px; }

.landingTabVendors-vendor img
{ display:block;
  width:100%; }

@media (min-width:760px)
{ .landingTabVendors-vendor
{ width:33.333%; }

}

@media (min-width:900px)
{ .landingTabVendors-vendor
{ width:25%;
  padding:10px; }

}

@media (min-width:1000px)
{ .landingTabVendors-image:before
{ height:280px; }

.landingTabVendors-image img
{ margin-top:-280px; }

.landingTabVendors-details
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between; }

.landingTabVendors-detail
{ -webkit-box-flex:0;
  -ms-flex:0 1 44%;
  flex:0 1 44%;
  text-align:left; }

}

@supports ((-webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)) or (clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)))
{ .landingTabVendors-image:before
{ height:40vw;
  -webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%);
  clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%); }

.landingTabVendors-image img
{ margin-top:-40vw; }
}

@media (min-width:1400px)
{ .landingTabVendors-image:before
{ height:576px;
  -webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 98px),0 100%);
  clip-path:polygon(0 0,100% 0,100% calc(100% - 98px),0 100%); }

.landingTabVendors-image img
{ margin-top:-560px; }
}

.landingSuperDetails
{ z-index:2;
  padding-top:0; }

@media (max-width:1099px)
{ .landingSuperDetails .siteSection-container
{ padding-top:0; }

}

.landingSuperDetails .siteSection-content
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-flow:column;
  flex-flow:column; }

.landingSuperDetails-image
{ -webkit-box-ordinal-group:0;
  -ms-flex-order:-1;
  order:-1;
  margin-bottom:30px; }

.landingSuperDetails-image:before
{ display:block;
  height:30vw;
  margin-top:-1px;
  width:100vw;
  position:relative;
  left:50%;
  -webkit-transform:translateX(-50%);
  transform:translateX(-50%);
  background-color:#fff;
  background-position:top; }

.landingSuperDetails-image img
{ position:relative;
  display:block;
  margin:-30vw auto 0; }

@media (min-width:1100px)
{ .landingSuperDetails,.landingSuperDetails .siteSection-container
{ padding-bottom:0; }

.landingSuperDetails-image
{ margin:0;
  -webkit-box-ordinal-group:1;
  -ms-flex-order:0;
  order:0; }

.landingSuperDetails-image:before
{ margin-top:60px;
  height:200px; }

.landingSuperDetails-image img
{ position:absolute;
  bottom:50px;
  left:50%;
  margin:0; }

}

.landingSuperDetails .landingSection-title
{ position:relative;
  z-index:5; }

.landingSuperDetails .landingSection-subtitle
{ font-size:18px;
  text-align:left; }

@media (min-width:320px)
{ .landingSuperDetails .landingSection-subtitle
{ font-size:calc(2.27272727vw + 10.73px); }

}

@media (min-width:760px)
{ .landingSuperDetails .landingSection-subtitle
{ font-size:calc(.78125vw + 22.06px); }

}

@media (min-width:1400px)
{ .landingSuperDetails .landingSection-subtitle
{ font-size:33px; }

}

.landingSuperDetails-details
{ margin-top:1.5em;
  font-size:24px;
  line-height:1.8;
  font-weight:500; }

@media (min-width:320px)
{ .landingSuperDetails-details
{ font-size:calc(2.04545455vw + 17.45px); }

}

@media (min-width:760px)
{ .landingSuperDetails-details
{ font-size:calc(.625vw + 28.25px); }

}

@media (min-width:1400px)
{ .landingSuperDetails-details
{ font-size:37px; }

}

@media (min-width:1100px)
{ .landingSuperDetails-details
{ margin-top:3em; }

}

@supports ((-webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)) or (clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%)))
{ .landingSuperDetails-image:before
{ height:250px;
  -webkit-clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%);
  clip-path:polygon(0 0,100% 0,100% calc(100% - 7vw),0 100%); }

.landingSuperDetails-image img
{ margin-top:-250px; }
}

@media (min-width:700px)
{ .landingSuperDetails-image:before
{ height:400px; }

.landingSuperDetails-image img
{ margin-top:-400px; }

}

@media (min-width:1100px)
{ .landingSuperDetails
{ padding-top:7vw; }

.landingSuperDetails,.landingSuperDetails-image:before
{ -webkit-clip-path:polygon(0 7vw,100% 0,100% 100%,0 100%);
  clip-path:polygon(0 7vw,100% 0,100% 100%,0 100%); }

.landingSuperDetails-image:before
{ height:300px; }

.landingSuperDetails-image img
{ bottom:100px; }

}

@media (min-width:1400px)
{ .landingSuperDetails
{ padding-top:98px; }

.landingSuperDetails,.landingSuperDetails-image:before
{ -webkit-clip-path:polygon(0 98px,100% 0,100% 100%,0 100%);
  clip-path:polygon(0 98px,100% 0,100% 100%,0 100%); }
}

.landingBoxTrenner .siteSection-content
{ text-align:center; }

.landingBoxTrenner-title
{ font-size:30px; }

@media (min-width:340px)
{ .landingBoxTrenner-title
{ font-size:calc(4.64285714vw + 14.21px); }

}

@media (min-width:900px)
{ .landingBoxTrenner-title
{ font-size:56px; }

}

.landingBoxTrenner-image img
{ display:block;
  width:100%;
  max-width:600px;
  margin:30px auto 0; }

@media (min-width:1000px)
{ .landingBoxTrenner .siteSection-content
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  margin-top:-40px;
  margin-bottom:-40px;
  text-align:left;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center; }

.landingBoxTrenner-title
{ -webkit-box-flex:1;
  -ms-flex:1;
  flex:1;
  padding-right:30px; }

.landingBoxTrenner-image
{ -webkit-box-flex:1;
  -ms-flex:1;
  flex:1;
  margin:0; }

}

.landingBoxVendors .siteSection-container
{ padding-right:0;
  padding-left:0;
  max-width:none; }

.landingBoxVendors .landingSection-title
{ padding-right:30px;
  padding-left:30px; }

.landingBoxVendors-search
{ padding:0 20px;
  margin:0 auto 40px;
  max-width:750px; }

.landingBoxVendors-search-field
{ width:100%;
  padding:10px 20px;
  border:1px solid #dedede;
  border-radius:30px;
  outline:0;
  font:500 24px inherit;
  color:#000; }

.landingBoxVendors-search-field::-webkit-input-placeholder
{ color:#ababab; }

.landingBoxVendors-search-field:-ms-input-placeholder
{ color:#ababab; }

.landingBoxVendors-search-field::placeholder
{ color:#ababab; }

.landingBoxVendors-search-field:focus
{ border-color:#e50045; }

.landingBoxVendors-geolocationLink
{ display:none;
  padding:10px;
  color:#e50045;
  text-align:center; }

.landingBoxVendors-map-container
{ position:relative; }

.landingBoxVendors-map
{ height:60vh;
  min-height:300px;
  max-height:700px; }

@media (max-width:601px)
{ .landingBoxVendors-map
{ display:none; }

}

.landingBoxVendors-vendors
{ position:relative;
  border-radius:6px;
  background-color:#fff; }

@media (min-width:600px)
{ .landingBoxVendors-vendors
{ margin:-160px auto 0;
  width:90vw;
  max-height:50vh;
  overflow:scroll; }

}

@media (min-width:1000px)
{ .landingBoxVendors-vendors
{ position:absolute;
  top:20px;
  left:20px;
  bottom:20px;
  width:400px;
  max-height:none;
  margin:0; }

}

.landingBoxVendors-vendor
{ padding:20px 10px 20px 56px;
  background-image:url(./../../../assets/images/Map-Pin@2x.png);
  background-repeat:no-repeat;
  background-position:12px 10px;
  background-size:32px 40px;
  cursor:pointer; }

.landingBoxVendors-vendor:not(:last-child)
{ border-bottom:1px solid #dedede; }

.landingBoxVendors-vendor-name
{ font-size:inherit;
  font-weight:inherit; }

.landingBoxVendors-vendor-details
{ display:none;
  padding-top:30px; }

.is-active .landingBoxVendors-vendor-details
{ display:block; }

.landingTestimonials-testimonials
{ max-width:1050px;
  margin:0 auto; }

.landingTestimonials-testimonial
{ margin:50px auto 0;
  max-width:480px; }

.landingTestimonials-testimonial-quote
{ font-size:18px;
  font-weight:500; }

@media (min-width:340px)
{ .landingTestimonials-testimonial-quote, .sofi-box p
{ font-size:calc(1.42857143vw + 13px); }
}

.landingTestimonials-testimonial-source
{ margin-top:15px;
  font-size:14px; }

@media (min-width:340px)
{ .landingTestimonials-testimonial-source
{ font-size:calc(.95238095vw + 10.76px); }

}

@media (min-width:1100px)
{ .landingTestimonials-testimonials
{ -webkit-column-count:2;
  column-count:2;
  -webkit-column-gap:160px;
  column-gap:160px; }

.landingTestimonials-testimonial
{ display:inline-block;
  margin-top:70px; }

}

.landingMasonry-items
{ max-width:618px;
  margin:0 auto;
  -webkit-column-count:2;
  column-count:2;
  -webkit-column-gap:10px;
  column-gap:10px;
  -webkit-column-fill:balance;
  column-fill:balance; }

.landingMasonry-item
{ display:inline-block;
  margin:0 auto;
  max-width:304px; }

.landingMasonry-item img
{ display:block;
  margin:0 auto; }

@media (min-width:932px)
{ .landingMasonry-items
{ max-width:932px;
  -webkit-column-count:3;
  column-count:3; }

}

@media (min-width:1246px)
{ .landingMasonry-items
{ max-width:1246px;
  -webkit-column-count:4;
  column-count:4; }

}

.press-posts
{ margin:30px auto; }

@media (min-width:760px)
{ .landingTestimonials-testimonial-source
{ font-size:18px; }

.press-posts
{ margin:45px auto; }

}

@media (min-width:1400px)
{ .press-posts
{ margin:70px auto; }

}

.press-posts-month
{ margin:20px 0 10px;
  font-size:28px;
  line-height:1.35; }

@media (min-width:760px)
{ .press-posts-month
{ margin:30px 0 10px; }

.press-post
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between; }

}

@media (min-width:1400px)
{ .press-posts-month
{ margin:50px 0 30px;
  font-size:33px; }

}

.press-post
{ margin-top:30px; }

.press-post-thumbnail
{ margin-bottom:16px; }

.press-post-date
{ color:#9b9b9b;
  font-size:18px; }

@media (min-width:760px)
{ .press-post-thumbnail
{ margin-bottom:0;
  -webkit-box-flex:0;
  -ms-flex:0 0 35%;
  flex:0 0 35%; }

.press-post-content
{ -webkit-box-flex:0;
  -ms-flex:0 0 60%;
  flex:0 0 60%; }

.press-post-date
{ font-size:24px; }

}

.press-post-title
{ margin:0 0 11px;
  font-size:30px;
  line-height:1.3; }

@media (min-width:760px)
{ .press-post-title
{ margin:0 0 17px;
  font-size:33px; }

}

@media (min-width:1400px)
{ .press-post-title
{ margin:0 0 21px;
  font-size:36px; }

}

.press-post-excerpt
{ color:#9b9b9b;
  font-size:18px; }

.press-post-excerpt p
{ font-size:inherit; }

@media (min-width:760px)
{ .press-post-excerpt
{ font-size:23px; }

}

.press-post-download
{ display:block;
  margin-top:20px;
  text-decoration:none;
  font-size:24px;
  font-weight:600;
  color:#9b9b9b; }

.companyDownload-group
{ margin-top:40px; }

@media (min-width:700px)
{ .companyDownload-group
{ margin-top:50px; }

}

@media (min-width:1400px)
{ .press-post-excerpt
{ font-size:24px; }

.companyDownload-group
{ margin-top:60px; }

}

.companyDownload-group-title
{ font-size:26px; }

@media (min-width:700px)
{ .companyDownload-group-title
{ font-size:30px; }

}

@media (min-width:1400px)
{ .companyDownload-group-title
{ font-size:33px; }

}

.companyDownload-items
{ margin-top:-10px; }

@media (min-width:500px)
{ .companyDownload-items
{ margin:30px -10px 0;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap; }

}

@media (min-width:900px)
{ .companyDownload-items
{ margin-top:15px; }

}

.companyDownload-item
{ margin-top:30px; }

@media (min-width:500px)
{ .companyDownload-item
{ margin-top:0;
  padding:10px;
  -ms-flex-preferred-size:50%;
  flex-basis:50%; }

}

@media (min-width:900px)
{ .companyDownload-item
{ margin-top:25px;
  -ms-flex-preferred-size:33.333%;
  flex-basis:33.333%; }

}

.companyDownload-item-title
{ padding-top:.8em;
  font-size:20px; }

@media (min-width:1400px)
{ .companyDownload-item-title
{ font-size:22px; }

}

.companyDownload-variants
{ padding-top:5px; }

.companyDownload-variant
{ font-size:16px;
  text-decoration:none; }

.companyDownload-variant-title
{ color:#e50045;
  text-decoration:underline; }

.companyDownload-variant-suffix
{ text-decoration:none;
  color:#404040; }

.blogger-contact
{ margin-top:40px; }

@media (min-width:700px)
{ .companyDownload-variant
{ font-size:18px; }

.blogger-contact
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  margin-top:70px; }

}

.blogger-contact-image img
{ display:block;
  margin:0 auto;
  width:60vw;
  max-width:280px;
  border-radius:200px; }

.blogger-contact-content
{ margin-top:20px; }

@media (min-width:700px)
{ .blogger-contact-image img
{ width:25vw;
  max-width:340px; }

.blogger-contact-content
{ margin-top:0; }

.blogger-contact-image+.blogger-contact-content
{ margin-left:40px; }

}

.blogger-contact-content a
{ color:#e50045; }

.blogger-contact-label
{ font-size:18px;
  margin-bottom:.5em; }

@media (min-width:760px)
{ .blogger-contact-label
{ font-size:28px; }

}

@media (min-width:1400px)
{ .blogger-contact-label
{ font-size:33px; }

}

.supportProducts-wrapper
{ margin:-30px -7px 20px; }

@media (min-width:700px)
{ .supportProducts-wrapper
{ margin:-10px 0 50px; }

}

@media (min-width:1000px)
{ .supportProducts-wrapper
{ margin:0 0 70px; }

}

.supportProducts-intro
{ margin:45px 0 50px;
  text-align:center;
  font-size:30px; }

@media (min-width:760px)
{ .supportProducts-intro
{ font-size:33px;
  margin:100px 0 30px; }

}

.supportProducts
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-pack:distribute;
  justify-content:space-around;
  max-width:750px;
  margin:0 auto; }

.supportProduct
{ padding:0 7px;
  text-decoration:none; }

.supportProduct img
{ display:block;
  max-width:100%;
  width:auto;
  margin:0 auto;
  -webkit-transition:-webkit-transform .1s;
  transition:-webkit-transform .1s;
  transition:transform .1s;
  transition:transform .1s,-webkit-transform .1s; }

.supportProduct:active img
{ -webkit-transform:scale(.95);
  transform:scale(.95); }

.supportProduct-title
{ display:block;
  padding-top:1em;
  font-size:9px;
  text-align:center;
  color:#404040; }

@media (min-width:340px)
{ .supportProduct-title
{ font-size:calc(5vw - 8px); }

}

@media (min-width:380px)
{ .supportProduct-title
{ font-size:calc(3.18181818vw - 1.09px); }

}

@media (min-width:600px)
{ .supportProduct-title
{ font-size:18px; }

}

.supportProduct.is-current img
{ -webkit-filter:grayscale(1);
  filter:grayscale(1);
  opacity:.25; }

.supportProduct.is-current .supportProduct-title
{ opacity:.5; }

.supportSearch-title
{ margin-top:40px;
  margin-bottom:10px;
  text-align:center;
  font-size:30px;
  font-weight:500; }

@media (min-width:760px)
{ .supportSearch-title
{ font-size:33px;
  font-weight:600; }

}

.supportSearchForm
{ max-width:770px;
  margin:0 auto; }

.supportSearchForm-field
{ display:block;
  width:100%;
  padding:8px 22px 9px;
  border-radius:35px;
  border:1px solid #dedede;
  outline:0;
  font:24px Mikado,sans-serif;
  text-align:center;
  color:#404040;
  -webkit-appearance:none; }

.supportCategory-post a,.supportFaq-item a
{ color:#e50045; }

.supportSearchForm button
{ display:block;
  width:100%;
  max-width:330px;
  margin:20px auto 0; }

.supportFaq-title
{ text-align:center;
  font-size:30px; }

.supportFaq-items
{ list-style:none;
  margin:20px 0 0;
  padding:0; }

@media (min-width:760px)
{ .supportFaq-title
{ font-size:33px; }

.supportFaq-items
{ margin-top:26px; }

}

.supportFaq-item
{ font-size:18px; }

.supportFaq-item:not(:first-child)
{ margin-top:10px; }

.supportCategories
{ margin-bottom:40px; }

@media (min-width:760px)
{ .supportHome .supportFaq-item
{ text-align:center;
  font-size:23px; }

.supportCategories
{ margin-bottom:50px; }

}

@media (min-width:1400px)
{ .supportCategories
{ margin-bottom:60px; }

}

.supportCategory:not(:first-child)
{ margin-top:20px; }

.supportCategory-header
{ padding:10px 5px;
  margin:0 -5px;
  -webkit-box-shadow:inset 0 -1px 0 0 #d9d9d9;
  box-shadow:inset 0 -1px 0 0 #d9d9d9; }

.supportCategory-title
{ font-size:21px; }

@media (min-width:760px)
{ .supportCategory-header
{ padding:10px 30px;
  margin:0 -30px; }

.supportCategory-title
{ font-size:26px; }

}

.supportCategory-posts
{ list-style:none;
  margin:15px 0 0;
  padding:0; }

.supportCategory-post
{ font-size:18px; }

.supportCategory-post:not(:first-child)
{ margin-top:10px; }

body.voucher .newsletterForm,body.voucher .siteFooter-title,body.voucher .siteHero,body.voucher .siteHero+.sectionSeparator
{ display:none; }

body.voucher .siteFooter-navigation
{ margin-top:0; }

body.voucher .siteWrapper
{ min-height:100vh;
  height:100vh;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-flow:column;
  flex-flow:column; }

body.voucher .siteMain,body.voucher .siteWrapper
{ display:-webkit-box;
  display:-ms-flexbox;
  display:flex; }

body.voucher .siteMain
{ -webkit-box-flex:1 0 auto;
  -ms-flex:1 0 auto;
  flex:1 0 auto;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center; }

body.voucher .siteSection
{ width:100%; }

.voucherIframe
{ max-width:650px;
  margin:0 auto; }

a.siteOverlay-product.menu-shop
{ background-color:#32b090; }

@media screen and (min-width:1024px)
{ a.siteOverlay-product.menu-shop
{ display:none; }

}

.siteheader-subnavigation
{ padding-top:9px; }

@media (min-width:930px)
{ .landingPremium-link,.landingSubnavigation-link
{ display:block; }

.landingPremium-link.is-active,.landingSubnavigation-link.is-active
{ font-weight:700;
  margin-top:-1px; }

.landingPremium-link.is-active:after,.landingSubnavigation-link.is-active:after
{ position:absolute;
  bottom:0;
  right:9px;
  left:9px;
  display:block;
  height:3px;
  background-color:#e50045;
  content:""; }

}

.siteNavigation-container
{ padding:0; }

.siteNavigation-brand,.siteNavigation-overlayToggle,.siteNavigation-siteLinks
{ top:10px!important; }

.siteLink.is-current,.siteLink:focus,.siteLink:hover
{ margin-top:0; }

.siteOverlay-product.ohrenauf:before
{ background-image:none!important; }

.redBG
{ background-color:#ca1524; }

.mh-400
{ min-height:400px!important; }

.mh-300
{ min-height:300px!important; }

.button:active
{ margin:0; }

del,ins
{ margin-right:30px; }

a.full,button.full
{ width:100%!important; }

#place_order,.button.red,button.red
{ display:block;
  width:fit-content;
  padding:10px!important;
  border-radius:35px!important;
  outline:0!important;
  border:0!important;
  -webkit-box-shadow:0;
  box-shadow:0;
  font-size:20px!important;
  background-color:#f6ccd1!important;
  text-decoration:none!important;
  color:#fff!important;
  cursor:pointer!important;
  text-align:center;
  min-width:240px; }

.button.red:hover,button.red:hover
{ transform:scale(1.05); }

.button.red span,button.red span
{ display:block;
  padding:11px 28px 10px;
  border-radius:27px;
  -webkit-box-shadow:0 1px 2px 0 rgba(0,0,0,.25);
  box-shadow:0 1px 2px 0 rgba(0,0,0,.25);
  background-color:#e50045;
  font-weight:500; }

#place_order
{ background-color:#e50045!important;
  width:100%!important; }

.button.red.small
{ font-size:15px!important;
  min-width:180px; }

a
{ color:#e50045; }

.onsale
{ font:17px MikadoBold;
  color:#fff;
  display:flex;
  background-color:#25B08F;
  border-radius:60px;
  padding:5px 15px;
  width:fit-content;
  height:fit-content; }

input#payment_method_SaferpayCw_CreditCard,input#payment_method_bacs,input#payment_method_paypal,input#payment_method_paypal_credit_card_rest,input#ship-to-different-address-checkbox,input#wc-paypal_credit_card_rest-new-payment-method
{ height:15px; }

.summary .price del
{ text-decoration:line-through;
  color:#C2C2C2!important; }

abbr.required,ins
{ text-decoration:none!important; }

ins
{ color:#25B08F; }

.price
{ display:inline-flex;
  flex-direction:row-reverse;
  font-weight:700; }

.up-sells del,.wc_prodcuts_wrapper del
{ margin-right:0;
  color:#c2c2c2;
  display:block;
  font-weight:700; }

.up-sells ins,.wc_prodcuts_wrapper ins
{ margin-right:0;
  font-size:18px;
  font-weight:700; }

.woocommerce-checkout header
{ background-color:#ca1524; }

@media screen and (max-width:849px)
{ .siteNavigation-container
{ -webkit-box-orient:horizontal;
  -webkit-box-direction:inherit;
  -ms-flex-direction:inherit;
  flex-direction:row; }

.siteNavigation-container:after,.siteNavigation-siteLinks
{ display:none; }

.overlay-is-visible .siteNavigation-siteLinks
{ display:inherit; }

}

@media screen and (max-width:760px)
{ .pageContent
{ font-size:calc(1.4vw + 11.13px); }

}

input#paypal_credit_card_rest-card-cvc,input#paypal_credit_card_rest-card-expiry,input#paypal_credit_card_rest-card-number
{ font-size:16px!important; }

.wc-credit-card-form .form-row label
{ font-size:14px; }

.payment_box>p,label.woocommerce-form__label.woocommerce-form__label-for-checkbox.checkbox
{ font-size:13px; }

.wc-terms-and-conditions.woocommerce-validated
{ margin:0 0 20px; }

.woocommerce form .form-row.woocommerce-validated input.input-text
{ border-color:#81C399; }

.select2-container--default .select2-results__option--highlighted[aria-selected],.select2-container--default .select2-results__option--highlighted[data-selected]
{ background-color:#E50045;
  color:#fff; }

.cart_totals>h2,a.showcoupon
{ color:#E50045; }

.select2-container--open .select2-dropdown--below
{ border-top:none;
  border-radius:0 0 10px 10px; }

.woocommerce-info::before
{ display:none; }

div#payment
{ background:0 0!important; }

.woocommerce .woocommerce-page
{ float:right;
  display:block;
  padding:10px 25px;
  border-radius:60px;
  -webkit-box-shadow:0 0 0 10px rgba(229,0,69,.25);
  box-shadow:0 0 0 10px rgba(229,0,69,.25);
  background-color:#E50045;
  text-align:center;
  text-transform:uppercase; }

div#order_review
{ background:#FFF;
  box-shadow:0 0 7px 4px rgba(0,0,0,.09);
  border-radius:26px;
  padding:40px;
  margin:30px 0; }

@media screen and (max-width:480px)
{ div#order_review
{ padding:20px; }

}

.select2-container--default .select2-selection--single .select2-selection__rendered
{ line-height:0;
  font-size:16px; }

.select2-container--default .select2-selection--single
{ background-color:#fff;
  border:1px solid #aaa;
  border-radius:60px; }

.woocommerce form .form-row textarea,input
{ background:#FFF;
  padding:20px;
  font:16px inherit; }

.select2-container .select2-selection--single
{ height:40px; }

.select2-container .select2-selection--single .select2-selection__rendered
{ padding:20px; }

.select2-container--default .select2-selection--single .select2-selection__arrow
{ top:7px;
  right:10px; }

.woocommerce form .form-row textarea
{ line-height:1.5;
  display:block;
  box-shadow:none;
  border:1px solid #DEDEDE;
  border-radius:30px; }

input
{ border:1px solid #DEDEDE;
  border-radius:60px;
  height:auto; }

.woocommerce #payment #place_order,.woocommerce-page #payment #place_order
{ color:#fff;
  -webkit-font-smoothing:antialiased;
  display:block;
  padding:10px 25px;
  border-radius:60px;
  -webkit-box-shadow:0 0 0 10px rgba(229,0,69,.25);
  box-shadow:0 0 0 10px rgba(229,0,69,.25);
  background-color:#E50045;
  text-align:center;
  text-transform:uppercase; }

a.checkout-button.button.alt.wc-forward
{ display:block;
  padding:20px 25px;
  border-radius:60px;
  -webkit-box-shadow:0 0 0 10px rgba(229,0,69,.25);
  box-shadow:0 0 0 10px rgba(229,0,69,.25);
  background-color:#E50045;
  text-align:center;
  text-transform:uppercase; }

.cart_totals
{ background:#FFF;
  box-shadow:0 0 7px 4px rgba(0,0,0,.09);
  border-radius:26px;
  padding:40px;
  min-height:350px;
  height:100%; }

.woocommerce .cart .button,.woocommerce .cart input.button
{ float:none;
  color:#fff;
  background:#e50045;
  box-shadow:0 1px 2px 0 rgba(0,0,0,.25);
  border-radius:100px;
  margin:0 0 0 10px;
  border:10px solid #f6ccd1; }

.woocommerce #respond input#submit.disabled,.woocommerce #respond input#submit:disabled,.woocommerce #respond input#submit:disabled[disabled],.woocommerce a.button.disabled,.woocommerce a.button:disabled,.woocommerce a.button:disabled[disabled],.woocommerce button.button.disabled,.woocommerce button.button:disabled,.woocommerce button.button:disabled[disabled],.woocommerce input.button.disabled,.woocommerce input.button:disabled,.woocommerce input.button:disabled[disabled]
{ color:#fff; }

.woocommerce table.cart td.actions .input-text
{ background:#FFF;
  border:1px solid #DEDEDE;
  border-radius:40px;
  padding:10px;
  width:300px; }

.woocommerce-shipping-fields
{ margin:0 0 30px;
  background-color:#efefef;
  padding:15px;
  border-radius:20px; }

.woocommerce-error,.woocommerce-info,.woocommerce-message
{ padding:1em 2em 1em 3.5em;
  margin:0 0 2em;
  position:relative;
  color:#515151;
  border-top:3px solid #E50045;
  list-style:none;
  width:auto;
  word-wrap:break-word;
  background:#FFF;
  box-shadow:0 0 7px 4px rgba(0,0,0,.09);
  border-radius:6px; }

.woocommerce form .form-row input.input-text,.woocommerce form .form-row textarea
{ height:40px; }

.wc-cart-wrapper
{ display:flex;
  justify-content:space-between; }

.wc-cart-wrapper .cart_totals.calculated_shipping
{ float:none;
  width:100%; }

.wc-cart-wrapper .cart-collaterals
{ padding:0;
  width:30%; }

.wc-cart-wrapper .quantity .quantity
{ display:none; }

@media screen and (max-width:1024px)
{ .wc-cart-wrapper
{ flex-direction:column; }

.wc-cart-wrapper .cart-collaterals
{ padding:0;
  width:100%; }

.wc-form
{ max-width:100%!important;
  width:100%; }

}

.woocommerce table.shop_table
{ border:0; }

.woocommerce table.shop_table img
{ width:150px; }

.input-text.qty.text
{ text-align:center;
  padding:10px;
  width:80px; }

.woocommerce .cart-collaterals .cart_totals
{ width:100%;
  float:none; }

#place_order,.angelleye_cancel,.paypal_checkout_button
{ float:none!important; }

.cart-collaterals .shop_table.shop_table_responsive td,.cart-collaterals .shop_table.shop_table_responsive th,.cart-collaterals .shop_table.shop_table_responsive tr
{ display:block;
  padding-left:0; }

.cart-collaterals .shop_table.shop_table_responsive td
{ border:none!important; }

.cart-collaterals .shop_table.shop_table_responsive th
{ width:100%!important;
  padding-top:20px; }

.cart-collaterals .button,.cart-collaterals .shop_table.shop_table_responsive tr
{ margin-top:20px!important; }

.wc-cart-wrapper .shop_table th
{ color:#404040;
  font-weight:400!important; }

.wc-cart-wrapper .shop_table a
{ color:#404040;
  text-decoration:none; }

.cart-collaterals .shop_table.shop_table_responsive td,.cart_totals.calculated_shipping h2,td.product-price,td.product-subtotal
{ color:#e50045; }

.wc-proceed-to-checkout
{ text-align:center; }

.wc-form
{ max-width:65%;
  width:100%; }

.ec_checkout_page_button_type_paypalimage
{ margin:15px 0!important; }

.cart_totals>div
{ display:block;
  text-align:center; }

.cart-contents
{ height:43px;
  display:flex;
  background:#fff;
  color:#000!important;
  align-items:center;
  padding:0 5px;
  font-size:13px;
  position:relative; }

.cart-contents svg
{ margin-right:5px; }

.cart-contents span
{ color:#fff;
  font-size:11px;
  position:absolute;
  left:21px;
  top:16px; }

.product_meta
{ display:none; }

@media screen and (min-width:1023px)
{ ul.products li
{ width:330px; }

}

.woocommerce-LoopProduct-link .price
{ margin:20px 0; }

.wc_prodcuts_wrapper
{ padding:60px 90px; }

ul.products
{ list-style-type:none;
  display:flex;
  padding:0;
  margin:0;
  flex-wrap:wrap; }

ul.products li
{ text-align:center;
  padding:15px 0;
  width:300px;
  margin:0 15px;
  align-content:flex-end;
  justify-content:center; }

@media screen and (max-width:1023px)
{ ul.products li
{ margin:0 5px; }

.wc_prodcuts_wrapper
{ padding:60px 50px; }

}

ul.products .woocommerce-loop-product__link
{ text-decoration:none; }

ul.products .woocommerce-loop-product__title,ul.products li .price
{ margin-top:15px;
  color:#404040;
  font-size:14px; }

ul.products li .price
{ display:block; }

@media screen and (max-width:768px)
{ ul.products li
{ margin:0;
  width:280px; }

.wc_prodcuts_wrapper
{ padding:60px 30px; }

}

@media (max-width:768px) and (orientation:landscape)
{ .wc_prodcuts_wrapper
{ padding:60px 0; }

}

span.woocommerce-Price-amount.amount
{ color:#404040; }

.product-type-simple
{ padding:0;
  display:flex;
  flex-wrap:wrap; }

.product-type-simple .woocommerce-product-gallery
{ max-width:40%; }

.product-type-simple .summary.entry-summary
{ width:50%;
  padding-top:50px; }

.summary.entry-summary .woocommerce-Price-amount.amount
{ margin:20px 0;
  display:block;
  font-size:25px;
  font-weight:700; }

.woocommerce-product-details__short-description p
{ color:#404040;
  font-weight:400;
  font-size:18px; }

.product_title.entry-title,.up-sells h2
{ color:#404040; }

.product-type-simple form.cart
{ display:flex;
  margin-top:30px; }

.product-type-simple form.cart input
{ width:70px;
  font-size:20px;
  font-weight:100;
  text-align:center;
  padding:10px; }

.upsells
{ border-top:1px solid #ccc;
  margin-top:50px;
  padding:50px 0;
  width:100%; }

.upsells ul.products
{ margin-top:30px; }

.upsells ul.products li
{ width:285px!important;
  padding:30px;
  margin:0 5px; }

@media screen and (max-width:1024px)
{ .upsells ul.products li
{ width:200px!important;
  padding:0;
  margin:0 10px; }

.cart .quantity
{ margin:0 10px 0 0!important; }

.product-type-simple .summary.entry-summary
{ width:40%;
  padding-top:50px; }

}

@media screen and (max-width:760px)
{ .upsells ul.products li
{ width:310px!important;
  padding:0;
  margin:10px; }
}

@media screen and (max-width:670px)
{ .upsells ul.products li
{ width:260px!important;
  padding:0;
  margin:10px; }
}

@media screen and (max-width:480px)
{ .upsells ul.products li
{ width:310px!important;
  padding:0;
  margin:10px; }
}

.product-type-simple .woocommerce-product-gallery
{ max-width:fit-content; }

.product-type-simple .summary.entry-summary
{ width:fit-content;
  padding-top:50px; }

.cart .quantity
{ margin:15px 30px 0 0!important; }

@media screen and (max-width:480px)
{ .cart .quantity
{ margin:0!important;
  text-align:center; }

}

.cart .quantity
{ margin:15px 20px 0 0; }

@media screen and (min-width:1024px)
{ p#billing_city_field,p#shipping_city_field
{ width:60%;
  display:inline-table; }
  p#billing_postcode_field,p#shipping_postcode_field
{ width:40%;
  display:inline-table;
  }
}

.angelleye_cancel
{ margin-bottom:30px!important; }

img.ec_checkout_page_button_type_paypalimage
{ margin:15px 50%;
  padding:10px; }

.woocommerce .woocommerce-customer-details address,.woocommerce ul.order_details,table.woocommerce-table.woocommerce-table--order-details.shop_table.order_details
{ background:#FFF;
  box-shadow:0 0 7px 4px rgba(0,0,0,.09);
  border-radius:26px;
  padding:30px;
  margin-top:30px; }

.woocommerce ul.order_details
{ list-style:none; }

.woocommerce .woocommerce-customer-details address
{ font-style:normal; }

h2.woocommerce-column__title
{ margin-top:30px; }

@media screen and (max-width:480px)
{ .woocommerce-order-overview li
{ display:inline-block;
  width:100%;
  border-right:0!important;
  margin:5px 0; }

}

p.form-row.terms.wc-terms-and-conditions
{ margin:0 0 30px; }

.input-text
{ padding:0 20px; }

.form-row.place-order
{ display:inline-grid!important; }

.voucherForm
{ width:100%;
  margin:0 0 60px; }

.angelleye-woocommerce-customer-details-email,.angelleye-woocommerce-customer-details-phone,.woocommerce-customer-details--email,.woocommerce-customer-details--phone
{ font-size:16px!important; }

.empty-cart
{ width:100%;
  display:block; }

.empty-cart>span
{ text-align:center;
  display:block;
  color:#404040;
  font-size:20px; }

.empty-cart>.button-container
{ display:flex;
  justify-content:center; }

.empty-cart h1
{ text-align:center;
  font-size:50px;
  color:#404040; }

.checkout-bottom-wrapper span,span.isVisible
{ font-size:13px; }

.empty-cart .box-container
{ display:block;
  text-align:center;
  margin:20px;
  padding:30px; }

.empty-cart .boxes-image
{ width:auto; }

.checkout-bottom-wrapper
{ margin:30px 0 0;
  display:block;
  text-align:left; }

.input-checkbox,.input-radio
{ padding:5px!important; }

.form-row.place-order
{ padding:1em!important;
  text-align:left!important; }

label.checkbox.delivery,label.checkbox.terms
{ display:flex!important;
  align-items:baseline!important; }

label.checkbox.delivery>input.input-checkbox,label.checkbox.terms>input.input-checkbox
{ position:relative;
  top:2px; }

.woocommerce #payment .terms,.woocommerce #payment .wc-terms-and-conditions,.woocommerce-page #payment .terms,.woocommerce-page #payment .wc-terms-and-conditions
{ text-align:left;
  padding:0;
  float:left; }

@media screen and (max-width:360px)
{ .cart_totals
{ padding:20px!important; }

span.isVisible
{ display:none!important; }

div#order_review
{ padding:0; }

}

span.isVisible
{ display:flex;
  color:#000!important;
  padding:0 5px;
  position:relative;
  top:0;
  left:0; }

@media screen and (max-width:762px)
{ .button.wc-forward
{ width:100%;
  margin:18px!important;
  justify-content:center;
  display:flex!important; }

}

p.cart-empty
{ display:none; }

.woocommerce-product-details__short-description
{ padding-top:30px; }

.wc-gzd-additional-info.tax-info
{ margin-bottom:0!important;
  margin-top:0!important; }

.wc-gzd-additional-info.shipping-costs-info
{ margin-bottom:30px; }

.product p.wc-gzd-additional-info
{ width:100%; }

.woocommerce-cart .siteWrapper,.woocommerce-checkout .siteWrapper
{ display:block!important; }

.woocommerce-cart .hero-container,.woocommerce-checkout .hero-container
{ width:100%!important; }

.hero-container .hero-logo
{ min-width:300px;
  max-width:500px; }

.landingPremium .siteMain-subnavigation
{ padding-top:50px; }

.landingPremium .siteMain-subnavigation-container
{ justify-content:center; }

.siteMain-subnavigation .landingPremium-link
{ margin:5px 25px; }

.premium-products-container
{ margin-top:50px;
  display:flex;
  justify-content:center;
  flex-wrap:wrap; }

.premium-product
{ flex-grow:1;
  flex-basis:250px;
  margin:50px 10px 10px;
  padding:20px;
  min-width:250px;
  position:relative;
  box-shadow:2px 1px 1px #d3d3d3;
  border-radius:7px;
  border:1px solid #d3d3d3;
  max-width:400px; }

.premium-product.popular
{ border:3px solid #f6a809;
  padding-top:35px;
  margin-top:30px; }

.premium-product h1
{ font-size:45px;
  color:#e50045; }

.premium-product h2
{ font-size:25px;
  color:#e50045;
  margin-bottom:30px; }

.premium-product .popular
{ position:absolute;
  width:160px;
  left:calc(50% - 80px);
  top:-40px; }

.premium-product h3
{ display:inline-block;
  font-size:35px;
  margin-left:50px; }

.premium-product h4
{ margin-bottom:30px; }

.premium-product .price-subtext
{ display:inline;
  font-size:12px;
  font-weight:700; }

ul.premium-features li
{ font-size:30px;
  margin:10px; }

.premium-product a.button
{ display:block;
  width:200px;
  margin:0 auto;
  padding:10px;
  border-radius:35px;
  outline:0;
  border:0;
  -webkit-box-shadow:0;
  box-shadow:0;
  font-size:21px;
  background-color:#f6ccd1;
  text-decoration:none;
  color:#fff;
  cursor:pointer; }

.premium-product a.button span
{ display:block;
  padding:11px 28px 10px;
  border-radius:27px;
  -webkit-box-shadow:0 1px 2px 0 rgba(0,0,0,.25);
  box-shadow:0 1px 2px 0 rgba(0,0,0,.25);
  background-color:#e50045;
  font-weight:500; }

.landingTeaser-container.premium
{ flex-flow:column; }

.landingPremium-link
{ display:block; }

.landingPremium-link.is-active
{ font-weight:700; }

.landingPremium-link.is-active:after
{ position:absolute;
  bottom:0;
  right:9px;
  left:9px;
  display:block;
  height:3px;
  background-color:#e50045;
  content:""; }

/* Ab Mai 2019 - by SoFi */
.fontsize-calc17
{ font-size:calc(0.912vw + 17px); }

.sofi-grid-wrapper {
	width: 100%;
	margin: 0;
    padding: 0;
}
@media only screen and (min-width: 1430px) { .sofi-grid-wrapper {
    margin-left: -65px;
	width: 1400px;
  }
}
@media only screen and (max-width: 1430px) and (min-width: 1260px) { .sofi-grid-wrapper {
    margin-left: calc(50% - 48vw);
	width: 96vw;
  }
}


/* Metaslider-Addon */
.sliderbox-icon {
	float: left;
	padding: 0.3em 1.1em 4px 0;
}
.pre-slidebox {
	padding: 30px 0 40px 0;
}

.metaslider {
	clear: both;
	padding-bottom: 3em;
}
@media only screen and (max-width: 540px) { img.sliderbox-icon {
    max-width: 30%;
  }
}

/* Countdown */
#main_countedown_1 .element_conteiner {
	min-width: 120px!important;
	margin: 1em!important;
}
#main_countedown_1 .time_left {
	border-radius: 0!important;
	background-color: #ffc107!important;
	font-size: 3em!important;
	font-family: Mikado,sans-serif!important;
	color: #fff!important;
}
#main_countedown_1 .time_description {
	font-size: 1.4em!important;
	font-family: Mikado,sans-serif!important;
	color: #fff!important;
}
/* Allgemein */
.hero--product {
	min-height: 100px;
}

/* Produktseiten tigerbox TOUCH, tigerticket */
@media screen and (min-width:1100px) { 
	.product--tigerbox-touch .landingFeatures-feature, .product--tigerticket  .landingFeatures-feature{
		width: 20%;
	}
}

.landingTeaser-sofi-img {
	text-align: center;
}

@media screen and (min-width:1100px) { 
.sofi-landing-wrapper {
	font-size: calc(0.95vw + 13px);
	line-height: 1.1;
}
.sofi-landing-wrapper {
	display: inline-flex;
}
.landingTeaser-sofi-img {
	max-width: 56%;
	float: left;
	margin-right: 4%;
}
.landingTeaser-sofi-txt {
	align-self: center;
	max-width: 40%;
}
}

@media screen and (min-width:760px) { 
.slidebox-spacer  {
	margin-top: 40px;
}
}

.product--tigerbox-touch .landingTeaser-main { 
	margin-left: 0;
	text-align: center;
}
.product--tigerbox-touch .landingTeaser-button { 
	display: none;
}
.product--tigerbox-touch .landingTeaser-content { 
	display: inline-block;
}
.product--tigerbox-touch .landingTeaser-container { 
	display: block;
}
.product--tigerbox-touch .landingTeaser-image { 
	display: none;
}

/*
.homeSection--tigerbox-touch a { 
	color: #f6a809;
}
*/

.homeSection--tigerticket a { 
	color: #ca1524;
}

.homeSection--tigercards a { 
	color: #ca1524;
}

.product--tigertones .siteSection--colored,.product--tigercards .siteSection--colored,.product--tigerticket .siteSection--colored
{ background-color:#f6a809; }

.product--tigerbox .siteSection--colored, .product--tigerbox-touch .siteSection--colored
{ background-color:#f6a809; }

.landing .siteSection--colored,.product--tigerbooks .siteSection--colored
{ background-color:#ca1524; }

@media screen and (max-width:900px) { 
.siteMain-subnavigation-container, .siteMain-subnavigation
{ display:none; }
}

.landingFeatures-feature-icon
{ margin-bottom:20px; 
  text-align: center; }
  
.landingFeatures-feature,.landingSection-title
{ text-align:center; }

.landingFeatures-feature-text {
	font-size: 1.3em;
	padding-bottom: 40px;
}

.landingFeatures-feature-title {
	font-size: 1.6em;
	font-weight: 500;
}

.sofi-grid::before,
.sofi-grid::after {
    clear: both;
    content: '';
    display: block;
}
.sofi-grid-item {
	display: inline-block;
	vertical-align: top;
	text-align: center;
    float: left;
    height: auto;
    margin: 0.3%;
    width: 16%;	
	overflow: hidden;
	padding: 0.5%;
}
.sofi-grid-item img {
    width: 100%;
	height: auto;
}

@media only screen and (max-width: 1200px) { .sofi-grid-item {
    width: 19%;
  }
}
@media only screen and (max-width: 1000px) { .sofi-grid-item {
    width: 24%;
	padding: 1%;
  }
}
@media only screen and (max-width: 780px) { .sofi-grid-item {
    width: 32%;
	padding: 1.5%;
  }
}
@media only screen and (max-width: 540px) { .sofi-grid-item {
    width: 49%;
	padding: 2%;
  }
}
@media only screen and (max-width: 270px) { .sofi-grid-item {
    width: 99%;
  }
}

/* Hilfeseiten */

.siteMain-subnavigation, body.error .siteWrapper, body.page .siteWrapper, body.support .siteWrapper {
	background-image: url(./../../../assets/images/BackgroundPattern-Color@2x.png);
	background-size: 1441px 810px;
}

a {
	color: #e50045;
}
.supportProducts-wrapper {
	margin: 30px -7px 30px;
}
.supportProduct {
	padding: 0 7px;
	text-decoration: none;
}
.supportProduct img {
	display: block;
	max-width: 100%;
	width: auto;
	margin: 0 auto;
	-webkit-transition: -webkit-transform .1s;
	transition: -webkit-transform .1s;
	transition: transform .1s;
	transition: transform .1s,-webkit-transform .1s;
}
.supportProduct.is-current img {
	-webkit-filter: grayscale(1);
	filter: grayscale(1);
	opacity: .25;
}
.supportProduct-title {
	font-size: 18px;
}
.supportProduct-title {
	display: block;
	padding-top: 1em;
	text-align: center;
	color: #404040;
}
.supportProductPage-header {
	position: relative;
	margin-top: 50px;
	margin-bottom: 40px;
}
.supportCategories {
	margin-bottom: 50px;
}
@media only screen and (max-width: 900px) { 
	.supportProducts {
		display: block;
	}
	
	a.supportProduct {
	padding: 0 7px 20px;
	text-decoration: none;
	float: left;
	display: inline-block;
	width: 49%;
	}

	.supportProductPage-header {
		clear: both;
		padding-top: 12px;
		margin-bottom: 22px;
	}
	
	.support .pageContent p {
		font-size: calc(0.5625vw + 15.13px);
	}
	
	.supportCategory-post, .supportFaq-item {
		list-style: none;
		padding: 0;
		font-size: 16px;
		padding-bottom: 16px;
	}
	.supportCategory-posts {
		margin: 15px 0 0;
	}
	.support .pageContent p.supportProductPage-description {
		font-size: 20px;
	}
	.supportProductPage-backButton {
		padding-bottom: 60px;
	}
	.supportCategory-title {
		font-size: 24px;
		margin-top: 30px;
	}
}
@media only screen and (max-width: 800px) { 
	.siteLink.siteLink--service {
		font-size: calc(1.1625vw + 9.13px);
	}
}
@media only screen and (min-width: 780px) { 
	.supportProducts {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		max-width: 750px;
		margin: 0 auto;
	}
}
.supportProductPage-description {
	text-align: center;
	margin-top: 0.2em;
}
.supportProductPage-title {
	font-size: 33px;
}
.supportProductPage-backButton .button-inner--mobile {
	display: none;
}
.supportProductPage-backButton .button-inner {
	display: block;
}

.hero--homepage
{ height: calc(300px + 90vw);
  min-height: 600px; }

@media only screen and (min-width: 780px) { 
	.hero--homepage {
		height: calc(800px + 20vw);
	}
}

@media only screen and (min-width: 1200px) { 
	.hero--homepage {
		height: calc(900px + 5vw);
	}
}

body
{ color: #333; }

.supportFaq-title
{ text-align: left;
  font-size: 24px;
  margin-top: 30px;
  padding: 10px 30px;
  margin: 0 -30px;
  box-shadow: inset 0 -1px 0 0 #d9d9d9;
}

.supportPost-title {
	font-size: 24px;
}
  
@media only screen and (min-width: 760px) { 
	.supportFaq-title, .supportPost-title {
		font-size: 26px;
	}
}

.supportFaq-items {
	margin-top: 15px;
}

.supportFaq {
	margin-bottom: 20px;
}




