.content-item {
    padding: 16px;
    margin-bottom: 12px;
    border-radius: 1.5625rem;
    box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.5);
    background-color: white;
    display: flex;
    align-items: center;
}

.item-name {
    flex: 1;
    padding-right: 1rem;
}