@font-face {
    font-family: RockyBeachGrotesque;
    src: url("../../../../assets/fonts/RockyBeachGrotesque-RegularWeb.woff2") format("woff2");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: RockyBeachGrotesque;
    src: url("../../../../assets/fonts/RockyBeachGrotesque-BoldWeb.woff2") format("woff2");
    font-weight: bold;
    font-style: normal
}

@font-face {
    font-family: RockyBeachGrotesqueHeadline;
    src: url("../../../../assets/fonts/RockyBeachGrotesque-BoldHeadlineWeb.woff2") format("woff2");
    font-weight: bold;
    font-style: normal
}

@font-face {
    font-family: RockyBeachGrotesque;
    src: url("../../../../assets/fonts/RockyBeachGrotesque-RegularItalicWeb.woff2") format("woff2");
    font-weight: normal;
    font-style: italic
}

body {
    font-family: RockyBeachGrotesque, Mikado, sans-serif
}

.openlink a:hover {
    cursor: pointer;
    text-decoration: none;
}

.ddf-container {
    width: 100%;
    margin: auto;
    padding-top: 80px;
    background-color: #010202;
    color: #fefefe;
    font-family: RockyBeachGrotesque, Mikado, sans-serif;
}

.ddf-form-label {
    font-weight: bold;
}

.ddf-section-headline {
    font-weight: bold;
    font-family: RockyBeachGrotesqueHeadline, Mikado, sans-serif;
    line-height: 1em;
}


.ddf-title {
    font-family: RockyBeachGrotesqueHeadline, Mikado, sans-serif;
    font-size: 3em;
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-top: -0.5em;
    margin-bottom: 0.5em;
    line-height: 1em;
}

.ddf-page-headline {
    line-height: 1.2em;
}


.ddf-check-boxes {
    overflow: hidden;
}

.registerCheckboxLabel {
    margin-top: -1em;
    float:left;
}

.ddf-section-image-content-box {
    width: 100%;
    margin: 0;
}


@media screen and (min-width: 1200px){

    .ddf-taipan {
        width: 100%;
        background-image: url("../../../../assets/images/ddf_header_01.png");
        background-position: top;
        background-repeat: no-repeat;
        background-size: 80%;
        padding-top: 40%;
        margin-top: -2%;
    }

    .ddf-page-headline {
        width: 80%;
        font-size: 18pt;
        font-weight: bold;
        margin: -40px auto 0 auto;
        text-align: center;
    }

    .ddf-section-white {
        width: 100%;
        margin: 2em auto 0 auto;
        padding: 3em;
        color: #010202;
        background-color: #fefefe;
        text-align: center;
        font-size: 20pt;
    }

    .ddf-section-black {
        width: 100%;
        margin: 2em auto 0 auto;
        padding: 3em;
        color: #fefefe;
        background-color: #010202;
        text-align: center;
        font-size: 20pt;
    }

    .ddf-registration-container {
        min-height: auto;
    }


    .ddf-section-headline {
        font-weight: bold;
        font-size: 48pt;
    }

    .ddf-section-text-content {
        width: 40%;
        margin: 0 auto 0 auto;
    }

    .ddf-section-image-content {
        width: 80%;
        margin: 2em auto;
    }

    .ddf-section-image-content-shifted {
        width: 60%;
        margin: -2em auto;
    }

    .ddf-icon {
        width: 25%;
    }

    .form-broad {
        width: 60%;
    }

    .ddf-button-row {
        width: 20%;
        margin: 0 auto;
    }

    .ddf-error-info {
        text-align: center;
        font-size: 18pt;
    }

    .ddf-error-button {
        width: 50%;
        margin: 0 auto;
    }

    .app-icon {
        width: 45%;
    }

    .icon-right {
        float: right;
    }

    .icon-left {
        float: left;
    }
}

@media screen and (min-width: 800px) and (max-width: 1199px){
    .ddf-title {
        width: 100%;
        text-align: center;
        padding-top: 40px;
    }

    .ddf-taipan {
        width: 100%;
        background-image: url("../../../../assets/images/ddf_header_01.png");
        background-position: top;
        background-repeat: no-repeat;
        background-size: 120%;
        padding-top: 60%;
        margin-top: -2%;
    }

    .ddf-page-headline {
        width: 80%;
        font-size: 16pt;
        font-weight: bold;
        margin: -40px auto 0 auto;
        text-align: center;
    }

    .ddf-section-white {
        width: 100%;
        margin: 2em auto 0 auto;
        padding: 1em;
        color: #010202;
        background-color: #fefefe;
        text-align: center;
        font-size: 20pt;
    }

    .ddf-section-black {
        width: 100%;
        margin: 2em auto 0 auto;
        padding: 1em;
        color: #fefefe;
        background-color: #010202;
        text-align: center;
        font-size: 20pt;
    }

    .ddf-registration-container {
        min-height: auto;
    }

    .ddf-section-headline {
        font-weight: bold;
        font-size: 38pt;
    }

    .ddf-section-text-content {
        width: 70%;
        margin: 0 auto 0 auto;
    }

    .ddf-section-image-content {
        width: 120%;
        margin: 2em -10%;
    }

    .ddf-section-image-content-shifted {
        width: 100%;
        margin: -2em auto;
    }

    .ddf-icon {
        width: 40%;
    }

    .form-broad {
        width: 60%;
    }

    .ddf-button-row {
        width: 20%;
        margin: 0 auto;
    }

    .ddf-error-info {
        text-align: center;
        font-size: 18pt;
    }

    .ddf-error-button {
        width: 50%;
        margin: 0 auto;
    }

    .app-icon {
        width: 45%;
    }

    .icon-right {
        float: right;
    }

    .icon-left {
        float: left;
    }
}

@media screen and (min-width: 500px) and (max-width: 799px){
    .ddf-container {
        padding-top: 60px;
    }

    .ddf-taipan {
        width: 100%;
        background-image: url("../../../../assets/images/ddf_header_01.png");
        background-position: top;
        background-repeat: no-repeat;
        background-size: 120%;
        padding-top: 60%;
        margin-top: -2%;
    }

    .ddf-page-headline {
        width: 90%;
        font-size: 18pt;
        font-weight: bold;
        margin: -40px auto 0 auto;
        text-align: center;
    }

    .ddf-section-white {
        width: 100%;
        margin: 2em auto 0 auto;
        padding: 1em;
        color: #010202;
        background-color: #fefefe;
        text-align: center;
        font-size: 20pt;
    }

    .ddf-section-black {
        width: 100%;
        margin: 2em auto 0 auto;
        padding: 1em;
        color: #fefefe;
        background-color: #010202;
        text-align: center;
        font-size: 20pt;
    }


    .ddf-registration-container {
        min-height: auto;
    }

    .ddf-section-headline {
        font-weight: bold;
        font-size: 38pt;
    }

    .ddf-section-text-content {
        width: 70%;
        margin: 0 auto 0 auto;
    }

    .ddf-section-image-content {
        width: 120%;
        margin: 2em -10%;
    }

    .ddf-section-image-content-shifted {
        width: 100%;
        margin: -2em auto;
    }

    .ddf-icon {
        width: 50%;
    }

    .form-broad {
        width: 60%;
    }

    .ddf-button-row {
        width: 20%;
        margin: 0 auto;
    }

    .ddf-error-info {
        text-align: center;
        font-size: 18pt;
    }

    .ddf-error-button {
        width: 50%;
        margin: 0 auto;
    }

    .app-icon {
        width: 45%;
    }

    .icon-right {
        float: right;
    }

    .icon-left {
        float: left;
    }
}

@media screen and (max-width: 499px){
    .ddf-container {
        padding-top: 40px;
    }

    .ddf-taipan {
        width: 100%;
        background-image: url("../../../../assets/images/ddf_header_01.png");
        background-position: top;
        background-repeat: no-repeat;
        background-size: 180%;
        padding-top: 80%;
        margin-top: 5%;
    }

    .ddf-page-headline {
        width: 80%;
        font-size: 14pt;
        font-weight: bold;
        margin: 0 auto;
        text-align: center;
    }

    .ddf-section-white {
        width: 100%;
        margin: 1em auto 0 auto;
        padding: 1em;
        color: #010202;
        background-color: #fefefe;
        text-align: center;
        font-size: 16pt;
    }

    .ddf-section-black {
        width: 100%;
        margin: 1em auto 0 auto;
        padding: 1em;
        color: #fefefe;
        background-color: #010202;
        text-align: center;
        font-size: 16pt;
    }

    .ddf-registration-container {
        min-height: auto;
    }

    .registerCheckboxLabel {
        margin-top: -1em;
    }

    .ddf-section-headline {
        font-weight: bold;
        font-size: 30pt;
    }

    .ddf-section-text-content {
        width: 95%;
        margin: 0 auto 0 auto;
    }

    .ddf-section-image-content {
        width: 110%;
        margin: 1em -5%;
    }

    .ddf-section-image-content-shifted {
        width: 100%;
        margin: -2em 0;
    }

    .ddf-icon {
        width: 60%;
    }

    .form-broad {
        width: 60%;
    }

    .ddf-button-row {
        width: 20%;
        margin: 0 auto;
    }

    .ddf-error-info {
        text-align: center;
        font-size: 18pt;
    }

    .ddf-error-button {
        width: 50%;
        margin: 0 auto;
    }

    .app-icon {
        width: 45%;
    }

    .icon-right {
        float: right;
    }

    .icon-left {
        float: left;
    }
}
