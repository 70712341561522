.rewe-header-overlay {
  margin-top: -4.5em;
  margin-bottom: 2em;
  z-index: 10;
  position: relative;
  background-color: white;

  @media screen and (min-width: 1200px) {
    margin-top: -90px;
  }

  > div {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    img {
      @media screen and (min-width: 1000px) {
        max-height: 520px;
      }
    }
  }
}

.rewe-register-form {
  width: 30%;
  max-width: 700px;
  margin: 2% auto auto auto;
  text-align: center;
  vertical-align: middle;
  align-content: center;
  font-family: Mikado, sans-serif;
  color: #333;

  @media screen and (min-width: 600px) and (max-width: 1199px) {
    width: 50%;
  }

  @media screen and (max-width: 599px) {
    width: 80%;
  }

  .password-container {
    position: relative;

    .show-password-icon-container {
      color: lightgray;
      position: absolute;
      right: 1em;
      bottom: 1em;
    }
  }

  input[type=email], input[type=password], input[type=text] {
    width: 100%;
    padding: 8px 22px 9px;
    border-radius: 35px;
    border: 1px solid #dedede;
    outline: 0;
    font-size: 24px;
    color: inherit;
    height: 49px
  }

  input.is-invalid {
    border: 3px solid #e50045;
    color: #e50045;
  }

  .auth-label {
    display: inline-block;
    padding-left: 5px;
    font-size: 24px;
    font-weight: bold;
    color: inherit
  }

  .password-forgot {
    float: right;
    margin-top: 10px;
    margin-right: 5px;
    color: #e50045;
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
  }

  .password-forgot:hover {
    color: #a94442;
    text-decoration: none;
  }

  .promotion-conditions {
    margin-top: 2em;
    text-align: left;
    font-size: 0.8em;
  }
}

.round {
  position: relative;
}

.paymentinput .round {
  display: inherit
}

.round label {
  margin: 20px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
}

.round.center label {
  left: calc(50% - 13px);
}

.round label:after {
  border: 5px solid #000;
  border-top: none;
  border-right: none;
  content: "";
  height: 10px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-55deg);
  width: 18px;
}

.round input[type="checkbox"], .round input[type="radio"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label:after, .round input[type="radio"]:checked + label:after {
  opacity: 1;
}

.roundlabel {
  margin-left: 40px;
  margin-bottom: 10px;
}

.registerCheckboxLabel {
  font-size: 14px;
}

.sepalabel {
  margin-top: -10px;
  font-size: 20px;
}

.container {
  margin: 0 auto;
}