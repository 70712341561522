.wildcards-item-cover-container {
    position: relative;
    width: 100%;
    min-height: 400px;

    @media screen and (max-width: 399px) {
        min-height: 85vw;
    }

    .cover {
        border: 1px solid gray;
        width: 100%;
        border-radius: 1rem;
        height: 400px;

        @media screen and (max-width: 399px) {
            height: 85vw;
        }

        img {
            border-radius: 1rem;
            aspect-ratio : 1 / 1;
        }
    }

    .badge-container {
        position: absolute;
        top: -1px;
        margin-left: 1.25rem;
    }

    .badge-icon {
        position: absolute;
        margin: 0.4rem 0 0 0.4rem;
        width: 83%;
        z-index: 99;
    }
}
