
.cluster-cover {
    border: 1px solid gray;
    width: 23.6rem;
    height: 23.6rem;
}

@media screen and (max-width: 399px) {
    .cluster-details-wrap {
        flex-direction: column;
    }
}
@media screen and (min-width: 400px) and (max-width: 699px) {
    .cluster-details-wrap {
        flex-direction: column;
    }
}

@media screen and (min-width: 700px) and (max-width: 999px) {
    .cluster-details-wrap {
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
    .cluster-details-wrap {
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (min-width: 1200px) {
    .cluster-details-wrap {
        display: flex;
        justify-content: space-between;
    }
}

