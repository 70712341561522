.profile-items-container {
    justify-content: center;

    .add-profile-image {
        position: relative;
        top: 9rem;
    }
}

.profile {
    background-color: white;
    border-radius: 1rem !important;
    box-shadow: 0 0 0.3125rem rgba(0,0,0,0.5);
    margin: 0.9375rem 0.625rem;
    text-align: center;
    text-decoration: none;
    max-width: 380px;
    min-width: 380px;
}

.profile:hover {
    background-color: #f3f3f3;
    cursor: pointer;
}

.new-profile {
    border-color: rgba(30,175,140,0.2);
    background-color: rgba(30,175,140,0.2);
    border-radius: 1rem !important;
    box-shadow: 0 0 0.3125rem rgba(0,0,0,0.5);
    margin: 0.9375rem 0.625rem;
    text-align: center;
    text-decoration: none;
    max-width: 380px;
    min-width: 380px;
}

.new-profile:hover {
    background-color: rgba(30,175,140,0.3);
    cursor: pointer;
}

.profile-body {
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    border-top: none;
    overflow-wrap: break-word;
}

/* ==========================================================================
Lock icon
========================================================================== */

.public-icon {
    position: absolute;
    display: flex;
    margin-top: 3.75rem;
    margin-left: 21.7rem;
}

.lock-icon {
    position: absolute;
    bottom: 12px;
    right: 12px;
    color: orange;
}

.lock-icon:hover > .tooltiptext {
    visibility: visible;
}

.tooltiptext {
    color: black;
    visibility: hidden;
    border: 1px solid gray;
    border-radius: 5px;
    position: absolute;
    background-color: white;
    width: 100px;
    z-index: 1;
    top: 2rem;
    right: -4rem;
}

.profiles-amount {
    text-align: right;
    color: #1bafb4;
    font-family: MikadoBlack, sans-serif;
    margin: 1rem 0 .2rem 0;
}

@media (max-width: 400px) {

}

