.cancellation-body {
    width: 100%;

    .cancellation-headline {
        width: 80%;
        text-align: center;
        margin: 20px auto;
        font-size: 20pt;
        font-weight: bold;

        @media screen and (min-width: 800px) and (max-width: 1199px) {
            margin: 30px auto;
        }

        @media screen and (min-width: 400px) and (max-width: 799px) {
            width: 80%;
            margin: 30px auto;
        }

        @media screen and (max-width: 399px) {
            margin: 30px auto;
            font-size: 16pt;
        }
    }

    .form-group-info {
        padding-left: 5px;
        margin-bottom: .5rem;
    }

    .datepicker {
        width: 100%;
        border-radius: 0;
        cursor: pointer;
    }
}


@media screen and (min-width: 1200px){

    .cancellation-info {
        width: 40%;
        margin: 30px auto;
    }

    .cancellation-form {
        width: 40%;
        margin: auto auto;
    }

    .confirmation-table {
        width: 40%;
        margin: 0 auto;
    }

    .cancellation-survey {
        width: 65%;
        height: 850px;
        margin: -20px auto;
    }

    .cancellation-button {
        width: 100%;
        font-size: 18px;
    }
}

@media screen and (min-width: 800px) and (max-width: 1199px){
    .cancellation-info {
        width: 60%;
        margin: 20px auto;
    }

    .cancellation-form {
        width: 60%;
        margin: auto auto;
    }

    .confirmation-table {
        width: 60%;
        margin: 0 auto;
    }

    .cancellation-survey {
        width: 80%;
        height: 950px;
        margin: 0 auto;
    }

    .cancellation-button {
        width: 100%;
        font-size: 18px;
    }
}

@media screen and (min-width: 400px) and (max-width: 799px){

    .cancellation-info {
        width: 80%;
        margin: 20px auto;
    }

    .cancellation-form {
        width: 80%;
        margin: auto auto;
    }

    .confirmation-table {
        width: 80%;
        margin: 0 auto;
    }

    .cancellation-survey {
        width: 80%;
        height: 950px;
        margin: 0 auto;
    }

    .cancellation-button {
        width: 100%;
        font-size: 18px;
    }
}

@media screen and (max-width: 399px){
    .cancellation-info {
        width: 80%;
        margin: 20px auto;
    }

    .cancellation-form {
        width: 80%;
        margin: auto auto;
    }

    .confirmation-table {
        width: 80%;
        margin: 0 auto;
    }

    .cancellation-survey {
        width: 100%;
        height: 1200px;
        margin: 0 auto;
    }

    .cancellation-button {
        width: 100%;
        font-size: 14px;
    }
}
