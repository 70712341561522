.navbar-container {
  font-family: Mikado,sans-serif;
  position: fixed;
  z-index:1;
  background-color: #fff;

  @media ((min-width: 530px) and (max-width: 1199px)) {
    .navbar-nav {
      background-color: #fff;
      border: solid 1px #404040;
      padding: 0 2%;
      max-width: 495px;
      position: relative;
      left: calc(100% - 495px);
    }
  }

  @media (max-width: 530px) {
    .navbar-nav {
      background-color: #fff;
      border: solid 1px #404040;
      padding: 0 2%;
      width: 100%;
    }
  }

  .navbar-link {
    color: #404040;
    display: inline-block;

    svg {
      vertical-align: baseline;
    }
  }

  .navbar-link:hover {
    text-decoration: none;
    color: #ce023e;
    font-weight: bold;
  }

  .navbar-dropdown-link {
    color: #404040;
    margin-left: 0;
    align-self: flex-end !important;

    &:hover {
      text-decoration: none;
      color: #ce023e;
      font-weight: bold;
    }
  }

  .products-box {
    background-color: white;
    z-index: 100;
    position: fixed;
    align-content: center;
    text-align: center;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;

    .products-image {
      min-height: 100%;

      &:hover {
        transform: scale(1.05);
        transition: .3s;
      }
    }

    @media (max-width: 999px) {
    }
  }

  @media (min-width: 1200px) {
    .navbar {
      height: 45px;
      padding-right: 0;
    }
  }

  @media (max-width: 1199px) {
    .navbar {
      height: 60px;
    }
  }

  @media (min-width: 1000px) {

    .products-row {
      margin-top: 0;
    }


    .products-card {
      height: 300px;
      width: 30%;
      cursor: pointer;
    }

    .products-card:hover {
      height: 310px;
      width: 32%;
    }

    .products-image {
      height: auto;
    }
  }

  @media (max-width: 999px) {
    .products-row {
      margin-top: 20px;
    }

    .products-card {
      height: 100px;
      width: 30%;
      cursor: pointer;
    }

    .products-card:hover {
      height: 110px;
      width: 32%;
    }

    .products-image {
      width: 45%;
    }
  }
}

