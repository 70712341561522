@keyframes tarif-item-anim {
    from {
        background-color: rgba(0,0,0,0.0);
        box-shadow: 0 0 0.3125rem rgba(0,0,0,0.0);
    }
    to {
        background-color: rgba(0,0,0,0.1);
        box-shadow: 0 0 0.3125rem rgba(0,0,0,0.5);
    }
}

.tarif-list {
    margin: auto;
    justify-content: center;
}

.tarif-item {
    width: 320px;
    margin: 10px;
    cursor: pointer;
}

.tarif-item ul{
    margin-bottom: 0;
}

.tarif-item:hover {
    background-color: rgba(0,0,0,0.1);
    box-shadow: 0 0 0.3125rem rgba(0,0,0,0.5);
    animation-name: tarif-item-anim;
    animation-duration: 1s;
}

