.search-query-input-container {
  width: 40%;
  margin: 1em auto;

  input[type=search] {
    width: 80%;
    padding: 8px 22px 9px;
    border-radius: 35px;
    border: 0;
    background-color: rgba(100, 100, 100, 0.043);
    outline: 0;
    font-size: 24px;
    color: inherit;
    height: 60px;
    margin: 1em 0.5em;
    float: left;
  }

  #search-button {
    color: inherit;
    background: none;
    border: none;
    margin: 1em 0;
    float: left;
    padding-top: 25px;
  }

  svg {
    width: 1.5em;
    height: auto;
  }
}




.search-hr {
  clear: both;
  border-top: 1px solid #676767;
  margin-top: 0;
}

.search-container {
  clear: both;
  width: 85%;
  height: auto;
  margin: 0 7.5% 50px 7.5%;
  vertical-align: middle;
  color: #333;
  hyphens: auto;

  @media screen and (max-width: 570px) {
    width: 90%;
    height: auto;
    margin: 50px auto;
  }

  .search-title {
    width: 50%;
    float: left;
  }

  .no-result-container {
    clear: both;
    padding-top: 2em;
    text-align: center;
    font-size: 2em;
  }

  .search-items-container {
    width: 100%;
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (max-width: 570px) {
      justify-content: space-between;
    }

    .search-item {
      margin: 10px 10px 20px 10px;
      padding: 10px;
      height: 355px;
      min-height: 355px;
      max-height: 355px;
      width: 220px;
      float: left;
      overflow: hidden;

      @media screen and (max-width: 570px) {
        width: 49%;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        min-height: 60vw;
        max-height: fit-content;
        height: fit-content;
      }
    }
  }
}

/*don't put it into a container it's related to series resutl too*/
.search-title {
  font-weight: normal;
  font-size: 2em;
  color: #676767;
  min-height: 50px;

  .recommendations-profile-select {
    color: #676767;
    border: none;
    width: 100px;
    min-width: fit-content;
    text-align: center;

  }
}

a.all-heros-headline-link {
  font-weight: normal;
  color: #676767;
  text-decoration: underline;
}

.search-result-sort-select {
  font-size: 1.4em;
  width: 50%;
  float: right;
  vertical-align: baseline;
}


.search-item-cover {
  width: 200px;
  max-width: 200px;
  max-height: 220px;
  border-radius: 10px;
  margin: 0;

  @media screen and (max-width: 570px) {
    width: 100%;
    max-width: 100%;
    max-height: 50vw;
  }

  .item-cover-background-container {
    background-color: #9b9b9b;
    min-height: 200px;
    width: 100%;
    border-radius: 20px;
    position: relative;
    top: 20px;

    @media screen and (max-width: 570px) {
      min-height: 43vw;
      top: 6vw;
    }
  }

  .cover-age-display {
    color: white;
    font-size: 0.8em;
    position: relative;
    text-align: right;
    top: 172px;
    left: -10px;

    @media screen and (max-width: 570px) {
      position: absolute;
      top: 38vw;
      left: 85%;
    }

    a {
      color: white;
    }

    span {
      font-size: 1.5em;
      font-weight: bold;

      @media screen and (max-width: 570px) {
        font-size: 1.2em;
      }
    }

  }

  .cover-product-length-display {
    position: relative;
    top: 118px;
    left: 15px;
    color: white;
    text-align: left;
    font-size: 0.8em;

    @media screen and (max-width: 570px) {
      position: absolute;
      top: 38vw;
      left: 6%
    }

    span {
      font-size: 1.5em;
      font-weight: bold;

      @media screen and (max-width: 570px) {
        font-size: 1.2em;
      }
    }
  }

  .cover-product-classification-display {
    position: relative;
    top: 142px;
    left: 95px;
    height: 1.3em;

    @media screen and (max-width: 570px) {
      position: absolute;
      top: 38.5vw;
      left: 47%;
      height: 1em;
    }
  }

  img {
    position: relative;
    top: -210px;
    max-height: 200px;

    @media screen and (max-width: 570px) {
      max-width: 100%;
      max-height: 50vw;
      top: -43vw;
    }

  }
}

.search-item-text-container {
  width: 200px;
  margin-top: 5px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 570px) {
    float: left;
    clear: both;
    margin-top: 0;
    -webkit-line-clamp: 6;
    width: 100%;
  }

  .product-title-product-title {
    font-size: 1.3em;

    @media screen and (max-width: 570px) {
      font-size: 1em;
    }
  }

  .product-title-series-title {
    @media screen and (max-width: 570px) {
      font-size: 0.8em;
    }
  }
}

.search-item-name {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: normal;
  color: #676767;
  padding-left: 5px;
  min-height: 1.5em;
}

.search-item-age {
  color: #676767;
}

.series-container {
  width: 85%;
  height: auto;
  margin: 50px auto 1em auto;
  vertical-align: middle;
  clear: both;
}

.series-items-container-wrapper {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  max-height: 335px;
}

.series-items-container {
  overflow: visible;
  max-height: 250px;
  height: 250px;
  width: max-content;
}

.series-item {
  float: left;
  margin: 10px 10px 10px 10px;
  padding: 10px;
  height: auto;
  min-height: 250px;
  width: 200px;
  border-radius: 20px;
}

.series-item:hover {
  cursor: pointer;
  /*    background: linear-gradient(225deg, rgba(250, 185, 0, 0.4), rgb(250, 185, 0));*/
}

.series-item-cover {
  width: 100%;
  border-radius: 10px;
  margin: 0 0 20px 0;
  float: left;
  overflow: hidden;
}

.series-item-text-container {
  margin-top: 20px;
  clear: both;
}

.series-item-name, a .series-item-name {
  font-weight: normal;
  color: #676767;
  text-align: center;
  font-size: 1.4em;
}

.series-item-age {
  color: #676767;
}

.refresh-link {
  cursor: pointer;
  color: #e50045;
}

.refresh-link:hover {
  text-decoration: underline;
}

.search-back-button {
  width: 100%;
}

.search-back-button a:hover {
  text-decoration: none;
}

@media screen and (min-width: 500px) and (max-width: 1099px) {
  .search-query-input-container {
    width: 70%;
  }
}

@media screen and (max-width: 570px) {
  .search-query-input-container {
    width: 90%;
  }

  .series-items-container-wrapper {
    width: 95%;
  }
}