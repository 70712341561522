.wildcard-details-wrap {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
    }

    .cover-wrap {
        width: 40%;
        margin-right: 5%;
        max-width: 360px;

        @media only screen and (max-width: 1000px) {
            width: 80%;
        }

        .loader {
            width: 100%;
            height: 100%;
            border: 1px solid gray;
            border-radius: 1rem;

            .loader-bg {
                width: 60%;
            }
        }
    }

    .wildccard-metadata-container {
        .edit-wildcard-name-button {
            margin-left: 10px;
            vertical-align: top;
        }
    }
}

