.indicatorWrapper {
    display: flex;
    align-items: center;
    float: right;
}

.indicator {
    font-weight: bold;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.indicator-green {
    background-color: #4caf50;
}

.indicator-red {
    background-color: #f44336;
}