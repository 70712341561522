.product-metadata-cover {
  width: 200px;
  max-width: 200px;
  max-height: 220px;
  border-radius: 10px;
  margin: 0;

  @media screen and (max-width: 570px) {
    width: 100%;
    max-width: 100%;
    max-height: 50vw;
  }

  .product-metadata-cover-background-container {
    background-color: #9b9b9b;
    min-height: 200px;
    width: 100%;
    border-radius: 20px;
    position: relative;
    top: 20px;

    @media screen and (max-width: 570px) {
      min-height: 43vw;
      top: 6vw;
    }
  }

  .cover-age-display {
    color: white;
    font-size: 0.8em;
    position: relative;
    text-align: right;
    top: 172px;
    left: -10px;

    @media screen and (max-width: 570px) {
      position: absolute;
      top: 38vw;
      left: 85%;
    }

    a {
      color: white;
    }

    span {
      font-size: 1.5em;
      font-weight: bold;

      @media screen and (max-width: 570px) {
        font-size: 1.2em;
      }
    }

  }

  .cover-product-length-display {
    position: relative;
    top: 118px;
    left: 15px;
    color: white;
    text-align: left;
    font-size: 0.8em;

    @media screen and (max-width: 570px) {
      position: absolute;
      top: 38vw;
      left: 6%
    }

    span {
      font-size: 1.5em;
      font-weight: bold;

      @media screen and (max-width: 570px) {
        font-size: 1.2em;
      }
    }
  }

  .cover-product-classification-display {
    position: relative;
    top: 142px;
    left: 95px;
    height: 1.3em;

    @media screen and (max-width: 570px) {
      position: absolute;
      top: 38.5vw;
      left: 47%;
      height: 1em;
    }
  }

  img {
    position: relative;
    top: -210px;
    max-height: 200px;

    @media screen and (max-width: 570px) {
      max-width: 100%;
      max-height: 50vw;
      top: -43vw;
    }

  }
}
