.series-details-container {
  hyphens: auto;

  .back-button {
    margin-top: 0.8em;
    float: left;
    margin-left: 7.5%;

    @media screen and (max-width: 499px) {
      width: 92%;
      margin-top: 0;
      margin-left: 4%;
    }
  }

  .series-container {
    clear: both;
    width: 85%;
    height: auto;
    margin: 50px auto;

    @media screen and (max-width: 499px) {
      width: 90%;
    }

    .series-hr {
      clear: both;
      border-top: 1px solid #676767;
      margin-top: 0;
    }

    .cover-age-display {
      color: white;
      text-align: left;
      font-size: 0.8em;
      position: relative;
    }

    .series-information-headline {
      font-size: 35pt;
      line-height: 30pt;
      height: auto;
      clear: both;
    }

    .series-information-container {
      margin: 4em 0 2em 0;
      overflow: hidden;
      height: auto;
      min-height: 350px;
      width: 100%;
      border-radius: 20px;
      display: flex;

      a {
        color: #676767;
        text-decoration: underline;
      }

      .series-cover-and-buttons-container {
        width: 280px;
        max-width: 280px;
        margin: 0 3% 0 0;
        display: flex;
        flex-direction: column;

        .series-cover {
          max-height: 320px;

          .series-cover-background-container {
            background-color: #9b9b9b;
            min-height: 190px;
            width: 100%;
            border-radius: 0 0 20px 20px;
            position: relative;
            top: 145px;

            .cover-age-display {
              position: absolute;
              top: 140px;
              left: 230px;

              color: white;
              text-align: left;
              font-size: 0.8em;

              span {
                font-size: 1.5em;
                font-weight: bold;
              }

            }
          }

          img {
            position: relative;
            top: -185px;
            border-radius: 15px;
            width: 100%;
          }
        }

        .cluster-button-container {
          margin: 2em auto 1em auto;
          display: flex;
          flex-direction: column;
          align-items: center;

          .cluster-profile-description {
            margin-bottom: 1em;
          }

          .cluster-profile-select {
            height: 60px;
            font-size: 16px;
            width: 250px;
            border-radius: 35px;
            margin-bottom: 1em;
            text-align: center;

            @media screen and (max-width: 499px) {
              width: 100%;
            }
          }

          .cluster-profile-button {
            @media screen and (max-width: 499px) {
              width: 100%;
            }
          }

          @media screen and (max-width: 499px) {
            margin: 1em auto 0 auto;
            align-items: normal;
          }
        }
      }

      .series-text-container {
        width: calc(96% - 280px);
      }
    }

    .series-name {
      margin-top: 0;
      font-weight: normal;
      font-size: 2em;
      color: #676767;
      min-height: 50px;
    }

    .series-description {
      margin-top: 1em;
    }

    .series-season-container {
      margin-top: 1em;
    }

    .series-season-container ol {
      padding-left: 15px;
    }

    .episode-item-cover {
      width: 200px;
      max-width: 200px;
      max-height: 200px;
      border-radius: 10px;
      margin: 0 3% 0 0;
      float: left;
    }

    .season-information-container {
      display: flex;
      margin-top: 2em;
      justify-content: space-between;
      align-items: flex-start;

      .season-title {
        font-size: 2em;
        color: #676767;
        font-weight: normal;
        height: auto;
        float: left;
        min-width: 30%;
      }

      .season-description {
        font-size: 1em;
        font-weight: normal;
        width: calc(70% - 40px);
        padding-left: 5%;
      }

      .season-episode-items-view-type-button {
        width: 30px;
        margin-left: 10px;
      }
    }

    .episode-items-container {
      width: 100%;
      margin-top: 1em;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      .episode-item {
        margin: 10px 10px 20px 0;
        padding: 10px 10px 10px 0;
        height: auto;
        min-height: 245px;
        width: 100%;
        clear: both;

        @media screen and (max-width: 499px) {
          margin: 10px 0 20px 0;
          padding: 10px 0 10px 0;
        }

        &.compact {
          width: 220px;
          clear: none;
          margin: 10px 10px 20px;
          padding: 10px;
          height: 335px;

          a {
            display: flex;
            flex-flow: column;
            justify-content: center;

            .episode-item-text-container {
              width: 200px;
              padding-left: 5px;
              margin-top: 45px;

              .episode-item-name {
              }

              .episode-item-description {
                display: none;
              }
            }
          }
        }

        a {
          display: flex;
          color: #676767;
        }

        .episode-item-cover {
          width: 200px;
          max-width: 200px;
          max-height: 200px;
          border-radius: 20px;
          margin: 0 3% 0 0;

          .episode-item-cover-background-container {
            background-color: #9b9b9b;
            min-height: 200px;
            width: 100%;
            border-radius: 20px;
            position: relative;
            top: 35px;
          }

          .cover-age-display {
            position: absolute;
            top: 172px;
            text-align: right;
            left: 165px;

            span {
              font-size: 1.5em;
              font-weight: bold;
            }
          }

          .cover-product-length-display {
            position: absolute;
            top: 172px;
            left: 15px;
            color: white;
            text-align: left;
            font-size: 0.8em;

            span {
              font-size: 1.5em;
              font-weight: bold;
            }
          }

          .cover-product-classification-display {
            position: absolute;
            top: 175px;
            left: 95px;
            height: 1.3em;
          }

          img {
            position: relative;
            top: -195px;
          }
        }

        .episode-item-text-container {
          width: calc(100% - 4% - 200px);
          max-height: 230px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 9;
          -webkit-box-orient: vertical;

          @media screen and (max-width: 499px) {
            -webkit-line-clamp: 11;
            max-height: 240px;
          }

          .episode-item-name {
            margin-top: 0;
            margin-bottom: 0.5em;
            font-weight: normal;
            color: #676767;
            padding-left: 0;
            min-height: 1.5em;

            .product-title-product-title {
              font-size: 1.3em;

              @media screen and (max-width: 499px) {
                font-size: 1em;
              }
            }

            .product-title-series-title {

              @media screen and (max-width: 499px) {
                font-size: 0.8em;
              }
            }

          }

          .episode-item-description {
            @media screen and (max-width: 499px) {
              font-size: 0.8em;
            }

        }
      }
    }

  }

  @media screen and (min-width: 500px) and (max-width: 799px) {
    width: 85%;
  }


  @media screen and (max-width: 499px) {
    width: 85%;

    .series-information-container {
      flex-direction: column;
      width: 100%;
      margin: 0 0 2em 0;

      .series-cover-and-buttons-container {
        width: 100%;
        margin: 0 0 2em 0;
        max-width: 100%;

        .series-cover {
          max-height: 100vw;

          .series-cover-background-container {
            top: 150px;
            min-height: 60vw;

            .cover-age-display {
              top: 45vw;
              left: 65vw;
            }
          }

          img {
            top: -60vw;
          }
        }
      }

      .series-text-container {
        width: 100%;
      }
    }

    .season-information-container {
      flex-direction: column;

      .season-title {
        height: auto;
        width: 100%;
      }

      .season-description {
        width: 100%;
        padding-left: 0;
      }

      .season-episode-items-view-type-button {
        display: none;
      }
    }
  }
}

}

.all-series-container {
  clear: both;
  width: 85%;
  height: auto;
  margin: 50px 7.5%;

  .all-series-items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .all-series-item {
      margin: 10px 10px 10px 10px;
      padding: 10px;
      height: auto;
      min-height: 250px;
      width: 200px;
      border-radius: 20px;

      .all-series-item-cover {
        max-height: 200px;

        .all-series-item-cover-background-container {
          background-color: #9b9b9b;
          min-height: 120px;
          width: 100%;
          border-radius: 20px;
          position: relative;
          top: 70px;

          .cover-age-display {
            color: white;
            text-align: right;
            font-size: 0.8em;
            position: relative;
            top: 90px;
            left: -10px;

            span {
              font-size: 1.5em;
              font-weight: bold;
            }
          }
        }

        img {
          position: relative;
          top: -135px;
          border-radius: 15px;
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 499px) {
    .all-series-items-container {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
      min-height: 100vw;

      .all-series-item {
        width: 100%;
        margin-bottom: 2em;
        min-height: 100vw;

        .all-series-item-cover {
          width: 100%;
          max-width: 100%;
          max-height: 90vw;

          .all-series-item-cover-background-container {
            min-height: 55vw;
            top: 30vw;

            .cover-age-display {
              position: absolute;
              top: 45vw;
              left: 86%;
              font-size: 1em;
            }
          }

          img {
            max-width: 420px;
            max-height: 420px;
            top: -55vw;
          }
        }

      }
    }
  }
}



