.authentication-form {
    width: 60%;
    max-width: 700px;
    margin: 5% auto auto auto;
    text-align: center;
    vertical-align: middle;
    align-content: center;
    font-family: Mikado, sans-serif;
    color: #333;
}

input[type=email], input[type=password], input[type=text] {
    width: 100%;
    padding: 8px 22px 9px;
    border-radius: 35px;
    border: 1px solid #dedede;
    outline: 0;
    font-size: 24px;
    color: inherit;
    height: 49px
}

input.is-invalid {
    border: 3px solid #e50045;
    color: #e50045;
}

.auth-label {
    display: inline-block;
    padding-left: 5px;
    font-size: 24px;
    font-weight: 500;
    color: inherit
}

.password-forgot {
    float: right;
    margin-top: 10px;
    margin-right: 5px;
    color: #e50045;
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
}

.password-forgot:hover {
    color: #a94442;
    text-decoration: none;
}